import React, { MouseEvent, createContext, useContext, useState } from 'react';

interface MenuContextProps {
  menuOpen: string | null;
  menuPosition: { top: number; left: number };
  toggleMenu: (
    clientId: string,
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => void;
  onWorkScheduleClick: (clientId: string, e: MouseEvent) => void;
  onDetailsClick: (clientId: string, e: MouseEvent) => void;
  onSendInvitationClick: (clientId: string, e: MouseEvent) => void;
  onDeleteClick: (clientId: string, e: MouseEvent) => void;
  setMenuOpen: (clientId: string | null) => void;
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }
  return context;
};

export const MenuProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [menuPosition, setMenuPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const toggleMenu = (
    clientId: string,
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    const menuHeight = 200;
    const menuWidth = 150;

    let top = rect.bottom;
    let left = rect.left;

    if (rect.bottom + menuHeight > innerHeight) {
      top = rect.top - menuHeight;
    }
    if (rect.left + menuWidth > innerWidth) {
      left = rect.right - menuWidth;
    }

    setMenuPosition({ top, left });
    setMenuOpen((prevMenuOpen) =>
      prevMenuOpen === clientId ? null : clientId,
    );
  };

  const onWorkScheduleClick = (clientId: string, e: MouseEvent) => {};

  const onDetailsClick = (clientId: string, e: MouseEvent) => {};

  const onSendInvitationClick = (clientId: string, e: MouseEvent) => {};

  const onDeleteClick = (clientId: string, e: MouseEvent) => {};

  return (
    <MenuContext.Provider
      value={{
        menuOpen,
        menuPosition,
        toggleMenu,
        onWorkScheduleClick,
        onDetailsClick,
        onSendInvitationClick,
        onDeleteClick,
        setMenuOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
