import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteSchedule,
  updateSchedule,
} from '../../../../store/actions/scheduleActions';
import { AppDispatch } from '../../../../store/store';
import { Translator, WorkSchedule } from '../../../../types';
import ChangeModal from '../ChangeModal/ChangeModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import {
  formatDate,
  formatTime,
  formatToHHMM,
  getDatesForMonth,
} from '../helpers';
import style from './DatesGrid.module.css';

interface DatesGridProps {
  currentMonth: Date;
  userId: string;
  onDateClick: (
    date: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  schedule: Record<string, WorkSchedule[]>;
  onAdd?: (day: keyof Translator['weeklyWorkSchedule'], date: string) => void;
  setUpdateCounter: React.Dispatch<React.SetStateAction<number>>;
}

const DatesGrid: React.FC<DatesGridProps> = ({
  currentMonth,
  userId,
  onDateClick,
  schedule,
  setUpdateCounter,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState<{
    startAt: string;
    finishAt: string;
  }>({ startAt: '00:00', finishAt: '00:00' });
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedWork, setSelectedWork] = useState<WorkSchedule | null>(null);
  const [currentSchedule, setCurrentSchedule] = useState(schedule);

  useEffect(() => {
    setCurrentSchedule(schedule);
  }, [schedule]);

  const dates = getDatesForMonth(currentMonth);

  const handleTimeClick = (
    event: React.MouseEvent<HTMLDivElement>,
    work: WorkSchedule,
    date: Date,
  ) => {
    event.stopPropagation();
    setSelectedWork(work);
    setSelectedDate(date);
    setConfirmationModalOpen(true);
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleEdit = () => {
    if (selectedWork) {
      setSelectedTime({
        startAt: formatToHHMM(selectedWork.startAt),
        finishAt: formatToHHMM(selectedWork.finishAt),
      });
      setChangeModalOpen(true);
    }
    setConfirmationModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedWork) {
      await dispatch(
        deleteSchedule({ scheduleId: selectedWork.id, userId }),
      ).unwrap();
      setConfirmationModalOpen(false);
      setUpdateCounter((prevCounter) => prevCounter + 1);
    }
  };

  const handleSave = async (startAt: string, finishAt: string) => {
    if (selectedDate && selectedWork) {
      await dispatch(
        updateSchedule({
          startAt,
          finishAt,
          userId,
          date: selectedDate.toISOString(),
          workId: selectedWork.id,
        }),
      ).unwrap();
      setCurrentSchedule((prevSchedule) => {
        const dayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][
          selectedDate.getDay()
        ] as keyof Translator['weeklyWorkSchedule'];
        const updatedWorkSchedules = prevSchedule[dayKey].map((work) => {
          if (work.id === selectedWork.id) {
            return { ...work, startAt, finishAt };
          }
          return work;
        });
        return { ...prevSchedule, [dayKey]: updatedWorkSchedules };
      });

      setChangeModalOpen(false);
      setUpdateCounter((prevCounter) => prevCounter + 1);
    }
  };

  const normalizeDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const isSameDay = (work: WorkSchedule, date: Date) => {
    const workDate = normalizeDate(new Date(work.startAt));
    const compareDate = normalizeDate(date);
    return workDate.getTime() === compareDate.getTime();
  };

  return (
    <div className={style.datesGrid}>
      {dates.map((date) => {
        const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
        const formattedDate = formatDate(date);
        const dayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][
          date.getDay()
        ] as keyof Translator['weeklyWorkSchedule'];
        const workSchedule = currentSchedule[dayKey] || [];

        const isClickable = isCurrentMonth;

        return (
          <div
            key={formattedDate}
            className={`${style.dateCell} ${isClickable ? style.active : style.inactive}`}
          >
            <div className={style.numberDate}>{date.getUTCDate()}</div>
            <div>
              {workSchedule
                .filter((work) => work && work.startAt && isSameDay(work, date))
                .map((work, index) => (
                  <div
                    key={index}
                    className={style.time}
                    onClick={(e) => handleTimeClick(e, work, date)}
                  >
                    {`${formatTime(work.startAt)} - ${formatTime(work.finishAt)}`}
                  </div>
                ))}
              {workSchedule.filter(
                (work) => work && work.startAt && isSameDay(work, date),
              ).length === 0 &&
                isClickable && (
                  <button
                    onClick={(e) => onDateClick(formattedDate, e)}
                    className={style.addButton}
                  >
                    Додати
                  </button>
                )}
            </div>
          </div>
        );
      })}
      {selectedDate && selectedWork && (
        <ChangeModal
          isOpen={changeModalOpen}
          onClose={() => setChangeModalOpen(false)}
          onSave={handleSave}
          position={modalPosition}
          initialStartAt={selectedTime.startAt}
          initialFinishAt={selectedTime.finishAt}
          userId={userId}
          workId={selectedWork.id}
          selectedDate={selectedDate}
        />
      )}
      {selectedWork && (
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          onEdit={handleEdit}
          onDelete={handleDelete}
          position={modalPosition}
          scheduleId={selectedWork.id}
          userId={userId}
          setUpdateCounter={setUpdateCounter}
        />
      )}
    </div>
  );
};

export default DatesGrid;
