import { useState } from 'react';
import dayjs from 'dayjs';
import SearchInput from '../SearchInput/SearchInput';
import DateModal from '../DateModal/DateModal';
import DateInputs from '../DateInputs/DateInputs';
import style from './ControlPanel.module.css';

interface ControlPanelProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  dateValues: {
    startDate: string;
    endDate: string;
  };
  setDateValues: (dates: { startDate: string; endDate: string }) => void;
}

const ControlPanel = ({
  searchQuery,
  setSearchQuery,
  dateValues,
  setDateValues,
}: ControlPanelProps) => {
  const [isDateModalOpen, setDateModalOpen] = useState(false);

  const openDateModal = () => setDateModalOpen(true);

  const closeDateModal = () => setDateModalOpen(false);

  const handleDateSelect = (dates: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    const formattedStartDate = dates.startDate
      ? dayjs(dates.startDate).format('YYYY-MM-DD')
      : '';
    console.log(dates.startDate, formattedStartDate);
    const formattedEndDate = dates.endDate
      ? dayjs(dates.endDate).format('YYYY-MM-DD')
      : '';

    setDateValues({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div className={style.panel_wrap}>
      <SearchInput
        value={searchQuery}
        onChange={handleSearchChange}
        onClear={handleClearSearch}
      />
      <div onClick={openDateModal}>
        <DateInputs value={dateValues} />
      </div>
      <DateModal
        isOpen={isDateModalOpen}
        onClose={closeDateModal}
        onSelect={handleDateSelect}
      />
    </div>
  );
};

export default ControlPanel;
