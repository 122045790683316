import React from 'react';
import style from './DaysOfWeek.module.css';

const DaysOfWeek: React.FC = () => {
  const days = [
    'Понеділок',
    'Вівторок',
    'Середа',
    'Четвер',
    'П’ятниця',
    'Субота',
    'Неділя',
  ];
  return (
    <div className={style.daysOfWeek}>
      {days.map((day) => (
        <div key={day} className={style.dayHeader}>
          {day}
        </div>
      ))}
    </div>
  );
};

export default DaysOfWeek;
