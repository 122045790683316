import React from 'react';
import { Employee } from '../../../../types';
import DeleteIcon from '../icons/delete';
import style from './EmployeeItem.module.css';

interface EmployeeItemProps {
  employee: Employee;
  index: number;
  onDeleteClick: (employee: Employee) => void;
}

const EmployeeItem: React.FC<EmployeeItemProps> = ({
  employee,
  index,
  onDeleteClick,
}) => (
  <div className={style.itemContainer}>
    <div className={style.additionalElement}>{index + 1}</div>
    <div className={style.wrapInfo}>
      <img src={employee.avatarUrl} alt={employee.userName} />
      <p>{employee.userName}</p>
    </div>
    <p>{employee.email}</p>
    <p>{employee.phone}</p>
    <button
      onClick={() => onDeleteClick(employee)}
      className={style.deleteButton}
    >
      <DeleteIcon />
    </button>
  </div>
);

export default EmployeeItem;
