import React, { ChangeEvent } from 'react';
import addPhotos from '../icons/add_photo.svg';
import style from './ImageUpload.module.css';

interface ImageUploadProps {
  image: string | null;
  handleImageChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  image,
  handleImageChange,
}) => {
  const triggerFileInput = () => {
    document.getElementById('imageUpload')?.click();
  };

  return (
    <div className={style.imageUploadContainer} onClick={triggerFileInput}>
      <label htmlFor="imageUpload" className={style.imageLabel}>
        {image ? (
          <img src={image} alt="Profile" className={style.profileImage} />
        ) : (
          <div className={style.placeholder}>
            <img
              src={addPhotos}
              alt="Upload Icon"
              className={style.uploadIcon}
            />
          </div>
        )}
      </label>
      <input
        id="imageUpload"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className={style.imageInput}
      />
    </div>
  );
};

export default ImageUpload;
