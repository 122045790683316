import { isAxiosError } from 'axios';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import { AppDispatch } from '../../../store/store';
import axiosInstance from '../../../utils/axiosInstance';

interface UploadResponse {
  url: string;
}

export const uploadImage = async (image: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', image);

  const uploadResponse = await axiosInstance.post<UploadResponse>(
    '/File/UploadAvatarForUser',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return uploadResponse.data.url;
};

export const addTranslator = async (
  data: any,
  avatarUrl: string,
  dispatch: AppDispatch,
): Promise<boolean> => {
  const requestData = {
    userData: {
      userName: data.name,
      email: data.email,
      phone: data.phone,
      avatarUrl,
    },
  };

  try {
    await axiosInstance.put('/Admin/AddTranslator', requestData);
    dispatch(
      showSnackbar({ message: 'Перекладач успішно доданий', type: 'success' }),
    );
    return true;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const errorMessage = error.response?.data.Message || 'Помилка сервера';
      if (status === 400 && errorMessage === 'User already exists') {
        dispatch(
          showSnackbar({
            message: 'Перекладач з такою email адресою існує',
            type: 'error',
          }),
        );
      } else {
        dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
      }
    } else {
      const unexpectedError = 'Виникла неочікувана помилка';
      dispatch(showSnackbar({ message: unexpectedError, type: 'error' }));
    }
    return false;
  }
};
