import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import style from './Input.module.css';

interface InputProps {
  type: string;
  register: UseFormRegister<any>;
  name: string;
  label: string;
  validation?: any;
  errors: FieldErrors;
}

const Input: React.FC<InputProps> = ({
  type,
  register,
  name,
  label,
  validation = {},
  errors,
}) => {
  return (
    <div className={style.inputContainer}>
      <label htmlFor={name}>{label}</label>
      <input type={type} {...register(name, validation)} />
      {errors[name] && (
        <span className={style.error}>{String(errors[name]?.message)}</span>
      )}
    </div>
  );
};

export default Input;
