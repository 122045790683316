import { createReducer } from '@reduxjs/toolkit';

import {
  loadHistoryRequest,
  loadHistorySuccess,
  loadHistoryFailure,
} from '../actions/historyActions';

interface HistoryState {
  history: any[];
  loading: boolean;
  error: string | null;
  pageIndex: number;
  orderDirection: string;
  hasNextPage: boolean;
}

export const initialState: HistoryState = {
  history: [],
  loading: false,
  error: null,
  pageIndex: 0,
  orderDirection: 'ASC',
  hasNextPage: true,
};

const historyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadHistoryRequest, (state, action) => {
      state.loading = true;
      if (action.payload.pageIndex === 0) {
        state.history = [];
        state.pageIndex = 0;
      }
      state.orderDirection = action.payload.orderDirection;
    })
    .addCase(loadHistorySuccess, (state, action) => {
      state.loading = false;
      state.history = action.payload.items;
      state.pageIndex = action.payload.pageIndex;
      state.hasNextPage = action.payload.hasNextPage;
    })
    .addCase(loadHistoryFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default historyReducer;
