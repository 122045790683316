import axiosInstance from '../utils/axiosInstance';
import { WorkSchedule } from './type/cheduleTypes';

export const updateScheduleApi = async (
  startAt: string,
  finishAt: string,
  workId?: number,
) => {
  const response = await axiosInstance.patch<WorkSchedule>('/Admin/Schedule', {
    startAt,
    finishAt,
    id: workId,
  });
  return response.data;
};

export const deleteScheduleApi = async (scheduleId: number, userId: string) => {
  const response = await axiosInstance.delete<{ date: string }>(
    '/Admin/Schedule',
    {
      params: { scheduleId, userId },
    },
  );
  return response.data;
};
