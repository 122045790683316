import React from 'react';
import CrossIcon from '../../СompanyForm/icon/Сross';
import style from './ModalHeader.module.css';

interface ModalHeaderProps {
  selectedClientName: string;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  selectedClientName,
  onClose,
}) => (
  <div className={style.modalHeader}>
    <h5>Співробітники {selectedClientName}</h5>
    <button onClick={onClose} className={style.closeHeader}>
      <CrossIcon />
    </button>
  </div>
);

export default ModalHeader;
