import { useCallback, useEffect, useRef, useState } from 'react';
import { Employee } from '../../../types';
import { deleteEmployeeApi, fetchEmployeesApi } from './api';

export const useEmployeeData = (
  selectedClientId: number | null,
  orderDirection: 'ASC' | 'DESC',
  searchText: string,
) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const isFetchingRef = useRef<boolean>(false);

  const fetchEmployees = useCallback(
    async (page: number, reset = false, searchString = '') => {
      if (selectedClientId === null || isFetchingRef.current) return;
      isFetchingRef.current = true;
      try {
        const data = await fetchEmployeesApi(
          selectedClientId,
          page,
          orderDirection,
          searchString,
        );
        setEmployees((prev) => (reset ? data.items : [...prev, ...data.items]));
        setHasMore(data.hasNextPage);
      } catch (error) {
        console.error('Помилка при завантаженні даних:', error);
      } finally {
        isFetchingRef.current = false;
      }
    },
    [selectedClientId, orderDirection],
  );

  useEffect(() => {
    if (selectedClientId !== null) {
      setEmployees([]);
      setPageIndex(0);
      setHasMore(true);
      fetchEmployees(0, true, searchText);
    }
  }, [selectedClientId, fetchEmployees, searchText]);

  useEffect(() => {
    if (pageIndex > 0) {
      fetchEmployees(pageIndex, false, searchText);
    }
  }, [pageIndex, fetchEmployees, searchText]);

  return {
    employees,
    hasMore,
    pageIndex,
    setPageIndex,
    fetchEmployees,
    setHasMore,
    isFetchingRef,
  };
};

export const useDeleteEmployee = (
  fetchEmployees: (page: number, reset: boolean, searchString: string) => void,
  selectedClientId: number | null,
  searchText: string,
) => {
  const deleteEmployee = async (workerId: string) => {
    try {
      const status = await deleteEmployeeApi(workerId, selectedClientId);
      if (status === 200) {
        fetchEmployees(0, true, searchText);
      }
    } catch (error) {
      console.error('Помилка при видаленні працівника:', error);
    }
  };

  return deleteEmployee;
};

export const useSearch = (
  fetchEmployees: (page: number, reset: boolean, searchString: string) => void,
) => {
  const [searchText, setSearchText] = useState<string>('');
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    if (value.length === 0 || value.length >= 2) {
      searchTimeoutRef.current = setTimeout(() => {
        fetchEmployees(0, true, value);
      }, 1000);
    }
  };

  const handleClearSearch = () => {
    setSearchText('');
    fetchEmployees(0, true, '');
  };

  return {
    searchText,
    handleSearchChange,
    handleClearSearch,
  };
};
