import React from 'react';
import SortArrow from '../../icons/SortArrow';
import SortingAZ from '../../icons/SortingAZ';
import SortingZA from '../../icons/SortingZA';
import style from './CustomerListHead.module.css';

interface CustomerListHeadProps {
  sortingLogo: boolean;
  isRotated: boolean;
  toggleSortingDirection: () => void;
  handleSortArrowClick: () => void;
}

const CustomerListHead: React.FC<CustomerListHeadProps> = ({
  sortingLogo,
  isRotated,
  toggleSortingDirection,
  handleSortArrowClick,
}) => (
  <div className={style.head_list}>
    <h5>#</h5>
    <div className={style.initials}>
      <h5>ПІБ</h5>
      <button onClick={toggleSortingDirection}>
        {sortingLogo ? <SortingZA /> : <SortingAZ />}
      </button>
    </div>
    <h5>ЄДРПОУ</h5>
    <h5>Тариф</h5>
    <div className={style.term}>
      <h5>Термін договору</h5>
      <button onClick={handleSortArrowClick}>
        <SortArrow className={isRotated ? style.rotated : ''} />
      </button>
    </div>
    <h5>Телефон</h5>
  </div>
);

export default CustomerListHead;
