import React, { ChangeEvent } from 'react';
import style from './SearchInput.module.css';
import CrossIcon from '../icons/Сross';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
}

const SearchInput = ({ value, onChange, onClear }: SearchInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onClear();
  };

  return (
    <div className={style.inputContainer}>
      <label>Клієнт/Перекладач</label>
      <input
        type="text"
        placeholder="Пошук"
        onChange={handleChange}
        value={value}
      />
      {value && (
        <div className={style.crossIcon} onClick={handleClear}>
          <CrossIcon />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
