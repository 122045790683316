import React, { useEffect, useRef, useState } from 'react';
import style from './TimePicker.module.css';
import BottomArrow from './icon/bottomArrow';
import TopArrow from './icon/topArrow';

interface TimePickerProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({
  label,
  value = '00:00',
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const pickerRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedValue(value);
    }
  }, [isOpen, value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      const selectedIndex = generateTimeOptions().indexOf(selectedValue);
      if (selectedIndex !== -1 && optionRefs.current[selectedIndex]) {
        optionRefs.current[selectedIndex].scrollIntoView({ block: 'nearest' });
      }
    }
  }, [isOpen, selectedValue]);

  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 10) {
        const hour = String(h).padStart(2, '0');
        const minute = String(m).padStart(2, '0');
        options.push(`${hour}:${minute}`);
      }
    }
    return options;
  };

  const handleOptionClick = (time: string) => {
    setSelectedValue(time);
    onChange(time);
    setIsOpen(false);
  };

  const handlePickerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const getDropdownPosition = () => {
    if (pickerRef.current) {
      const rect = pickerRef.current.getBoundingClientRect();
      if (rect.bottom + 220 > window.innerHeight) {
        return { bottom: '100%', top: 'auto' };
      }
    }
    return { top: '100%', bottom: 'auto' };
  };

  return (
    <div
      ref={pickerRef}
      className={`${style.timePicker} ${isOpen ? style.open : ''}`}
    >
      <label className={`${style.label} ${isOpen ? style.openLabel : ''}`}>
        {label}
      </label>
      <div className={style.selectWrapper} onClick={handlePickerClick}>
        <div className={style.timeSelect}>
          {selectedValue}
          <div className={style.arrowWrapper}>
            {isOpen ? (
              <TopArrow fill={'#0a8081'} />
            ) : (
              <BottomArrow fill={'#596987'} />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={style.options} style={getDropdownPosition()}>
          {generateTimeOptions().map((time, index) => (
            <div
              key={time}
              ref={(el) => (optionRefs.current[index] = el!)}
              className={`${style.option} ${time === selectedValue ? style.selected : ''}`}
              onClick={() => handleOptionClick(time)}
            >
              {time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TimePicker;
