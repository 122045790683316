import React from 'react';

interface DetailsIconProps {
  fill?: string;
}

const DetailsIcon: React.FC<DetailsIconProps> = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 16C16.1667 16 15.4583 15.7083 14.875 15.125C14.2917 14.5417 14 13.8333 14 13C14 12.1667 14.2917 11.4583 14.875 10.875C15.4583 10.2917 16.1667 10 17 10C17.8333 10 18.5417 10.2917 19.125 10.875C19.7083 11.4583 20 12.1667 20 13C20 13.8333 19.7083 14.5417 19.125 15.125C18.5417 15.7083 17.8333 16 17 16ZM17 14C17.2833 14 17.5208 13.9042 17.7125 13.7125C17.9042 13.5208 18 13.2833 18 13C18 12.7167 17.9042 12.4792 17.7125 12.2875C17.5208 12.0958 17.2833 12 17 12C16.7167 12 16.4792 12.0958 16.2875 12.2875C16.0958 12.4792 16 12.7167 16 13C16 13.2833 16.0958 13.5208 16.2875 13.7125C16.4792 13.9042 16.7167 14 17 14ZM11 23V20.1C11 19.75 11.0833 19.4208 11.25 19.1125C11.4167 18.8042 11.65 18.5583 11.95 18.375C12.4833 18.0583 13.0458 17.7958 13.6375 17.5875C14.2292 17.3792 14.8333 17.225 15.45 17.125L17 19L18.55 17.125C19.1667 17.225 19.7667 17.3792 20.35 17.5875C20.9333 17.7958 21.4917 18.0583 22.025 18.375C22.325 18.5583 22.5625 18.8042 22.7375 19.1125C22.9125 19.4208 23 19.75 23 20.1V23H11ZM12.975 21H16.05L14.7 19.35C14.4 19.4333 14.1083 19.5417 13.825 19.675C13.5417 19.8083 13.2583 19.95 12.975 20.1V21ZM17.95 21H21V20.1C20.7333 19.9333 20.4583 19.7875 20.175 19.6625C19.8917 19.5375 19.6 19.4333 19.3 19.35L17.95 21ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V10C20.7333 9.66667 20.4417 9.35 20.125 9.05C19.8083 8.75 19.4333 8.55 19 8.45V5H5V19H9.15C9.1 19.1833 9.0625 19.3667 9.0375 19.55C9.0125 19.7333 9 19.9167 9 20.1V21H5ZM7 9H14C14.4333 8.66667 14.9083 8.41667 15.425 8.25C15.9417 8.08333 16.4667 8 17 8V7H7V9ZM7 13H12C12 12.65 12.0375 12.3083 12.1125 11.975C12.1875 11.6417 12.2917 11.3167 12.425 11H7V13ZM7 17H10.45C10.6333 16.85 10.8292 16.7167 11.0375 16.6C11.2458 16.4833 11.4583 16.375 11.675 16.275V15H7V17ZM5 19V5V8.425V8V19Z" />
  </svg>
);

export default DetailsIcon;
