import React, { useState } from 'react';
import style from './InputOtp.module.css';

interface OTPInputProps {
  length: number;
  onChange: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onChange }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;

    if (/[^0-9]/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < length - 1) {
      const nextInput = document.getElementById(
        `otp-input-${index + 1}`,
      ) as HTMLInputElement | null;
      if (nextInput) {
        nextInput.focus();
      }
    }

    onChange(newOtp.join(''));
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        const prevInput = document.getElementById(
          `otp-input-${index - 1}`,
        ) as HTMLInputElement | null;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  return (
    <div className={style.input_container}>
      {otp.map((data, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength={1}
          value={otp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className={style.otp_input}
        />
      ))}
    </div>
  );
};

export default OTPInput;
