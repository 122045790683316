import { useState } from 'react';

export const usePlanModal = () => {
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [canMoveModal, setCanMoveModal] = useState<boolean>(false);

  const handleMenuButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    planId: any,
  ) => {
    console.log('Menu button clicked for planId:', planId);
    const rect = e.currentTarget.getBoundingClientRect();
    const modalHeight = 140;
    const isEnoughSpaceDown = window.innerHeight - rect.bottom > modalHeight;

    setModalPosition({
      top: isEnoughSpaceDown
        ? rect.bottom + window.scrollY
        : rect.top + window.scrollY - modalHeight,
      left: rect.left + window.scrollX - 240,
    });

    if (!isEnoughSpaceDown) {
      setModalPosition({
        top: rect.top + window.scrollY - modalHeight,
        left: rect.left + window.scrollX - 260,
      });
    } else {
      setModalPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 260,
      });
    }

    setSelectedPlanId(planId);
    setCanMoveModal(true);
  };

  const handleCloseModal = () => {
    setModalPosition(null);
    setSelectedPlanId(null);
    setCanMoveModal(false);
  };

  return {
    modalPosition,
    selectedPlanId,
    canMoveModal,
    handleMenuButtonClick,
    handleCloseModal,
  };
};
