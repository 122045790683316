import React from 'react';
import style from './Footer.module.css';

const Footer: React.FC<{ handleSave: () => void }> = ({ handleSave }) => (
  <div className={style.modalFooter}>
    <button className={style.saveButton} onClick={handleSave}>
      Зберегти
    </button>
  </div>
);

export default Footer;
