import axiosInstance from '../../../utils/axiosInstance';

export const fetchEmployeesApi = async (
  selectedClientId: number,
  pageIndex: number,
  orderDirection: 'ASC' | 'DESC',
  searchText: string,
) => {
  const response = await axiosInstance.post('/Admin/GetWorkerList', {
    pageIndex,
    pageSize: 10,
    searchString: searchText,
    orderDirection,
    companyId: selectedClientId,
  });
  return response.data;
};

export const deleteEmployeeApi = async (
  workerId: string,
  selectedClientId: number | null,
) => {
  const response = await axiosInstance.delete(
    '/Admin/RemoveWorkerFromCompany',
    {
      params: { workerId, companyId: selectedClientId },
    },
  );
  return response.status;
};
