import { UserData, PasswordData } from '../../types';
import axiosInstance from '../../utils/axiosInstance';

export const fetchUserData = async (): Promise<UserData> => {
  const response = await axiosInstance.get<UserData>('/User/Me');
  return response.data;
};

export const uploadAvatar = async (
  image: File,
): Promise<{ imageUrl: string }> => {
  const formData = new FormData();
  formData.append('file', image);

  const response = await axiosInstance.post('/File/UploadAvatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.data && typeof response.data.url === 'string') {
    return { imageUrl: response.data.url };
  } else {
    console.error('Unexpected response:', response.data);
    throw new Error('Invalid response from uploadAvatar');
  }
};

export const updateUserData = async (
  updatedData: Partial<UserData>,
): Promise<void> => {
  await axiosInstance.patch('/User/UpdateUserData', updatedData);
};

export const updatePassword = async (
  passwordData: PasswordData,
): Promise<void> => {
  await axiosInstance.patch('/User/UpdatePassword', {
    newPassword: passwordData.newPassword,
    oldPassword: passwordData.oldPassword,
  });
};

export const sendOtpForEmailUpdate = async (
  newEmail: string,
): Promise<void> => {
  await axiosInstance.post('/User/SendOtpForEmailUpdate', {
    email: newEmail,
  });
};

export const updateEmailWithOtp = async (
  newEmail: string,
  otp: string,
): Promise<void> => {
  await axiosInstance.patch('/User/UpdateEmail', {
    email: newEmail,
    otp: otp,
  });
};

export const resendOtpForEmailUpdate = async (data: { email: string }) => {
  return await axiosInstance.post('/User/ResendOtpForEmailUpdate', data);
};
