interface HistoryLogoProps {
  fill: string;
}

const HistoryLogo = ({ fill }: HistoryLogoProps) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 18.5C6.7 18.5 4.69583 17.7375 2.9875 16.2125C1.27917 14.6875 0.3 12.7833 0.05 10.5H2.1C2.33333 12.2333 3.10417 13.6667 4.4125 14.8C5.72083 15.9333 7.25 16.5 9 16.5C10.95 16.5 12.6042 15.8208 13.9625 14.4625C15.3208 13.1042 16 11.45 16 9.5C16 7.55 15.3208 5.89583 13.9625 4.5375C12.6042 3.17917 10.95 2.5 9 2.5C7.85 2.5 6.775 2.76667 5.775 3.3C4.775 3.83333 3.93333 4.56667 3.25 5.5H6V7.5H0V1.5H2V3.85C2.85 2.78333 3.8875 1.95833 5.1125 1.375C6.3375 0.791667 7.63333 0.5 9 0.5C10.25 0.5 11.4208 0.7375 12.5125 1.2125C13.6042 1.6875 14.5542 2.32917 15.3625 3.1375C16.1708 3.94583 16.8125 4.89583 17.2875 5.9875C17.7625 7.07917 18 8.25 18 9.5C18 10.75 17.7625 11.9208 17.2875 13.0125C16.8125 14.1042 16.1708 15.0542 15.3625 15.8625C14.5542 16.6708 13.6042 17.3125 12.5125 17.7875C11.4208 18.2625 10.25 18.5 9 18.5ZM11.8 13.7L8 9.9V4.5H10V9.1L13.2 12.3L11.8 13.7Z"
      fill={fill}
    />
  </svg>
);

export default HistoryLogo;
