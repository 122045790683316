import React from 'react';
import { Translator } from '../../../types';
import ClientItem from '../ClientItem/ClientItem';
import style from './ClientList.module.css';

interface ClientListProps {
  translators: Translator[];
  onDelete: (translatorId: string) => void;
  onRefresh: () => void;
}

const ClientList: React.FC<ClientListProps> = ({
  translators,
  onDelete,
  onRefresh,
}) => {
  return (
    <div className={style.clientList}>
      {translators.map((translator, index) => (
        <ClientItem
          key={`${translator.id}-${index}`}
          translator={translator}
          index={index}
          onDelete={onDelete}
          onRefresh={onRefresh}
        />
      ))}
    </div>
  );
};

export default ClientList;
