import { createAction } from '@reduxjs/toolkit';
import { Translator } from '../../types';

export const loadTranslatorsRequest = createAction<{
  pageIndex: number;
  pageSize: number;
  orderDirection: string;
}>('LOAD_TRANSLATORS_REQUEST');

export const loadTranslatorsSuccess = createAction<Translator[]>(
  'LOAD_TRANSLATORS_SUCCESS',
);
export const appendTranslatorsSuccess = createAction<Translator[]>(
  'APPEND_TRANSLATORS_SUCCESS',
);
export const loadTranslatorsFailure = createAction<string>(
  'LOAD_TRANSLATORS_FAILURE',
);

export const searchTranslatorsRequest = createAction(
  'SEARCH_TRANSLATORS_REQUEST',
);
export const searchTranslatorsSuccess = createAction<Translator[]>(
  'SEARCH_TRANSLATORS_SUCCESS',
);
export const searchTranslatorsFailure = createAction<string>(
  'SEARCH_TRANSLATORS_FAILURE',
);

export const setFetchLimit = createAction<number>('SET_FETCH_LIMIT');

export const deleteTranslator = createAction<string>('DELETE_TRANSLATOR');

export const updateTranslatorSuccess = createAction<Translator>(
  'UPDATE_TRANSLATOR_SUCCESS',
);

export const updateWorkScheduleRequest = createAction<{
  id: string;
  schedule: Translator['weeklyWorkSchedule'];
}>('UPDATE_WORK_SCHEDULE_REQUEST');
export const updateWorkScheduleSuccess = createAction<Translator>(
  'UPDATE_WORK_SCHEDULE_SUCCESS',
);
export const updateWorkScheduleFailure = createAction<string>(
  'UPDATE_WORK_SCHEDULE_FAILURE',
);
