import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Auth/SignIn`,
        credentials,
      );
      localStorage.setItem('jwtToken', response.data.jwtToken);
      Cookies.set('refreshToken', response.data.refreshToken);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unknown error occurred',
        });
      }
    }
  },
);

export const refreshJwtToken = createAsyncThunk(
  'auth/refreshJwtToken',
  async (_, thunkAPI) => {
    try {
      const refreshToken = Cookies.get('refreshToken');
      const jwtToken = localStorage.getItem('jwtToken');

      if (!refreshToken || !jwtToken) {
        return thunkAPI.rejectWithValue({
          message: 'No refresh token or JWT token available',
        });
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Auth/Refresh`,
        {
          jwtToken: jwtToken,
          refreshToken: refreshToken,
        },
      );

      localStorage.setItem('jwtToken', response.data.jwtToken);
      Cookies.set('refreshToken', response.data.refreshToken);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unknown error occurred',
        });
      }
    }
  },
);

export const performLogout = createAsyncThunk(
  'auth/logout',
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem('jwtToken');
      Cookies.remove('refreshToken');
      return;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unknown error occurred',
        });
      }
    }
  },
);
