import React from 'react';
import CrossIcon from '../../СompanyForm/icon/Сross';
import SearchIcon from '../icons/SearchIcon';
import style from './SearchBar.module.css';

interface SearchBarProps {
  searchText: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchText,
  onSearchChange,
  onClearSearch,
}) => (
  <div className={style.searchWrap}>
    <div className={style.searchIcon}>
      <SearchIcon />
    </div>
    <input
      type="text"
      value={searchText}
      onChange={onSearchChange}
      placeholder="Search..."
      className={style.searchInput}
    />
    {searchText.length > 0 && (
      <div className={style.clearIcon} onClick={onClearSearch}>
        <CrossIcon />
      </div>
    )}
  </div>
);

export default SearchBar;
