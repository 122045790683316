import React, { FC, useState } from 'react';
import DeleteIcon from '../icons/deleteIcon';
import TrialingIcon from '../icons/trialingIcon';
import { RemoveModal } from '../RemoveModal/RemoveModal';
import style from './Tag.module.css';

interface TagType {
  id: number;
  name: string;
}

interface TagProps {
  tag: TagType;
  isSelected: boolean;
  onTagClick: (tag: TagType) => void;
  onDeleteTag: (
    tagId: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

const Tag: FC<TagProps> = ({ tag, isSelected, onTagClick, onDeleteTag }) => {
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  const handleOpenRemoveModal = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setIsOpenRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setIsOpenRemoveModal(false);
  };

  return (
    <div
      className={`${style.tag} ${isSelected ? style.selected : ''}`}
      onClick={() => onTagClick(tag)}
    >
      <button
        className={style.deleteButton}
        onClick={(event) => handleOpenRemoveModal(event)}
      >
        <DeleteIcon />
      </button>
      {tag.name}
      {isSelected && (
        <span className={style.svgIcon}>
          <TrialingIcon />
        </span>
      )}
      {isOpenRemoveModal && (
        <RemoveModal
          onClose={handleCloseRemoveModal}
          onConfirm={(event) => onDeleteTag(tag.id, event)}
          tagName={tag.name}
        />
      )}
    </div>
  );
};

export default Tag;
