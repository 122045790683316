import React, { useEffect, useState } from 'react';
import LeadingIcon from '../icon/Leading';
import DeleteIcon from '../icon/delete';
import style from './ActionMenu.module.css';

const ActionMenu: React.FC<{
  actionMenuRef: React.RefObject<HTMLDivElement>;
  actionMenuPosition: DOMRect;
  handleEdit: () => void;
  handleDelete: () => void;
  onClose: () => void;
}> = ({
  actionMenuRef,
  actionMenuPosition,
  handleEdit,
  handleDelete,
  onClose,
}) => {
  const [menuStyles, setMenuStyles] = useState<React.CSSProperties>({
    visibility: 'hidden',
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuRef, onClose]);

  useEffect(() => {
    if (actionMenuRef.current) {
      const top = actionMenuPosition.bottom + window.scrollY;
      const left = actionMenuPosition.left + window.scrollX;

      setMenuStyles({
        top: top - 250,
        left: left - 420,
        transform: 'translate(-50%, -50%)',
        visibility: 'visible',
      });
    }
  }, [actionMenuPosition, actionMenuRef]);

  return (
    <div ref={actionMenuRef} className={style.actionsMenu} style={menuStyles}>
      <button onClick={handleEdit} className={style.changeButton}>
        <LeadingIcon />
        Змінити
      </button>
      <button onClick={handleDelete} className={style.deleteButton}>
        <DeleteIcon />
        Видалити
      </button>
    </div>
  );
};

export default ActionMenu;
