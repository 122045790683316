import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export interface TranslationHistoryItem {
  callDuration: string;
  client: {
    id: string;
    userName: string;
    avatarUrl: string;
  };
  createdAt: string;
  finishedAt: string;
  finishedBy: string;
  translator: {
    id: string;
    userName: string;
    avatarUrl: string;
  };
  updatedAt: string;
}

dayjs.extend(duration);

export const getFormattedDuration = (timeString: string) => {
  const parts = timeString.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const secondsAndMs = parseFloat(parts[2]);

  const totalMilliseconds = (hours * 3600 + minutes * 60 + secondsAndMs) * 1000;

  const callDuration = dayjs.duration(totalMilliseconds, 'milliseconds');

  let formattedDuration;
  if (callDuration.asHours() < 1) {
    formattedDuration = callDuration.format('mm:ss'); // Формат для звонков менее часа
  } else {
    formattedDuration = callDuration.format('HH:mm:ss'); // Формат для звонков более часа
  }

  return formattedDuration;
};
