import React, { useEffect, useRef } from 'react';
import { MouseEvent as ReactMouseEvent } from 'react';
import CrossIcon from '../../icon/Сross';

import styles from './RemoveModal.module.css';

interface RemoveModalProps {
  onClose: () => void;
  onConfirm: (event: ReactMouseEvent<HTMLButtonElement>) => void;
  tagName: string;
}

export const RemoveModal = ({
  onClose,
  onConfirm,
  tagName,
}: RemoveModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={styles.modalBackdrop}>
      <div ref={modalRef} className={styles.modalContent}>
        <div className={styles.title}>
          <h6>Видалення тегу</h6>
          <button className={styles.closeButton} onClick={onClose}>
            <CrossIcon />
          </button>
        </div>
        <div className={styles.tagNameWrapper}>
          <p>{`Ви підтверджуєте видалення тегу "${tagName}"?`}</p>
        </div>
        <div className={styles.modalActions}>
          <button onClick={onClose}>Ні</button>
          <button onClick={onConfirm}>Так</button>
        </div>
      </div>
    </div>
  );
};
