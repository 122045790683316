import React from 'react';
import { Admin } from '../../../types';
import AdminItem from '../AdminItem/AdminItem';
import AscIcon from '../icons/ascIcon';
import CsaIcon from '../icons/csaIcon';
import style from './adminList.module.css';

interface AdminListProps {
  admins: Admin[];
  orderDirection: 'asc' | 'desc';
  handleSort: () => void;
  openModal: (admin: Admin) => void;
  isLoading: boolean;
  hasMore: boolean;
  fetchAdmins: () => void;
}

const AdminList: React.FC<AdminListProps> = ({
  admins,
  orderDirection,
  handleSort,
  openModal,
  isLoading,
  hasMore,
  fetchAdmins,
}) => {
  const isFewAdmins = admins.length <= 8;

  return (
    <div
      className={`${style.wrapInformation} ${
        isFewAdmins ? style.fewAdmins : ''
      }`}
    >
      <div className={style.adminTitle}>
        <h6>#</h6>
        <div className={style.initialsWrap}>
          <h6 className={style.nameInfo}>Піб</h6>
          <button onClick={handleSort} className={style.sortButton}>
            {orderDirection === 'asc' ? <AscIcon /> : <CsaIcon />}
          </button>
        </div>
        <h6>Email</h6>
      </div>
      <div>
        {admins.length === 0 ? (
          <div className={style.noResults}>
            <p>
              Адміністратора не знайдено. Спробуйте іншу назву або додайте
              нового адміністратора.
            </p>
          </div>
        ) : (
          admins.map((admin, index) => (
            <AdminItem
              key={admin.id}
              admin={admin}
              index={index}
              openModal={openModal}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default AdminList;
