const Visibility = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 9C9.9375 9 10.7344 8.67188 11.3906 8.01562C12.0469 7.35938 12.375 6.5625 12.375 5.625C12.375 4.6875 12.0469 3.89062 11.3906 3.23438C10.7344 2.57812 9.9375 2.25 9 2.25C8.0625 2.25 7.26562 2.57812 6.60938 3.23438C5.95312 3.89062 5.625 4.6875 5.625 5.625C5.625 6.5625 5.95312 7.35938 6.60938 8.01562C7.26562 8.67188 8.0625 9 9 9ZM9 7.65C8.4375 7.65 7.95938 7.45313 7.56563 7.05938C7.17188 6.66563 6.975 6.1875 6.975 5.625C6.975 5.0625 7.17188 4.58438 7.56563 4.19063C7.95938 3.79688 8.4375 3.6 9 3.6C9.5625 3.6 10.0406 3.79688 10.4344 4.19063C10.8281 4.58438 11.025 5.0625 11.025 5.625C11.025 6.1875 10.8281 6.66563 10.4344 7.05938C10.0406 7.45313 9.5625 7.65 9 7.65ZM9 11.25C7.175 11.25 5.5125 10.7406 4.0125 9.72188C2.5125 8.70313 1.425 7.3375 0.75 5.625C1.425 3.9125 2.5125 2.54688 4.0125 1.52813C5.5125 0.509375 7.175 0 9 0C10.825 0 12.4875 0.509375 13.9875 1.52813C15.4875 2.54688 16.575 3.9125 17.25 5.625C16.575 7.3375 15.4875 8.70313 13.9875 9.72188C12.4875 10.7406 10.825 11.25 9 11.25ZM9 9.75C10.4125 9.75 11.7094 9.37812 12.8906 8.63437C14.0719 7.89062 14.975 6.8875 15.6 5.625C14.975 4.3625 14.0719 3.35938 12.8906 2.61562C11.7094 1.87187 10.4125 1.5 9 1.5C7.5875 1.5 6.29063 1.87187 5.10938 2.61562C3.92812 3.35938 3.025 4.3625 2.4 5.625C3.025 6.8875 3.92812 7.89062 5.10938 8.63437C6.29063 9.37812 7.5875 9.75 9 9.75Z" />
  </svg>
);

export default Visibility;
