const LoaderIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 18.5C1.45 18.5 0.979167 18.3042 0.5875 17.9125C0.195833 17.5208 0 17.05 0 16.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H11V2.5H2V16.5H16V7.5H18V16.5C18 17.05 17.8042 17.5208 17.4125 17.9125C17.0208 18.3042 16.55 18.5 16 18.5H2ZM14 6.5V4.5H12V2.5H14V0.5H16V2.5H18V4.5H16V6.5H14ZM3 14.5H15L11.25 9.5L8.25 13.5L6 10.5L3 14.5Z"
      fill="#0A8081"
    />
  </svg>
);

export default LoaderIcon;
