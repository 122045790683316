import { WorkTime } from '../../../../types';

export const sanitizeTime = (time: string) => {
  if (time.split(':').length === 4) {
    return time.split(':').slice(0, 3).join(':');
  }
  return time;
};

export const formatTime = (time: string) => {
  return time.slice(0, 5);
};

export interface WorkTimeModalProps {
  isOpen: boolean;
  onClose: () => void;
  workTime: WorkTime[];
  onSave: (workTime: WorkTime[]) => void;
}
