import React, { ChangeEvent, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Translator } from '../../../types';
import CustomSelect from './CustomSelect/CustomSelect';
import ImageUpload from './ImageUpload/ImageUpload';
import Input from './Input/Input';
import SuccessModal from './SuccessModal/SuccessModal';
import style from './TranslatorInfoModal.module.css';
import { handleFormSubmit, handleImageUpload } from './helpers';
import ScheduleIcon from './icons/Schedule';
import CrossIcon from './icons/Сross';

interface TranslatorInfoModalProps {
  onClose: () => void;
  translator?: Translator;
  onRefresh: () => void;
  onOpenWorkSchedule: () => void;
}

interface FormData extends FieldValues {
  role: string;
  tag: string;
  name: string;
  email: string;
  phone: string;
}

const TranslatorInfoModal: React.FC<TranslatorInfoModalProps> = ({
  onClose,
  translator,
  onRefresh,
  onOpenWorkSchedule,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm<FormData>({ mode: 'onChange' });
  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (translator) {
      setValue('role', 'Перекладач');
      setValue('tag', '');
      setValue('name', translator.userName);
      setValue('email', translator.email);
      setValue('phone', translator.phone);
    }
  }, [translator, setValue]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const success = await handleFormSubmit(
      data,
      image,
      translator,
      dispatch,
      setError,
      setSubmittedEmail,
      setShowSuccessModal,
    );
    if (success) {
      setShowSuccessModal(true);
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleImageUpload(e, setImage);
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  const watchRole = watch('role');
  const watchName = watch('name');
  const watchEmail = watch('email');
  const watchPhone = watch('phone');

  const isFormValid =
    watchRole &&
    watchName &&
    watchEmail &&
    watchPhone &&
    !errors.role &&
    !errors.name &&
    !errors.email &&
    !errors.phone &&
    isValid;

  const handleWorkScheduleClick = () => {
    onClose();
    onOpenWorkSchedule();
  };

  return (
    <div className={style.formOverlay}>
      {showSuccessModal ? (
        <div
          style={{
            background: 'rgba(0, 0, 0, 0.8)',
            padding: '20px',
            color: '#fff',
          }}
        >
          <SuccessModal email={submittedEmail} onClose={handleClose} />
        </div>
      ) : (
        <div className={style.formContainer}>
          <div className={style.header}>
            <h2>Редагувати перекладача</h2>
            <button
              type="button"
              onClick={onClose}
              className={style.closeButton}
            >
              <CrossIcon />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={style.wrap_form}>
            <div className={style.section}>
              <ImageUpload
                image={
                  image
                    ? URL.createObjectURL(image)
                    : translator?.avatarUrl || null
                }
                handleImageChange={handleImageChange}
              />
              <CustomSelect
                name="role"
                label="Роль"
                options={[{ value: 'Перекладач', label: 'Перекладач' }]}
                register={register}
                defaultValue={translator ? 'Перекладач' : ''}
                validation={{ required: "Це поле є обов'язковим" }}
                errors={errors}
              />
              <div className={style.inputContainer}>
                <Input
                  type="text"
                  register={register}
                  name="tag"
                  label="Тег"
                  validation={{ required: "Це поле є обов'язковим" }}
                  errors={errors}
                />
              </div>
            </div>
            <div>
              <div className={style.title}>
                <h4>Персональна інформація</h4>
              </div>
              <div className={style.personal_information}>
                <Input
                  type="text"
                  register={register}
                  name="name"
                  label="ПІБ"
                  validation={{
                    required: "Це поле є обов'язковим",
                    pattern: {
                      value: /^[А-Яа-яЁёЇїІіЄєҐґ\s]+$/,
                      message: 'Має приймати лише літери Кирилиці',
                    },
                  }}
                  errors={errors}
                />
                <Input
                  type="email"
                  register={register}
                  name="email"
                  label="Email"
                  validation={{
                    required: "Це поле є обов'язковим",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Невірний формат email',
                    },
                  }}
                  errors={errors}
                />
                <Input
                  type="tel"
                  register={register}
                  name="phone"
                  label="Телефон"
                  validation={{
                    required: "Це поле є обов'язковим",
                    pattern: {
                      value: /^\+\d{12}$/,
                      message: 'Невірний формат телефону',
                    },
                  }}
                  errors={errors}
                />
              </div>
            </div>
            <div className={style.footer}>
              <button
                type="button"
                className={style.scheduleButton}
                onClick={handleWorkScheduleClick}
              >
                <ScheduleIcon />
                Графік роботи
              </button>
              <button
                type="submit"
                disabled={!isFormValid}
                className={
                  !isFormValid ? style.buttons_disabled : style.buttons
                }
              >
                Зберегти
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TranslatorInfoModal;
