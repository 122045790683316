import React, { useEffect, useState } from 'react';
import { Company } from '../../../types';
import style from './ModalWindowCompany.module.css';
import DeleteLogo from './icons/DeleteLogo';
import DetailsLogo from './icons/DetailsLogo';
import InvitationLogo from './icons/InvitationLogo';

interface ModalWindowCompanyProps {
  company: Company[];
  selectedClientId: number | null;
  onClose: () => void;
  onDetails: (id: number) => void;
  onSendInvitation: (id: number) => void;
  onDelete: (id: number) => void;
  onShowEmployees: (id: number) => void; // Add new prop
  buttonRef: { current: HTMLButtonElement | null };
}

const ModalWindowCompany: React.FC<ModalWindowCompanyProps> = ({
  company,
  selectedClientId,
  onClose,
  onDetails,
  onSendInvitation,
  onDelete,
  onShowEmployees, // Add new prop
  buttonRef,
}) => {
  const [position, setPosition] = useState<{ top: string; left: string }>({
    top: '0px',
    left: '0px',
  });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.querySelector(`.${style.modalContent}`);
      if (
        modal &&
        buttonRef.current &&
        !modal.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef, onClose]);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const modal = document.querySelector(
        `.${style.modalContent}`,
      ) as HTMLElement;

      if (modal) {
        const modalHeight = modal.offsetHeight;
        const modalWidth = modal.offsetWidth;
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;

        let top = buttonRect.bottom + window.scrollY;
        let left = buttonRect.left + window.scrollX;

        if (top + modalHeight > screenHeight) {
          top = buttonRect.top + window.scrollY - modalHeight;
        }

        if (left + modalWidth > screenWidth) {
          left = screenWidth - modalWidth;
        }

        setPosition({
          top: `${top}px`,
          left: `${left - 85}px`,
        });
      }
    }
  }, [buttonRef]);

  if (selectedClientId === null) return null;

  const client = company.find((company) => company.id === selectedClientId);
  if (!client) return null;

  return (
    <div className={style.modalOverlay} onClick={onClose}>
      <div
        className={style.modalContent}
        style={{ top: position.top, left: position.left }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => onDetails(client.id)}
          className={style.detailButton}
        >
          <DetailsLogo />
          Деталі
        </button>
        <button
          onClick={() => onShowEmployees(client.id)}
          className={style.detailButton}
        >
          <DetailsLogo />
          Співробітники
        </button>
        <button
          onClick={() => onSendInvitation(client.id)}
          className={style.inviteButton}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <InvitationLogo />
          Надіслати запрошення
        </button>
        <div
          className={`${style.borderBottom} ${isHovered ? style.hidden : ''}`}
        ></div>
        <button
          onClick={() => onDelete(client.id)}
          className={style.delateButton}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <DeleteLogo />
          Видалити
        </button>
      </div>
    </div>
  );
};

export default ModalWindowCompany;
