const DeleteIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.0125 3V2.9875H1H0.0125V1.0125H5H5.0125V1V0.0125H10.9875V1V1.0125H11H15.9875V2.9875H15H14.9875V3V16C14.9875 16.5467 14.793 17.0143 14.4037 17.4037C14.0143 17.793 13.5467 17.9875 13 17.9875H3C2.45334 17.9875 1.98568 17.793 1.59634 17.4037C1.207 17.0143 1.0125 16.5467 1.0125 16V3ZM13.0125 3V2.9875H13H3H2.9875V3V16V16.0125H3H13H13.0125V16V3ZM6.9875 5.0125V13.9875H5.0125V5.0125H6.9875ZM10.9875 5.0125V13.9875H9.0125V5.0125H10.9875Z" />
  </svg>
);

export default DeleteIcon;
