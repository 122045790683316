const BackArrowIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.71164 1.91125L3.47645 7.14645L2.62289 8H3.83H15.5V9H3.83H2.62442L3.47613 9.85324L8.70321 15.0897L8 15.7929L0.707107 8.5L8.00125 1.20586L8.71164 1.91125Z" />
  </svg>
);

export default BackArrowIcon;
