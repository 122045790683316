import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../store/actions/authActions';
import { showSnackbar } from '../../store/reducers/snackbarSlice';
import { AppDispatch, RootState } from '../../store/store';
import Button from '../Button/Button';
import Input from '../Input/Input';
import style from './Login.module.css';

interface LoginProps {
  setActiveComponent: (component: string) => void;
}

interface IFormInput {
  email: string;
  password: string;
}

const Login: React.FC<LoginProps> = ({ setActiveComponent }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { jwtToken, error: serverError }: any = useSelector(
    (state: RootState) => state.auth,
  );

  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm<IFormInput>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (jwtToken) {
      navigate('/customer');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    if (serverError) {
      const errorMessage =
        typeof serverError === 'string'
          ? serverError
          : serverError?.message || 'Невірний email чи пароль';
      dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
    }
  }, [serverError, dispatch]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(login({ email: data.email, password: data.password }));
  };

  return (
    <div className={style.login_container}>
      <h2 className={style.login_title}>Ласкаво просимо</h2>
      <form className={style.login_form} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.form_group}>
          <Input
            id="email"
            labelText="EMAIL"
            type="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Invalid email address',
              },
              onBlur: () => {
                trigger('email');
                setEmailTouched(true);
              },
              onChange: () => {
                if (emailTouched) {
                  setEmailTouched(false);
                }
              },
            })}
          />
          {/* {errors.email && emailTouched && errors.email.type !== 'required' && (
            <div className={style.error_message}>{errors.email.message}</div>
          )} */}
        </div>
        <div className={style.form_group}>
          <Input
            id="password"
            labelText="PASSWORD"
            type="password"
            {...register('password', {
              required: 'Password is required',
              onBlur: () => {
                trigger('password');
                setPasswordTouched(true);
              },
              onChange: () => {
                if (passwordTouched) {
                  setPasswordTouched(false);
                }
              },
            })}
          />
          {/* {errors.password &&
            passwordTouched &&
            errors.password.type !== 'required' && (
              <div className={style.error_message}>
                {errors.password.message}
              </div>
            )} */}
        </div>
        <div className={style.wrap_link}>
          <Link
            to="#"
            onClick={() => setActiveComponent('forgotPassword')}
            className={style.text_link}
          >
            Забули пароль?
          </Link>
        </div>
        <Button type="submit" text="Увійти" disabled={!isValid} />
      </form>
    </div>
  );
};

export default Login;
