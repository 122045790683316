import React from 'react';

interface SortingIconProps {
  fill?: string;
}

const SortingZA: React.FC<SortingIconProps> = ({ fill = '#152837' }) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.27051 3.54004L9.27051 0.540039L12.2705 3.54004H6.27051ZM9.27051 20.54L6.27051 17.54H12.2705L9.27051 20.54Z"
      fill={fill}
    />
    <path
      d="M16.0399 15.009C15.9186 14.6537 15.7929 14.3027 15.6629 13.956C15.5329 13.6093 15.4072 13.254 15.2859 12.89H11.4899C11.3686 13.254 11.2429 13.6137 11.1129 13.969C10.9916 14.3157 10.8702 14.6623 10.7489 15.009H9.0459C9.3839 14.047 9.70457 13.1587 10.0079 12.344C10.3112 11.5293 10.6059 10.758 10.8919 10.03C11.1866 9.302 11.4769 8.60867 11.7629 7.95C12.0489 7.29133 12.3436 6.64133 12.6469 6H14.1939C14.4972 6.64133 14.7919 7.29133 15.0779 7.95C15.3639 8.60867 15.6499 9.302 15.9359 10.03C16.2306 10.758 16.5296 11.5293 16.8329 12.344C17.1449 13.1587 17.4699 14.047 17.8079 15.009H16.0399ZM13.3879 7.742C13.1886 8.20133 12.9589 8.76033 12.6989 9.419C12.4476 10.0777 12.1832 10.7927 11.9059 11.564H14.8699C14.5926 10.7927 14.3239 10.0733 14.0639 9.406C13.8039 8.73867 13.5786 8.184 13.3879 7.742Z"
      fill={fill}
    />
    <path
      d="M4.563 5.90002C4.91833 5.90002 5.32133 5.91736 5.772 5.95202C6.22267 5.97802 6.64733 6.03436 7.046 6.12102V15H5.408V11.62H4.459C4.34633 11.62 4.22933 11.62 4.108 11.62C3.98667 11.6114 3.874 11.5984 3.77 11.581C3.59667 11.789 3.42333 12.0274 3.25 12.296C3.07667 12.556 2.90333 12.8377 2.73 13.141C2.56533 13.4357 2.39633 13.7434 2.223 14.064C2.05833 14.376 1.898 14.688 1.742 15H0C0.156 14.662 0.325 14.3197 0.507 13.973C0.689 13.6264 0.871 13.2927 1.053 12.972C1.24367 12.6427 1.43 12.3394 1.612 12.062C1.80267 11.776 1.976 11.5334 2.132 11.334C1.54267 11.1174 1.10933 10.7967 0.832 10.372C0.554666 9.94736 0.416 9.42302 0.416 8.79902C0.416 8.31369 0.511333 7.88902 0.702 7.52502C0.901333 7.16102 1.17867 6.86202 1.534 6.62802C1.898 6.38536 2.33567 6.20336 2.847 6.08202C3.35833 5.96069 3.93033 5.90002 4.563 5.90002ZM4.446 7.31702C4.11667 7.31702 3.809 7.34302 3.523 7.39502C3.24567 7.43836 3.003 7.51636 2.795 7.62902C2.587 7.74169 2.42233 7.89336 2.301 8.08402C2.18833 8.27469 2.132 8.50869 2.132 8.78603C2.132 9.08069 2.18833 9.32336 2.301 9.51402C2.42233 9.70469 2.59133 9.85636 2.808 9.96902C3.03333 10.0817 3.302 10.1597 3.614 10.203C3.93467 10.2464 4.29433 10.268 4.693 10.268H5.408V7.35602C5.10467 7.33002 4.784 7.31702 4.446 7.31702Z"
      fill={fill}
    />
  </svg>
);

export default SortingZA;
