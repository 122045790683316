import React from 'react';
import style from './SearchInput.module.css';
import SearchIcon from './icons/Search';
import СrossIcon from './icons/Сross';

interface SearchInputProps {
  onSearchChange: (query: string) => void;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  onClear?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onSearchChange,
  query,
  setQuery,
  onClear,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    onSearchChange(newQuery);
  };

  const handleClear = () => {
    setQuery('');
    if (onClear) onClear();
    onSearchChange('');
  };

  return (
    <div className={style.wrap_input}>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Пошук..."
        className={style.searchInput}
      />
      <SearchIcon />
      {query && (
        <button onClick={handleClear} className={style.crossIcon}>
          <СrossIcon />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
