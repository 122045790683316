import React, { useEffect, useState, useRef, useCallback } from 'react';
import PushPanel from '../PushPanel/PushPanel';
import PlanActionsModal from './PlanActionsModal/PlanActionsModal';
import NewPlanModal from './NewPlanModal/NewPlanModal';
import style from './plan.module.css';
import SortingAZ from './icons/SortingAZ';
import SortingZA from './icons/SortingZA';
import NonAvatar from './icons/nonAvatar';
import Sharpens from './icons/sharpens';
import { debounce } from 'lodash';
import { usePlanModal } from './hooks/usePlanModal';
import { PlanType } from './planTypes';
import { getBackgroundClass } from './helpers';
import {
  fetchClientsPlanRequestsList,
  fetchClientsPlansList,
} from './planService';
import { PlansResponse } from '../../types';
import CompanyForm from '../Company/СompanyForm/CompanyForm';

const PlanComponent: React.FC = () => {
  const [clientsPlans, setClientsPlans] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [activeType, setActiveType] = useState<'active' | 'completed'>(
    'active',
  );
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [needsScroll, setNeedsScroll] = useState<boolean>(false);
  const [isNewPlanModalOpen, setIsNewPlanModalOpen] = useState<boolean>(false);
  const [selectedPlanForEdit, setSelectedPlanForEdit] = useState<any | null>(
    null,
  );
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const displayPlanRef = useRef<HTMLDivElement | null>(null);

  const {
    modalPosition,
    selectedPlanId,
    canMoveModal,
    handleMenuButtonClick,
    handleCloseModal,
  } = usePlanModal();

  const isRequestInProgress = useRef(false);

  const fetchPlans = useCallback(async () => {
    if (isRequestInProgress.current) return;
    isRequestInProgress.current = true;

    setIsFetching(true);
    try {
      let data: PlansResponse;

      if (activeType === 'active') {
        data = await fetchClientsPlansList(
          pageIndex,
          12,
          searchQuery,
          isAscending ? 'asc' : 'desc',
        );
      } else {
        data = await fetchClientsPlanRequestsList(
          pageIndex,
          12,
          searchQuery,
          isAscending ? 'asc' : 'desc',
        );
      }

      if (pageIndex === 1) {
        setClientsPlans(data.items);
      } else {
        setClientsPlans((prevPlans) => [...prevPlans, ...data.items]);
      }

      setHasMore(data.items.length >= 12);
    } catch (error) {
      console.error('Error fetching plans:', error);
    } finally {
      setIsFetching(false);
      isRequestInProgress.current = false;
    }
  }, [activeType, pageIndex, isAscending, searchQuery]);

  useEffect(() => {
    setPageIndex(1);
    fetchPlans();
  }, [activeType, isAscending, searchQuery]);

  const handleActiveClick = () => {
    setActiveType('active');
  };

  const handleCompletedClick = () => {
    setActiveType('completed');
  };

  const handleSortClick = () => {
    setIsAscending((prevIsAscending) => !prevIsAscending);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    const checkIfNeedsScroll = () => {
      if (displayPlanRef.current) {
        const containerHeight = displayPlanRef.current.clientHeight;
        const contentHeight = displayPlanRef.current.scrollHeight;
        setNeedsScroll(contentHeight > containerHeight + 1);
      }
    };

    checkIfNeedsScroll();
    window.addEventListener('resize', checkIfNeedsScroll);

    return () => {
      window.removeEventListener('resize', checkIfNeedsScroll);
    };
  }, [clientsPlans]);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !isFetching) {
        setPageIndex((prevPageIndex) => prevPageIndex + 1);
      }
    },
    [hasMore, isFetching],
  );

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (lastElementRef.current && hasMore) {
      observerRef.current.observe(lastElementRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [clientsPlans, observerCallback, hasMore]);

  const handleClearSearch = () => {
    setSearchQuery('');
    setClientsPlans([]);
  };

  const noResultsMessage =
    searchQuery && clientsPlans.length === 0
      ? 'Співпадінь не знайдено. Спробуйте іншу назву, щоб розпочати роботу.'
      : '';

  const noPlansMessage =
    activeType === 'active' && clientsPlans.length === 0
      ? 'Немає активних тарифів.'
      : activeType === 'completed' && clientsPlans.length === 0
        ? 'Вхідних заявок наразі немає.'
        : '';

  const handleEditPlan = (planId: string) => {
    const selectedPlan = clientsPlans.find(
      (plan) => plan.id === planId || plan.requestId === planId,
    );

    if (selectedPlan?.userId === null) {
      setSelectedPlanForEdit(selectedPlan);
      setIsNewPlanModalOpen(true);
    } else if (selectedPlan?.userId) {
      setSelectedPlanForEdit(selectedPlan);
      setIsNewPlanModalOpen(true);
    } else {
      console.error('Plan not found for editing');
    }

    handleCloseModal();
  };

  const handleTogglePlanStatus = (planId: string) => {
    console.log(`Toggling status for plan with ID: ${planId}`);
  };

  const handleSavePlan = (updatedPlan: any) => {
    setIsNewPlanModalOpen(false);
  };

  const handleCloseNewPlanModal = () => {
    setIsNewPlanModalOpen(false);
  };
  return (
    <div className={style.wrapPlan}>
      <PushPanel
        onActiveClick={handleActiveClick}
        onCompletedClick={handleCompletedClick}
        activeType={activeType}
        onSearch={handleSearch}
        onClear={handleClearSearch}
      />
      <div
        className={`${style.displayPlan} ${needsScroll ? '' : style.borderBottomRadius}`}
        ref={displayPlanRef}
      >
        <div className={style.plansTitles}>
          <h5>#</h5>
          <div className={style.sortName}>
            <h5>Піб</h5>
            <button onClick={handleSortClick} className={style.sortButton}>
              {isAscending ? <SortingAZ /> : <SortingZA />}
            </button>
          </div>
          <h5>Тариф</h5>
        </div>
        <div className={style.scrollPlan}>
          {noPlansMessage && (
            <p className={style.noPlansMessage}>{noPlansMessage}</p>
          )}
          {noResultsMessage && !noPlansMessage && (
            <p className={style.noResultsMessage}>{noResultsMessage}</p>
          )}
          {!noResultsMessage &&
            !noPlansMessage &&
            clientsPlans.map((plan, index) => {
              const planId =
                activeType === 'completed' ? plan.requestId : plan.id;

              return (
                <div
                  key={`${planId}-${index}`}
                  className={style.planItem}
                  ref={
                    index === clientsPlans.length - 1 ? lastElementRef : null
                  }
                >
                  <h5>{index + 1}</h5>
                  <div className={style.infoWrap}>
                    <div className={style.avatarWrap}>
                      {plan.avatarUrl ? (
                        <img src={plan.avatarUrl} alt={plan.userName} />
                      ) : (
                        <NonAvatar />
                      )}
                    </div>
                    <h5>{plan.userName}</h5>
                  </div>
                  <div className={style.wrapDisplayPlan}>
                    <h5
                      className={`${style.planStyle} ${getBackgroundClass(
                        (plan.plan as PlanType) || (plan.newPlan as PlanType),
                      )}`}
                    >
                      {plan.plan || plan.newPlan}
                    </h5>
                  </div>
                  <button
                    className={style.menuButton}
                    onClick={(e) => handleMenuButtonClick(e, planId)}
                  >
                    <Sharpens />
                  </button>
                </div>
              );
            })}
        </div>
      </div>
      {modalPosition && selectedPlanId && (
        <PlanActionsModal
          isActive={activeType === 'completed'}
          onEdit={() => handleEditPlan(selectedPlanId)}
          onToggleStatus={() => handleTogglePlanStatus(selectedPlanId)}
          onClose={handleCloseModal}
          position={modalPosition}
          canMove={canMoveModal}
          selectedPlan={clientsPlans.find(
            (plan) =>
              plan.id === selectedPlanId || plan.requestId === selectedPlanId,
          )}
        />
      )}
      {isNewPlanModalOpen && selectedPlanForEdit?.userId === null ? (
        <CompanyForm
          companyData={selectedPlanForEdit}
          formDataArray={selectedPlanForEdit?.fields || []}
          onClose={handleCloseNewPlanModal}
          onSave={(savedCompany) => {
            handleCloseNewPlanModal();
          }}
          isEdit={true}
        />
      ) : (
        <NewPlanModal
          isOpen={isNewPlanModalOpen}
          selectedPlan={selectedPlanForEdit}
          onSave={handleSavePlan}
          onClose={handleCloseNewPlanModal}
        />
      )}
    </div>
  );
};

export default PlanComponent;
