interface TranslatorsProps {
  fill: string;
}

const TranslatorsLogo = ({ fill }: TranslatorsProps) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16.5C7.06667 16.5 6.175 16.6458 5.325 16.9375C4.475 17.2292 3.7 17.6667 3 18.25V18.5H13V18.25C12.3 17.6667 11.525 17.2292 10.675 16.9375C9.825 16.6458 8.93333 16.5 8 16.5ZM8 14.5C9.15 14.5 10.225 14.675 11.225 15.025C12.225 15.375 13.15 15.8667 14 16.5V2.5H2V16.5C2.85 15.8667 3.775 15.375 4.775 15.025C5.775 14.675 6.85 14.5 8 14.5ZM8 10.5C7.58333 10.5 7.22917 10.3542 6.9375 10.0625C6.64583 9.77083 6.5 9.41667 6.5 9C6.5 8.58333 6.64583 8.22917 6.9375 7.9375C7.22917 7.64583 7.58333 7.5 8 7.5C8.41667 7.5 8.77083 7.64583 9.0625 7.9375C9.35417 8.22917 9.5 8.58333 9.5 9C9.5 9.41667 9.35417 9.77083 9.0625 10.0625C8.77083 10.3542 8.41667 10.5 8 10.5ZM2 20.5C1.45 20.5 0.979167 20.3042 0.5875 19.9125C0.195833 19.5208 0 19.05 0 18.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H14C14.55 0.5 15.0208 0.695833 15.4125 1.0875C15.8042 1.47917 16 1.95 16 2.5V18.5C16 19.05 15.8042 19.5208 15.4125 19.9125C15.0208 20.3042 14.55 20.5 14 20.5H2ZM8 12.5C8.96667 12.5 9.79167 12.1583 10.475 11.475C11.1583 10.7917 11.5 9.96667 11.5 9C11.5 8.03333 11.1583 7.20833 10.475 6.525C9.79167 5.84167 8.96667 5.5 8 5.5C7.03333 5.5 6.20833 5.84167 5.525 6.525C4.84167 7.20833 4.5 8.03333 4.5 9C4.5 9.96667 4.84167 10.7917 5.525 11.475C6.20833 12.1583 7.03333 12.5 8 12.5Z"
      fill={fill}
    />
  </svg>
);

export default TranslatorsLogo;
