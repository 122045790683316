import React from 'react';
import style from './button.module.css';

interface ButtonProps {
  content: any;
  isActive?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ content, isActive, onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={`${style.button} ${isActive ? style.active : ''}`}
      >
        {content}
      </button>
    </div>
  );
};

export default Button;
