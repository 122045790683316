import React from 'react';
import { WorkTime } from '../../../../../types';
import { formatTime } from '../helpers';
import style from './WorkTimeList.module.css';

const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];

const WorkTimeList: React.FC<{
  localWorkTime: WorkTime[];
  openActionMenu: (
    index: number,
    buttonRef: HTMLDivElement | HTMLButtonElement,
  ) => void;
  openTimePicker: (
    index: number,
    buttonRef: HTMLDivElement | HTMLButtonElement,
  ) => void;
}> = ({ localWorkTime, openActionMenu, openTimePicker }) => (
  <div className={style.container}>
    {localWorkTime.map((wt, index) => (
      <div key={index} className={style.dayContainer}>
        <div className={style.dayHeader}>
          <span>{daysOfWeek[wt.dayOfTheWeek - 1]}</span>
        </div>
        <div className={style.dayBody}>
          {wt.timeStart && wt.timeEnd ? (
            <div
              className={style.timeDisplay}
              onClick={(e) =>
                openActionMenu(index, e.currentTarget as HTMLDivElement)
              }
            >
              {formatTime(wt.timeStart)}-{formatTime(wt.timeEnd)}
            </div>
          ) : (
            <button
              className={style.addButton}
              onClick={(e) => openTimePicker(index, e.currentTarget)}
            >
              Додати
            </button>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default WorkTimeList;
