import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkScheduleRequest } from '../../../store/actions/translatorsActions';
import { setSchedule } from '../../../store/reducers/scheduleSlice';
import { Translator, WorkSchedule } from '../../../types';
import Calendar from '../Calendar/Calendar';
import Modal from '../schedulemodal/Schedulemodal';
import style from './WorkScheduleForm.module.css';
import CrossIcon from './icons/Сross';
import { fetchTranslatorData } from './workScheduleHelpers';

interface WorkScheduleFormProps {
  translatorId: string;
  translatorName: string;
  onSave: (id: string, schedule: Translator['weeklyWorkSchedule']) => void;
  onClose: () => void;
  onRefresh: () => void;
}

const WorkScheduleForm: React.FC<WorkScheduleFormProps> = ({
  translatorId,
  translatorName,
  onSave,
  onClose,
  onRefresh,
}) => {
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const dispatch = useDispatch();
  const schedule = useSelector((state: any) => state.schedule.schedule);

  useEffect(() => {
    fetchTranslatorData(translatorId, dispatch);
  }, [translatorId, dispatch, updateCounter]);

  const handleAddClick = (
    day: keyof Translator['weeklyWorkSchedule'],
    date: string,
  ) => {
    const startAt = prompt('Введіть час початку роботи (наприклад, 11:00)');
    const finishAt = prompt('Введіть час закінчення роботи (наприклад, 16:00)');
    if (startAt && finishAt) {
      const newSchedule: WorkSchedule = {
        startAt: new Date(`${date}T${startAt}:00`).toISOString(),
        finishAt: new Date(`${date}T${finishAt}:00`).toISOString(),
        id: Date.now(),
      };
      dispatch(
        setSchedule({
          ...schedule,
          [day]: [...(schedule[day] || []), newSchedule],
        }),
      );
    }
  };

  const handleSaveClick = () => {
    if (translatorId) {
      dispatch(updateWorkScheduleRequest({ id: translatorId, schedule }));
      onSave(translatorId, schedule);
      onRefresh();
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      {translatorId && (
        <>
          <div className={style.title}>
            <h4>Графік роботи: {translatorName}</h4>
            <button className={style.closeButton} onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div className={style.calendarContainer}>
            <Calendar
              onAdd={handleAddClick}
              userId={translatorId}
              updateCounter={updateCounter}
              setUpdateCounter={setUpdateCounter}
            />
            <div className={style.saveButtonContainer}>
              <button onClick={handleSaveClick} className={style.saveButton}>
                Зберегти
              </button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default WorkScheduleForm;
