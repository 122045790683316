import { createAction } from '@reduxjs/toolkit';

export const loadCancelTranslationRequest = createAction(
  'LOAD_CANCEL_TRANSLATION_REQUEST',
);

export const loadCancelTranslationSuccess = createAction<{
  items: any[];
  hasNextPage: boolean;
  pageIndex: number;
}>('LOAD_CANCEL_TRANSLATION_SUCCESS');

export const loadCancelTranslationFailure = createAction<string>(
  'LOAD_CANCEL_TRANSLATION_FAILURE',
);
