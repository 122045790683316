import React from 'react';
import LoaderIcon from '../../icons/loaderIcon';
import style from './avatarLoader.module.css';

interface AvatarLoaderProps {
  avatarUrl: string;
  handleAvatarUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AvatarLoader: React.FC<AvatarLoaderProps> = ({
  avatarUrl,
  handleAvatarUpload,
}) => (
  <div
    className={style.avatarLoader}
    onClick={() => document.getElementById('avatarInput')?.click()}
  >
    <div className={style.avatarContainer}>
      <input
        type="file"
        id="avatarInput"
        style={{ display: 'none' }}
        onChange={handleAvatarUpload}
        accept="image/jpeg,image/png"
      />
      {avatarUrl ? (
        <img src={avatarUrl} alt="Avatar" className={style.avatarPreview} />
      ) : (
        <LoaderIcon />
      )}
    </div>
  </div>
);

export default AvatarLoader;
