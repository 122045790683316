import React, { useEffect, useRef, useState } from 'react';
import styles from './DateInput.module.css';

interface DateInputProps {
  label: string;
  date: Date | null;
  active: boolean;
  onClick: () => void;
  onDateChange: (date: Date | null) => void;
  onInputChange: (value: string) => void;
}

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ label, date, active, onClick, onDateChange, onInputChange }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (active && inputRef.current) {
        inputRef.current.focus();
      }
    }, [active]);

    const formatDate = (date: Date | null): string => {
      if (!date) return '';
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange(e.target.value);
    };

    return (
      <div className={styles.container} onClick={onClick}>
        <label className={styles.label}>{label}</label>
        <input
          ref={ref}
          type="text"
          value={formatDate(date)}
          onChange={handleChange}
          className={`${styles.dateInput} ${isFocused ? styles.active : ''}`}
          placeholder="дд.мм.рррр"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
    );
  },
);

DateInput.displayName = 'DateInput';

export default DateInput;
