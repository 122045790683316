import React, { ChangeEvent, useRef } from 'react';
import addPhotos from './icon/add_photo.svg';
import style from './ImageUpload.module.css';

interface ImageUploadProps {
  image: string | null;
  handleImageChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  image,
  handleImageChange,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleImageChange(e);
  };

  return (
    <div className={style.imageUploadContainer} onClick={handleButtonClick}>
      {image ? (
        <img
          key={image}
          src={image}
          alt="Profile"
          className={style.profileImage}
        />
      ) : (
        <div className={style.placeholder}>
          <img src={addPhotos} alt="Upload Icon" className={style.uploadIcon} />
          <button type="button" className={style.uploadButton}>
            Обрати файл
          </button>
        </div>
      )}
      <input
        ref={inputRef}
        id="imageUpload"
        type="file"
        accept="image/*"
        onChange={handleInputChange}
        className={style.imageInput}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default ImageUpload;
