interface PushLogoProps {
  fill: string;
}

const PushLogo = ({ fill }: PushLogoProps) => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.140625 17.1852V15.1852H2.14062V8.18524C2.14062 6.80191 2.55729 5.57274 3.39062 4.49774C4.22396 3.42274 5.30729 2.71858 6.64062 2.38524V1.68524C6.64062 1.26858 6.78646 0.914408 7.07812 0.622742C7.36979 0.331075 7.72396 0.185242 8.14062 0.185242C8.55729 0.185242 8.91146 0.331075 9.20312 0.622742C9.49479 0.914408 9.64062 1.26858 9.64062 1.68524V2.38524C10.974 2.71858 12.0573 3.42274 12.8906 4.49774C13.724 5.57274 14.1406 6.80191 14.1406 8.18524V15.1852H16.1406V17.1852H0.140625ZM8.14062 20.1852C7.59062 20.1852 7.11979 19.9894 6.72813 19.5977C6.33646 19.2061 6.14062 18.7352 6.14062 18.1852H10.1406C10.1406 18.7352 9.94479 19.2061 9.55313 19.5977C9.16146 19.9894 8.69063 20.1852 8.14062 20.1852ZM4.14062 15.1852H12.1406V8.18524C12.1406 7.08524 11.749 6.14358 10.9656 5.36024C10.1823 4.57691 9.24063 4.18524 8.14062 4.18524C7.04063 4.18524 6.09896 4.57691 5.31563 5.36024C4.53229 6.14358 4.14062 7.08524 4.14062 8.18524V15.1852Z"
      fill={fill}
    />
  </svg>
);

export default PushLogo;
