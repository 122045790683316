import { createAction } from '@reduxjs/toolkit';

export const loadHistoryRequest = createAction<{
  pageIndex: number;
  pageSize: number;
  orderDirection: string;
}>('LOAD_HISTORY_REQUEST');

export const loadHistorySuccess = createAction<{
  items: any[];
  hasNextPage: boolean;
  pageIndex: number;
}>('LOAD_HISTORY_SUCCESS');

export const loadHistoryFailure = createAction<string>('LOAD_HISTORY_FAILURE');
