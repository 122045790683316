import React from 'react';
import style from './BackButton.module.css';
import ExitArrow from './icon/arrow.svg';

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={style.backButton}>
      <img src={ExitArrow} alt="Arrow Icon" className={style.icon} />
    </button>
  );
};

export default BackButton;
