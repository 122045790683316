import React from 'react';

const InvitationLogo: React.FC = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.2 12L10 9.9L12.75 12L11.7 8.6L14.5 6.4H11.1L10 3L8.9 6.4H5.5L8.25 8.6L7.2 12ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V10.625C0 10.4417 0.0583333 10.2833 0.175 10.15C0.291667 10.0167 0.441667 9.93333 0.625 9.9C1.025 9.76667 1.35417 9.525 1.6125 9.175C1.87083 8.825 2 8.43333 2 8C2 7.56667 1.87083 7.175 1.6125 6.825C1.35417 6.475 1.025 6.23333 0.625 6.1C0.441667 6.06667 0.291667 5.98333 0.175 5.85C0.0583333 5.71667 0 5.55833 0 5.375V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V5.375C20 5.55833 19.9417 5.71667 19.825 5.85C19.7083 5.98333 19.5583 6.06667 19.375 6.1C18.975 6.23333 18.6458 6.475 18.3875 6.825C18.1292 7.175 18 7.56667 18 8C18 8.43333 18.1292 8.825 18.3875 9.175C18.6458 9.525 18.975 9.76667 19.375 9.9C19.5583 9.93333 19.7083 10.0167 19.825 10.15C19.9417 10.2833 20 10.4417 20 10.625V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM2 14H18V11.45C17.3833 11.0833 16.8958 10.5958 16.5375 9.9875C16.1792 9.37917 16 8.71667 16 8C16 7.28333 16.1792 6.62083 16.5375 6.0125C16.8958 5.40417 17.3833 4.91667 18 4.55V2H2V4.55C2.61667 4.91667 3.10417 5.40417 3.4625 6.0125C3.82083 6.62083 4 7.28333 4 8C4 8.71667 3.82083 9.37917 3.4625 9.9875C3.10417 10.5958 2.61667 11.0833 2 11.45V14Z" />
  </svg>
);

export default InvitationLogo;
