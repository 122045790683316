import React from 'react';
import { getMonthAndYear } from '../helpers';
import LeftArrow from '../icon/LeftAarrow';
import RightArrow from '../icon/RightArrow';
import style from './CalendarHeader.module.css';

interface CalendarHeaderProps {
  currentMonth: Date;
  onPrevMonth: () => void;
  onNextMonth: () => void;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  currentMonth,
  onPrevMonth,
  onNextMonth,
}) => (
  <div className={style.header}>
    <button onClick={onPrevMonth} className={style.arrowLeft}>
      <LeftArrow />
    </button>
    <span>{getMonthAndYear(currentMonth)}</span>
    <button onClick={onNextMonth} className={style.arrowRight}>
      <RightArrow />
    </button>
  </div>
);

export default CalendarHeader;
