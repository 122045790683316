import React, { ChangeEvent } from 'react';
import style from './SearchInput.module.css';
import SearchIcon from './icons/Search';
import CrossIcon from './icons/Сross';

interface Props {
  query: string;
  onChange: (query: string) => void;
  onClear: () => void;
}

const SearchInput: React.FC<Props> = ({ query, onChange, onClear }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onClear();
  };

  return (
    <div className={style.wrap_input}>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Пошук"
        className={style.searchInput}
      />
      {query && (
        <div className={style.crossIcon} onClick={handleClear}>
          <CrossIcon />
        </div>
      )}
      <SearchIcon />
    </div>
  );
};

export default SearchInput;
