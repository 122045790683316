import React, { useState } from 'react';
import { Admin } from '../../types';
import ConfirmModal from '../ConfirmationModal/ConfirmationModal';
import ControlPanel from '../ControlPanel/ControlPanel';
import style from './administrator.module.css';
import AdminList from './AdminList/AdminList';
import { deleteAdmin } from './adminService';
import { useAdmins } from './hooks/useAdmins';
import NewAdminModal from './NewAdminModal/NewAdminModal';

const Administrator: React.FC = () => {
  const {
    admins,
    isLoading,
    orderDirection,
    hasMore,
    fetchAdmins,
    handleSearch,
    handleSort,
    setAdmins,
    setIsLoading,
  } = useAdmins();
  const [isNewAdminModalOpen, setIsNewAdminModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);

  const handleDelete = async (adminId: string) => {
    try {
      await deleteAdmin(adminId);
      setAdmins((prevAdmins) =>
        prevAdmins.filter((admin) => admin.id !== adminId),
      );
    } catch (error) {
      console.error('Error deleting admin:', error);
    }
  };

  const openModal = (admin: Admin) => {
    setSelectedAdmin(admin);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdmin(null);
  };

  const confirmDelete = () => {
    if (selectedAdmin) {
      handleDelete(selectedAdmin.id);
    }
    closeModal();
  };

  const openNewAdminModal = () => {
    setIsNewAdminModalOpen(!isNewAdminModalOpen);
  };

  const handleNewAdminAdded = () => {
    fetchAdmins(true);
  };

  return (
    <div className={style.adminWrap}>
      <ControlPanel
        setIsSearching={setIsLoading}
        onSearch={handleSearch}
        onAddAdmiration={openNewAdminModal}
      />
      <AdminList
        admins={admins}
        orderDirection={orderDirection}
        handleSort={handleSort}
        openModal={openModal}
        isLoading={isLoading}
        hasMore={hasMore}
        fetchAdmins={fetchAdmins}
      />
      {isModalOpen && selectedAdmin && (
        <ConfirmModal
          userName={selectedAdmin.userName}
          avatarUrl={selectedAdmin.avatarUrl}
          onConfirm={confirmDelete}
          onCancel={closeModal}
          type="Administration"
        />
      )}
      {isNewAdminModalOpen && (
        <NewAdminModal
          setIsNewAdminModalOpen={setIsNewAdminModalOpen}
          fetchAdmins={handleNewAdminAdded}
        />
      )}
    </div>
  );
};

export default Administrator;
