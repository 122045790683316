import React from 'react';

interface SortingIconProps {
  fill?: string;
}

const SortingAZ: React.FC<SortingIconProps> = ({ fill = '#152837' }) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.27051 3.54004L9.27051 0.540039L12.2705 3.54004H6.27051ZM9.27051 20.54L6.27051 17.54H12.2705L9.27051 20.54Z"
      fill={fill}
    />
    <path
      d="M6.994 15.009C6.87267 14.6537 6.747 14.3027 6.617 13.956C6.487 13.6094 6.36133 13.254 6.24 12.89H2.444C2.32267 13.254 2.197 13.6137 2.067 13.969C1.94567 14.3157 1.82433 14.6624 1.703 15.009H0C0.338 14.047 0.658667 13.1587 0.962 12.344C1.26533 11.5294 1.56 10.758 1.846 10.03C2.14067 9.30203 2.431 8.60869 2.717 7.95002C3.003 7.29136 3.29767 6.64136 3.601 6.00002H5.148C5.45133 6.64136 5.746 7.29136 6.032 7.95002C6.318 8.60869 6.604 9.30203 6.89 10.03C7.18467 10.758 7.48367 11.5294 7.787 12.344C8.099 13.1587 8.424 14.047 8.762 15.009H6.994ZM4.342 7.74202C4.14267 8.20136 3.913 8.76036 3.653 9.41903C3.40167 10.0777 3.13733 10.7927 2.86 11.564H5.824C5.54667 10.7927 5.278 10.0734 5.018 9.40602C4.758 8.73869 4.53267 8.18402 4.342 7.74202Z"
      fill={fill}
    />
    <path
      d="M15.017 5.90002C15.3723 5.90002 15.7753 5.91736 16.226 5.95202C16.6767 5.97802 17.1013 6.03436 17.5 6.12102V15H15.862V11.62H14.913C14.8003 11.62 14.6833 11.62 14.562 11.62C14.4407 11.6114 14.328 11.5984 14.224 11.581C14.0507 11.789 13.8773 12.0274 13.704 12.296C13.5307 12.556 13.3573 12.8377 13.184 13.141C13.0193 13.4357 12.8503 13.7434 12.677 14.064C12.5123 14.376 12.352 14.688 12.196 15H10.454C10.61 14.662 10.779 14.3197 10.961 13.973C11.143 13.6264 11.325 13.2927 11.507 12.972C11.6977 12.6427 11.884 12.3394 12.066 12.062C12.2567 11.776 12.43 11.5334 12.586 11.334C11.9967 11.1174 11.5633 10.7967 11.286 10.372C11.0087 9.94736 10.87 9.42302 10.87 8.79902C10.87 8.31369 10.9653 7.88902 11.156 7.52502C11.3553 7.16102 11.6327 6.86202 11.988 6.62802C12.352 6.38536 12.7897 6.20336 13.301 6.08202C13.8123 5.96069 14.3843 5.90002 15.017 5.90002ZM14.9 7.31702C14.5707 7.31702 14.263 7.34302 13.977 7.39502C13.6997 7.43836 13.457 7.51636 13.249 7.62902C13.041 7.74169 12.8763 7.89336 12.755 8.08402C12.6423 8.27469 12.586 8.50869 12.586 8.78603C12.586 9.08069 12.6423 9.32336 12.755 9.51402C12.8763 9.70469 13.0453 9.85636 13.262 9.96902C13.4873 10.0817 13.756 10.1597 14.068 10.203C14.3887 10.2464 14.7483 10.268 15.147 10.268H15.862V7.35602C15.5587 7.33002 15.238 7.31702 14.9 7.31702Z"
      fill={fill}
    />
  </svg>
);

export default SortingAZ;
