import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { iconsConfig } from '../../pages/CustomersPage/helpers';
import Logo from '../../pages/CustomersPage/icons/Logo.svg';
import LogoutLogo from '../../pages/CustomersPage/icons/Logout';
import { performLogout } from '../../store/actions/authActions';
import { AppDispatch, RootState } from '../../store/store';
import NavigationButton from './NavigationButton/NavigationButton';
import style from './Sidebar.module.css';
import Preferences from '../preferences/Preferences';

interface SidebarProps {
  setActiveComponent: (component: keyof typeof iconsConfig) => void;
  activeComponent: keyof typeof iconsConfig;
}

const Sidebar: React.FC<SidebarProps> = ({
  setActiveComponent,
  activeComponent,
}) => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false); // Стан для модального вікна
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  const handleLogout = () => {
    dispatch(performLogout());
  };

  const handleNavigationClick = (component: keyof typeof iconsConfig) => {
    if (component === 'settings') {
      setIsSettingsModalOpen(true); // Відкрити модальне вікно
    } else {
      setActiveComponent(component);
      navigate(`/${component}`);
    }
  };

  const isSuperAdmin = user?.roles.some((role) => role.name === 'SuperAdmin');

  return (
    <div className={style.nav_panel}>
      <div>
        <div className={style.nav_logo}>
          <img src={Logo} alt="WordCraft" />
        </div>
        <nav className={style.top_tabs}>
          {Object.keys(iconsConfig).map((key) => {
            if (key === 'translators') {
              return (
                <React.Fragment key={key}>
                  <NavigationButton
                    label={iconsConfig[key as keyof typeof iconsConfig].label}
                    icon={iconsConfig[key as keyof typeof iconsConfig].icon}
                    active={activeComponent === key}
                    onClick={() =>
                      handleNavigationClick(key as keyof typeof iconsConfig)
                    }
                  />
                  {isSuperAdmin && (
                    <NavigationButton
                      key="administrator"
                      label={iconsConfig.administrator.label}
                      icon={iconsConfig.administrator.icon}
                      active={activeComponent === 'administrator'}
                      onClick={() =>
                        handleNavigationClick(
                          'administrator' as keyof typeof iconsConfig,
                        )
                      }
                    />
                  )}
                </React.Fragment>
              );
            } else if (
              key !== 'push_message' &&
              key !== 'settings' &&
              key !== 'administrator'
            ) {
              return (
                <NavigationButton
                  key={key}
                  label={iconsConfig[key as keyof typeof iconsConfig].label}
                  icon={iconsConfig[key as keyof typeof iconsConfig].icon}
                  active={activeComponent === key}
                  onClick={() =>
                    handleNavigationClick(key as keyof typeof iconsConfig)
                  }
                />
              );
            }
            return null;
          })}
        </nav>
      </div>
      <div>
        <nav className={style.bottom_tabs}>
          {Object.keys(iconsConfig)
            .filter((key) => key === 'push_message' || key === 'settings')
            .map((key) => (
              <NavigationButton
                key={key}
                label={iconsConfig[key as keyof typeof iconsConfig].label}
                icon={iconsConfig[key as keyof typeof iconsConfig].icon}
                active={activeComponent === key}
                onClick={() =>
                  handleNavigationClick(key as keyof typeof iconsConfig)
                }
              />
            ))}
          <button className={style.navButton} onClick={handleLogout}>
            <LogoutLogo fill="#596987" />
            Вийти
          </button>
        </nav>
      </div>

      {/* Модальне вікно для налаштувань */}
      {isSettingsModalOpen && (
        <Preferences onClose={() => setIsSettingsModalOpen(false)} />
      )}
    </div>
  );
};

export default Sidebar;
