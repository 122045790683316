import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchClients } from '../../../store/thunks/clientsThunk';
import { Client } from '../../../types';

const useCustomerLogic = () => {
  const { jwtToken } = useSelector((state: RootState) => state.auth);
  const searchResults = useSelector(
    (state: RootState) => state.clients.searchResults,
  );
  const loading = useSelector((state: RootState) => state.clients.loading);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [sortingLogo, setSortingLogo] = useState<boolean>(false);
  const [isRotated, setIsRotated] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [allClients, setAllClients] = useState<Client[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const currentPageIndex = useRef<number>(1);
  const observerTarget = useRef<HTMLDivElement | null>(null);
  const initialLoad = useRef<boolean>(true);
  const isInitialRequestDone = useRef<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const loadClients = useCallback(
    async (page: number, sortDirection: string) => {
      if (loading || isLoadingMore || isSearching) return;

      setIsLoadingMore(true);
      try {
        const result = await dispatch(fetchClients(page, 12, sortDirection));
        const clients: Client[] = result.clients;

        if (Array.isArray(clients) && clients.length > 0) {
          setAllClients((prevClients) => {
            if (!Array.isArray(prevClients)) {
              console.error('prevClients не є масивом:', prevClients);
              return clients;
            }
            return page === 1 ? clients : [...prevClients, ...clients];
          });
          setHasNextPage(clients.length === 12);
          currentPageIndex.current = page;
        } else {
          setHasNextPage(false);
        }
      } catch (error) {
        console.error('Failed to load clients:', error);
      } finally {
        setIsLoadingMore(false);
        if (page === 1) {
          initialLoad.current = false;
          isInitialRequestDone.current = true;
        }
      }
    },
    [dispatch, loading, isLoadingMore, isSearching],
  );

  useEffect(() => {
    if (!jwtToken) {
      navigate('/');
    } else if (!isInitialRequestDone.current) {
      isInitialRequestDone.current = true;
      loadClients(1, sortingLogo ? 'desc' : 'asc');
    }
  }, [jwtToken, navigate, sortingLogo, loadClients]);

  const loadMoreClients = useCallback(() => {
    if (
      hasNextPage &&
      !loading &&
      !isSearching &&
      !isLoadingMore &&
      isInitialRequestDone.current
    ) {
      const nextPage = currentPageIndex.current + 1;
      loadClients(nextPage, sortingLogo ? 'desc' : 'asc');
    }
  }, [
    hasNextPage,
    loading,
    isSearching,
    isLoadingMore,
    sortingLogo,
    loadClients,
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          loadMoreClients();
        }
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
      },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [hasNextPage, loadMoreClients]);

  const toggleSortingDirection = () => {
    setSortingLogo((prev) => !prev);
    currentPageIndex.current = 1;
    setAllClients([]);
    setIsLoadingMore(false);
    loadClients(1, !sortingLogo ? 'desc' : 'asc');
  };

  const handleSortArrowClick = () => {
    setIsRotated((prev) => !prev);
  };
  const debouncedSearch = useCallback(
    (query: string) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (searchQuery !== query) {
        debounceTimeout.current = setTimeout(() => {
          if (query.length >= 2) {
            setIsSearching(true);
            dispatch(fetchClients(1, 12, sortingLogo ? 'desc' : 'asc', query)) // Виклик fetchClients з параметром пошуку
              .then((results: any) => {
                setIsSearching(false);
                const clients: Client[] = results.clients;

                if (!clients || !Array.isArray(clients)) {
                  return;
                }

                setAllClients(clients);
                setHasNextPage(clients.length === 12);
                currentPageIndex.current = 1;
                isInitialRequestDone.current = true;
              })
              .catch((error) => {
                console.error('Search failed:', error);
                setIsSearching(false);
              });
          }
        }, 2000);
      }
    },
    [dispatch, searchQuery, sortingLogo],
  );

  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery);
    }
  }, [searchQuery, debouncedSearch]);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  return {
    sortingLogo,
    isRotated,
    allClients,
    observerTarget,
    searchResults,
    isSearching,
    handleSortArrowClick,
    toggleSortingDirection,
    handleSearchChange,
    setIsSearching,
  };
};

export default useCustomerLogic;
