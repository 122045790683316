const СrossIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.4 19.3848L5 17.9848L10.6 12.3848L5 6.78477L6.4 5.38477L12 10.9848L17.6 5.38477L19 6.78477L13.4 12.3848L19 17.9848L17.6 19.3848L12 13.7848L6.4 19.3848Z" />
  </svg>
);

export default СrossIcon;
