import dayjs from 'dayjs';
import style from './CancelTranslationListItem.module.css';
import ProfileIcon from '../icons/profile';
import { CancelTranslationItem } from '../../helpers';

interface CancelTranslationListItemProps {
  item: CancelTranslationItem;
  dataLength: number;
  index: number;
}

export const CancelTranslationListItem = ({
  item,
  dataLength,
  index,
}: CancelTranslationListItemProps) => {
  return (
    <div
      className={`${style.cancelTranslationItem} ${
        index === dataLength - 1 ? style.lastCancelTranslationItem : ''
      }`}
    >
      <p>{index + 1}</p>
      <div className={style.usernameWrapper}>
        {item.translator.avatarUrl ? (
          <img
            src={item.translator.avatarUrl}
            alt={`${item.translator.userName}'s avatar`}
            className={style.avatar}
          />
        ) : (
          <div className={style.avatarPlaceholder}>
            <ProfileIcon />
          </div>
        )}
        <p>{item.translator.userName}</p>
      </div>
      <div>
        <p>{dayjs(item.updatedAt).format('HH:mm')}</p>
      </div>
      <div>
        <p>{dayjs(item.updatedAt).format('DD.MM.YYYY')}</p>
      </div>
      <div>
        <div>
          <p className={style.comment}>{item.comment}</p>
        </div>
      </div>
    </div>
  );
};
