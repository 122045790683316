import React from 'react';
import { FieldError } from 'react-hook-form';
import style from './InputField.module.css';

interface InputFieldProps {
  label: string;
  type?: string;
  required?: boolean;
  register: any;
  name: string;
  validationRules?: object;
  error?: FieldError;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type = 'text',
  required = false,
  register,
  name,
  validationRules = {},
  error,
}) => {
  return (
    <div className={style.wrapInput}>
      <label className={style.label}>{label}</label>
      <input
        type={type}
        className={style.adminInput}
        {...register(name, { required, ...validationRules })}
      />
      {error && <p className={style.error}>{error.message}</p>}
    </div>
  );
};

export default InputField;
