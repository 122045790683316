const AscIcon = () => (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.49996 3.16602L7.99996 0.666016L10.5 3.16602H5.49996ZM7.99996 17.3327L5.49996 14.8327H10.5L7.99996 17.3327Z" />
    <path d="M6.10287 12.7235C6.00176 12.4274 5.89704 12.1349 5.7887 11.846C5.68037 11.5571 5.57565 11.261 5.47454 10.9577H2.3112C2.21009 11.261 2.10537 11.5607 1.99704 11.8568C1.89592 12.1457 1.79481 12.4346 1.6937 12.7235H0.274536C0.556203 11.9218 0.823425 11.1816 1.0762 10.5027C1.32898 9.82378 1.57454 9.181 1.81287 8.57434C2.05842 7.96767 2.30037 7.38989 2.5387 6.841C2.77704 6.29211 3.02259 5.75045 3.27537 5.216H4.56454C4.81731 5.75045 5.06287 6.29211 5.3012 6.841C5.53954 7.38989 5.77787 7.96767 6.0162 8.57434C6.26176 9.181 6.51092 9.82378 6.7637 10.5027C7.0237 11.1816 7.29454 11.9218 7.5762 12.7235H6.10287ZM3.89287 6.66767C3.72676 7.05045 3.53537 7.51628 3.3187 8.06517C3.10926 8.61406 2.88898 9.20989 2.65787 9.85267H5.12787C4.89676 9.20989 4.67287 8.61045 4.4562 8.05434C4.23954 7.49823 4.05176 7.036 3.89287 6.66767Z" />
    <path d="M12.7887 5.13267C13.0848 5.13267 13.4206 5.14711 13.7962 5.176C14.1718 5.19767 14.5256 5.24461 14.8579 5.31684V12.716H13.4929V9.89934H12.702C12.6081 9.89934 12.5106 9.89934 12.4095 9.89934C12.3084 9.89212 12.2145 9.88128 12.1279 9.86684C11.9834 10.0402 11.839 10.2388 11.6945 10.4627C11.5501 10.6793 11.4056 10.9141 11.2612 11.1668C11.124 11.4124 10.9831 11.6688 10.8387 11.936C10.7015 12.196 10.5679 12.456 10.4379 12.716H8.9862C9.1162 12.4343 9.25704 12.1491 9.4087 11.8602C9.56037 11.5713 9.71204 11.2932 9.8637 11.026C10.0226 10.7516 10.1779 10.4988 10.3295 10.2677C10.4884 10.0293 10.6329 9.82712 10.7629 9.661C10.2718 9.48045 9.91065 9.21323 9.67953 8.85934C9.44842 8.50545 9.33287 8.0685 9.33287 7.5485C9.33287 7.14406 9.41231 6.79017 9.5712 6.48684C9.73731 6.1835 9.96842 5.93434 10.2645 5.73934C10.5679 5.53711 10.9326 5.38545 11.3587 5.28434C11.7848 5.18323 12.2615 5.13267 12.7887 5.13267ZM12.6912 6.3135C12.4168 6.3135 12.1604 6.33517 11.922 6.3785C11.6909 6.41461 11.4887 6.47961 11.3154 6.5735C11.142 6.66739 11.0048 6.79378 10.9037 6.95267C10.8098 7.11156 10.7629 7.30656 10.7629 7.53767C10.7629 7.78323 10.8098 7.98545 10.9037 8.14434C11.0048 8.30323 11.1456 8.42962 11.3262 8.5235C11.514 8.61739 11.7379 8.68239 11.9979 8.7185C12.2651 8.75461 12.5648 8.77267 12.897 8.77267H13.4929V6.346C13.2401 6.32434 12.9729 6.3135 12.6912 6.3135Z" />
  </svg>
);

export default AscIcon;
