import React from 'react';
import style from './PreferencesHeader.module.css';
import CrossIcon from './icon/Сross';

interface PreferencesHeaderProps {
  onClose: () => void;
}

const PreferencesHeader: React.FC<PreferencesHeaderProps> = ({ onClose }) => (
  <div className={style.titlePreference}>
    <h5>Налаштування</h5>
    <button className={style.closeButton} onClick={onClose}>
      <CrossIcon />
    </button>
  </div>
);

export default PreferencesHeader;
