import React, { forwardRef } from 'react';
import style from './ClientMenu.module.css';
import DetailsIcon from './icons/Details';
import InvitationIcon from './icons/Invitation';
import Schedule from './icons/Schedule';
import TrashIcon from './icons/TrashIcon';

interface ClientMenuProps {
  onWorkScheduleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDetailsClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSendInvitationClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDeleteClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
}

const ClientMenu = forwardRef<HTMLDivElement, ClientMenuProps>(
  (
    {
      onWorkScheduleClick,
      onDetailsClick,
      onSendInvitationClick,
      onDeleteClick,
      style: customStyle,
    },
    ref,
  ) => {
    return (
      <div ref={ref} className={style.menu} style={customStyle}>
        <button className={style.menu_item} onClick={onWorkScheduleClick}>
          <Schedule />
          Графік роботи
        </button>
        <button className={style.menu_item} onClick={onDetailsClick}>
          <DetailsIcon />
          Деталі
        </button>
        <button
          className={`${style.menu_item} ${style.menu_invitation}`}
          onClick={onSendInvitationClick}
        >
          <InvitationIcon />
          Надіслати запрошення
        </button>
        <button
          className={`${style.menu_item} ${style.deleteItem}`}
          onClick={onDeleteClick}
        >
          <TrashIcon />
          Видалити
        </button>
      </div>
    );
  },
);

export default ClientMenu;
