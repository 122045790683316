import React, { forwardRef } from 'react';
import style from './Input.module.css';

interface InputProps {
  type: string;
  name: string;
  value?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  readOnly?: boolean;
  label: string;
  error?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const inputClassName = props.error ? `${style.inputError}` : `${style.input}`;

  return (
    <div className={style.wrapInput}>
      <label className={style.label}>{props.label}</label>
      <input
        ref={ref}
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        required={props.required}
        readOnly={props.readOnly}
        className={inputClassName}
      />
      {props.error && <span className={style.error}>{props.error}</span>}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
