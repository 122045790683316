import CalendarIcon from '../icons/Calendar';
import style from './DateInputs.module.css';

interface DateInputsProps {
  value: {
    startDate: string;
    endDate: string;
  };
}

const DateInputs = ({ value }: DateInputsProps) => {
  return (
    <div className={style.inputsContainer}>
      <div className={style.inputContainer}>
        <label>Початок</label>
        <input type="text" value={value.startDate} />
        <div className={style.calendarIcon}>
          <CalendarIcon />
        </div>
      </div>
      <div className={style.inputContainer}>
        <label>Кінець</label>
        <input type="text" value={value.endDate} />
        <div className={style.calendarIcon}>
          <CalendarIcon />
        </div>
      </div>
    </div>
  );
};

export default DateInputs;
