import React, { useEffect, useRef, useState } from 'react';
import style from './TimePickerModal.module.css';

interface TimePickerModalProps {
  onSave: (timeStart: string, timeEnd: string) => void;
  onClose: () => void;
  initialTimeStart?: string;
  initialTimeEnd?: string;
  buttonPosition: DOMRect;
}

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 10) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      times.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return times;
};

const TimePickerModal: React.FC<TimePickerModalProps> = ({
  onSave,
  onClose,
  initialTimeStart = '',
  initialTimeEnd = '',
  buttonPosition,
}) => {
  const [timeStart, setTimeStart] = useState(initialTimeStart);
  const [timeEnd, setTimeEnd] = useState(initialTimeEnd);
  const [isTimeStartOpen, setIsTimeStartOpen] = useState(false);
  const [isTimeEndOpen, setIsTimeEndOpen] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const timeStartRef = useRef<HTMLDivElement>(null);
  const timeEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const timeOptions = generateTimeOptions();

  const handleTimeSelect = (type: 'start' | 'end', value: string) => {
    if (type === 'start') {
      setTimeStart(value);
      setIsTimeStartOpen(false);
    } else {
      setTimeEnd(value);
      setIsTimeEndOpen(false);
    }
  };

  const handleSave = () => {
    onSave(timeStart, timeEnd);
  };

  const scrollToInitialTime = (
    ref: React.RefObject<HTMLDivElement>,
    time: string,
  ) => {
    if (ref.current) {
      const optionElement = ref.current.querySelector(`[data-time="${time}"]`);
      if (optionElement) {
        optionElement.scrollIntoView({ block: 'nearest' });
      }
    }
  };

  useEffect(() => {
    if (isTimeStartOpen) {
      scrollToInitialTime(timeStartRef, timeStart);
    }
  }, [isTimeStartOpen, timeStart]);

  useEffect(() => {
    if (isTimeEndOpen) {
      scrollToInitialTime(timeEndRef, timeEnd);
    }
  }, [isTimeEndOpen, timeEnd]);

  return (
    <div className={style.timePickerOverlay}>
      <div
        className={style.timePickerContent}
        ref={modalRef}
        style={{
          top: buttonPosition.bottom + window.scrollY + 15,
          left: buttonPosition.left + window.scrollX - 90,
        }}
      >
        <div className={style.timePickerBody}>
          <div className={style.timeInputContainer}>
            <label>Час початку</label>
            <div
              className={`${style.customTimePicker}  ${isTimeStartOpen ? style.open : ''}`}
              onClick={() => setIsTimeStartOpen(!isTimeStartOpen)}
            >
              <div className={style.selectedTime}>{timeStart}</div>
              {isTimeStartOpen && (
                <div className={style.timeOptions} ref={timeStartRef}>
                  {timeOptions.map((time) => (
                    <div
                      key={time}
                      data-time={time}
                      className={style.timeOption}
                      onClick={() => handleTimeSelect('start', time)}
                    >
                      {time}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={style.timeInputContainer}>
            <label>Кінець</label>
            <div
              className={`${style.customTimePicker}  ${isTimeEndOpen ? style.open : ''}`}
              onClick={() => setIsTimeEndOpen(!isTimeEndOpen)}
            >
              <div className={style.selectedTime}>{timeEnd}</div>
              {isTimeEndOpen && (
                <div className={style.timeOptions} ref={timeEndRef}>
                  {timeOptions.map((time) => (
                    <div
                      key={time}
                      data-time={time}
                      className={style.timeOption}
                      onClick={() => handleTimeSelect('end', time)}
                    >
                      {time}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={style.timePickerFooter}>
          <button onClick={handleSave} className={style.saveButton}>
            Зберегти
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimePickerModal;
