interface CancelLogoProps {
  fill: string;
}

const CancelLogo = ({ fill }: CancelLogoProps) => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4 15.5L9 14.1L11.6 11.5L9 8.9L10.4 7.5L13 10.1L15.6 7.5L17 8.9L14.4 11.5L17 14.1L15.6 15.5L13 12.9L10.4 15.5ZM2 22.5C1.45 22.5 0.979167 22.3042 0.5875 21.9125C0.195833 21.5208 0 21.05 0 20.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H12C12.55 0.5 13.0208 0.695833 13.4125 1.0875C13.8042 1.47917 14 1.95 14 2.5V6.5H12V5.5H2V17.5H12V16.5H14V20.5C14 21.05 13.8042 21.5208 13.4125 21.9125C13.0208 22.3042 12.55 22.5 12 22.5H2ZM2 19.5V20.5H12V19.5H2ZM2 3.5H12V2.5H2V3.5Z"
      fill={fill}
    />
  </svg>
);

export default CancelLogo;
