import {
  FC,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import AddTagInput from './AddTagInput/AddTagInput';
import SearchInput from './SearchInput/SearchInput';
import Tag from './Tag/Tag';
import style from './TagSelector.module.css';
import { addTag, deleteTag, fetchTags } from './tagService';

interface TagType {
  id: number;
  name: string;
}

interface TagSelectorProps {
  selectedTags: TagType[];
  onChange: (tags: TagType[]) => void;
}

const TagSelector: FC<TagSelectorProps> = ({ selectedTags, onChange }) => {
  const [tags, setTags] = useState<TagType[]>([]);
  const [filteredTags, setFilteredTags] = useState<TagType[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleFetchTags = async () => {
    try {
      const tags = await fetchTags();
      setTags(tags);
      setFilteredTags(tags);
      setIsFetched(true);
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  const handleTagClick = (tag: TagType) => {
    const isSelected = selectedTags.some(
      (selectedTag) => selectedTag.id === tag.id,
    );
    const newSelectedTags = isSelected
      ? selectedTags.filter((selectedTag) => selectedTag.id !== tag.id)
      : [...selectedTags, tag];

    onChange(newSelectedTags);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen((prevState) => {
      if (!prevState && !isFetched) {
        handleFetchTags();
      }
      return !prevState;
    });
  };

  const handleDeleteTag = async (
    tagId: number,
    event: ReactMouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      await deleteTag(tagId);
      await handleFetchTags();
      onChange(selectedTags.filter((tag) => tag.id !== tagId));
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
      setIsAdding(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = tags.filter((tag) =>
        tag.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredTags(filtered);
    } else {
      setFilteredTags(tags);
    }
  }, [searchTerm, tags]);

  const handleAddTag = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newTag.trim()) {
      event.preventDefault();
      try {
        const newTagData = await addTag(newTag);
        setTags((prevTags) => [...prevTags, newTagData]);
        setFilteredTags((prevFilteredTags) => [
          ...prevFilteredTags,
          newTagData,
        ]);
        setNewTag('');
        setIsAdding(false);
      } catch (error) {
        console.error('Error adding tag:', error);
      }
    }
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !searchTerm.trim()) {
      event.preventDefault();
      event.stopPropagation(); // Запобігаємо розповсюдженню події, якщо поле пошуку порожнє
    }
  };

  const displayValue =
    selectedTags.length === 0
      ? ''
      : selectedTags.length <= 2
        ? selectedTags.map((tag) => tag.name).join(', ')
        : `${selectedTags
            .slice(0, 2)
            .map((tag) => tag.name)
            .join(', ')} ...`;

  return (
    <div className={style.tagSelector} ref={dropdownRef}>
      <div
        className={dropdownOpen ? style.inputOpen : style.inputClose}
        onClick={handleDropdownToggle}
      >
        <label>ТЕГ</label>
        {displayValue}
      </div>
      {dropdownOpen && (
        <div className={style.dropdown}>
          <SearchInput
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            handleSearchKeyDown={handleSearchKeyDown} // Передаємо новий пропс
          />
          <div className={style.tagList}>
            {filteredTags.map((tag) => (
              <Tag
                key={tag.id}
                tag={tag}
                isSelected={selectedTags.some(
                  (selectedTag) => selectedTag.id === tag.id,
                )}
                onTagClick={handleTagClick}
                onDeleteTag={handleDeleteTag}
              />
            ))}
            {!isAdding && (
              <button
                className={style.addButton}
                onClick={() => setIsAdding(true)}
              >
                Додати новий тег
              </button>
            )}
            {isAdding && (
              <AddTagInput
                newTag={newTag}
                setNewTag={setNewTag}
                handleAddTag={handleAddTag}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagSelector;
