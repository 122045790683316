import React, { useState, forwardRef } from 'react';
import style from './InputField.module.css';

interface InputFieldProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  name: string;
  type?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  className?: string; // Додаємо для інпуту
  containerClassName?: string; // Додаємо для контейнера
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      value = '',
      onChange,
      onBlur,
      name,
      type = 'text',
      error,
      disabled = false,
      placeholder,
      onFocus,
      maxLength,
      className,
      containerClassName,
    },
    ref,
  ) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <div
        className={`${style.inputContainer} ${isActive ? style.active : ''} ${containerClassName || ''}`}
        onClick={() => setIsActive(true)}
      >
        <label className={style.inputLabel} htmlFor={name}>
          {label}
        </label>
        <input
          id={name}
          name={name}
          ref={ref}
          className={`${style.inputField} ${className || ''}`}
          value={value}
          onChange={onChange}
          onBlur={() => {
            setIsActive(false);
            onBlur();
          }}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFocus}
          maxLength={maxLength}
        />
      </div>
    );
  },
);

export default InputField;
