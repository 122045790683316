import React, { useEffect, useRef } from 'react';
import ProfileIcon from '../icons/profile';
import style from './ConfirmDeleteModal.module.css';

interface ConfirmDeleteModalProps {
  userName: string;
  avatarUrl: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  userName,
  avatarUrl,
  onConfirm,
  onCancel,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel]);

  return (
    <div className={style.modalOverlay}>
      <div ref={modalRef} className={style.modal}>
        <div className={style.modalContent}>
          <div>
            <h5 className={style.title}>Видалення перекладача</h5>
          </div>
          <h5 className={style.message}>
            Ви впевнені, що хочете видалити перекладача?
          </h5>
          <div className={style.detail}>
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={`${userName}'s avatar`}
                className={style.avatar}
              />
            ) : (
              <div className={style.avatarPlaceholder}>
                <ProfileIcon />
              </div>
            )}
            <p>{userName}</p>
          </div>
          <div className={style.modalActions}>
            <button className={style.confirmButton} onClick={onConfirm}>
              Так
            </button>
            <button className={style.cancelButton} onClick={onCancel}>
              Ні
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
