import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { fetchTranslators } from '../../../store/thunks/translatorsThunk';
import { Translator } from '../../../types';
import ClientMenu from '../ClientMenu/ClientMenu';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import TranslatorInfoModal from '../TranslatorInfoModal/TranslatorInfoModal';
import WorkSchedule from '../WorkSchedule/WorkSchedule';
import WorkScheduleForm from '../WorkScheduleForm/WorkScheduleForm';
import { useMenuContext } from '../contexts/MenuContext';
import ProfileIcon from '../icons/profile';
import style from './ClientItem.module.css';
import { adjustMenuPosition, handleDeleteClick } from './helpers';

interface ClientItemProps {
  translator: Translator;
  index: number;
  onDelete: (translatorId: string) => void;
  onRefresh: () => void;
}

const ClientItem: React.FC<ClientItemProps> = ({
  translator,
  index,
  onDelete,
  onRefresh,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    menuOpen,
    menuPosition,
    toggleMenu,
    onDetailsClick,
    onSendInvitationClick,
    setMenuOpen,
  } = useMenuContext();

  const menuRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [adjustedMenuPosition, setAdjustedMenuPosition] =
    useState(menuPosition);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, setMenuOpen]);

  useEffect(() => {
    if (menuOpen === translator.id && menuRef.current) {
      setAdjustedMenuPosition(adjustMenuPosition(menuRef, menuPosition));
    }
  }, [menuOpen, menuPosition, translator.id]);

  const handleWorkScheduleClick = () => {
    setIsInfoModalOpen(false);
    setMenuOpen(null);
    setTimeout(() => setIsModalOpen(true));
  };

  const handleInfoClick = () => {
    setIsInfoModalOpen(true);
    setMenuOpen(null);
  };

  const handleSaveSchedule = (
    id: string,
    schedule: Translator['weeklyWorkSchedule'],
  ) => setIsModalOpen(false);

  const handleDeleteClickWithConfirmation = () => {
    setMenuOpen(null);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    await handleDeleteClick(translator.id, onDelete);
    setIsDeleteModalOpen(false);
    dispatch(fetchTranslators(0, 18, 'ASC'));
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className={style.client}>
      <div>{index + 1}</div>
      {translator.avatarUrl ? (
        <img
          src={translator.avatarUrl}
          alt={`${translator.userName}'s avatar`}
          className={style.avatar}
        />
      ) : (
        <div className={style.avatarPlaceholder}>
          <ProfileIcon />
        </div>
      )}
      <div className={style.clientInfo}>
        <div className={style.clientName}>{translator.userName}</div>
      </div>
      <div className={style.clientPhone}>{translator.phone}</div>
      <div className={style.clientDays}>
        <WorkSchedule schedule={translator.weeklyWorkSchedule} />
      </div>
      <div className={style.clientActions}>
        <button
          className={style.actionButton}
          onClick={(event) => toggleMenu(translator.id, event)}
        >
          ...
        </button>
        {menuOpen === translator.id &&
          ReactDOM.createPortal(
            <div
              ref={menuRef}
              className={style.menuContainer}
              style={{
                position: 'absolute',
                top: adjustedMenuPosition.top,
                left: adjustedMenuPosition.left,
              }}
            >
              <ClientMenu
                onWorkScheduleClick={handleWorkScheduleClick}
                onDetailsClick={(e) => {
                  onDetailsClick(translator.id, e);
                  handleInfoClick();
                }}
                onSendInvitationClick={(e) =>
                  onSendInvitationClick(translator.id, e)
                }
                onDeleteClick={handleDeleteClickWithConfirmation}
              />
            </div>,
            document.body,
          )}
      </div>
      {isModalOpen && (
        <div className={style.modalOverlay}>
          <div className={style.modal}>
            <button
              className={style.closeButton}
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
            <WorkScheduleForm
              translatorId={translator.id}
              translatorName={translator.userName}
              onSave={handleSaveSchedule}
              onClose={() => setIsModalOpen(false)}
              onRefresh={onRefresh}
            />
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          userName={translator.userName}
          avatarUrl={translator.avatarUrl}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
      {isInfoModalOpen && (
        <TranslatorInfoModal
          onClose={() => setIsInfoModalOpen(false)}
          translator={translator}
          onRefresh={onRefresh}
          onOpenWorkSchedule={handleWorkScheduleClick}
        />
      )}
    </div>
  );
};

export default ClientItem;
