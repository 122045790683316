import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteScheduleApi, updateScheduleApi } from '../scheduleApi';

export const updateSchedule = createAsyncThunk(
  'schedule/updateSchedule',
  async ({
    startAt,
    finishAt,
    userId,
    date,
    workId,
  }: {
    startAt: string;
    finishAt: string;
    userId: string;
    date: string;
    workId?: number;
  }) => {
    try {
      const newWork = await updateScheduleApi(startAt, finishAt, workId);
      return {
        dayKey: new Date(date)
          .toLocaleDateString('en-US', { weekday: 'short' })
          .toLowerCase(),
        newWork,
      };
    } catch (error: any) {
      throw new Error(`Failed to update schedule: ${error.message || error}`);
    }
  },
);

export const deleteSchedule = createAsyncThunk(
  'schedule/deleteSchedule',
  async ({ scheduleId, userId }: { scheduleId: number; userId: string }) => {
    try {
      const data = await deleteScheduleApi(scheduleId, userId);
      return {
        dayKey: new Date(data.date)
          .toLocaleDateString('en-US', { weekday: 'short' })
          .toLowerCase(),
        scheduleId,
      };
    } catch (error: any) {
      throw new Error(`Failed to delete schedule: ${error.message || error}`);
    }
  },
);
