import axios from 'axios';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import { Company, WorkTime } from '../../../types';
import DefaultAvatar from '../icons/defaultAvatar';
import style from './CompanyForm.module.css';
import DateModal from './DateModal/DateModal';
import FieldController from './FieldController/FieldController';
import FormHeader from './FormHeader/FormHeader';
import FormSection from './FormSection/FormSection';
import { useCompanyForm } from './hooks/useCompanyForm';
import EmployeesIcon from './icon/EmployeesIcon';
import Input from './input/input';
import TagSelector from './TagSelector/TagSelector';
import WorkTimeModal from './WorkTimeModal/WorkTimeModal';
import axiosInstance from '../../../utils/axiosInstance';

interface CompanyFormProps {
  companyData?: Company;
  onClose: () => void;
  onSave: (company: Company) => void;
  isEdit?: boolean;
  onOpenEmployeesModal?: (id: number) => void;
  formDataArray?: any[];
  newPlan?: string | number;
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  companyData,
  onClose,
  onSave,
  isEdit = false,
  onOpenEmployeesModal,
  formDataArray,
  newPlan,
}) => {
  const {
    control,
    handleSubmit,
    errors,
    isValid,
    openDateModal,
    closeDateModal,
    isDateModalOpen,
    handleDateSelect,
    openWorkTimeModal,
    closeWorkTimeModal,
    isWorkTimeModalOpen,
    handleWorkTimeSave,
    onSubmit,
    watch,
    selectedTags,
    handleTagChange,
  } = useCompanyForm('companyData', companyData, undefined, isEdit);

  const timeDocumentFrom = watch('contractStartAt');
  const timeDocumentTo = watch('contractFinishAt');
  const planId = watch('planId');
  const contractNumber = watch('contractNumber');
  const dispatch = useDispatch();
  const [plans, setPlans] = useState<{ value: number; name: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<any[]>(formDataArray || []);
  const [formattedWorkTime, setFormattedWorkTime] = useState<string>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const hasFetchedPlans = useRef(false);
  const formattedDateRange =
    timeDocumentFrom && timeDocumentTo
      ? `з ${new Date(timeDocumentFrom).toLocaleDateString()} по ${new Date(timeDocumentTo).toLocaleDateString()}`
      : '';

  const handleFormSubmit = async (data: Company) => {
    try {
      const savedData = await onSubmit(data);
      console.log('Компанія збережена:', savedData);

      const startAt = timeDocumentFrom
        ? new Date(timeDocumentFrom).toISOString()
        : null;
      const finishAt = timeDocumentTo
        ? new Date(timeDocumentTo).toISOString()
        : null;

      console.log(
        'startAt:',
        startAt,
        'finishAt:',
        finishAt,
        'planId:',
        planId,
        'contractNumber:',
        contractNumber,
      );

      if (timeDocumentFrom && timeDocumentTo && planId && contractNumber) {
        const updateResponse = await axiosInstance.patch(
          '/Admin/UpdateClientPlan',
          {
            clientId: savedData.id,
            planId: Number(planId),
            contractNumber: contractNumber,
            contractStartAt: startAt,
            contractFinishAt: finishAt,
          },
        );

        console.log('PATCH запит успішно виконано:', updateResponse.data);
      } else {
        console.log('Не всі поля заповнені для оновлення плану');
      }
      onSave(savedData);
    } catch (error) {
      console.log('Сталася помилка під час обробки форми:', error);

      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          const errorMessage =
            error.response.data?.Message || error.response.data?.message;
          if (
            errorMessage &&
            errorMessage.toLowerCase().includes('user already exists')
          ) {
            dispatch(
              showSnackbar({
                message: 'Така компанія вже зареєстрована',
                type: 'error',
              }),
            );
          } else {
            dispatch(
              showSnackbar({
                message: 'Помилка запиту: Некоректні дані',
                type: 'error',
              }),
            );
          }
        } else {
          dispatch(
            showSnackbar({
              message: 'Сталася помилка при збереженні компанії',
              type: 'error',
            }),
          );
        }
      }
    }
  };

  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  const formatWorkTime = (workTime: WorkTime[]): string => {
    if (!workTime.length) return '';

    const daysOfWeekShort = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];
    const firstDay = workTime[0];
    const lastDay = workTime[workTime.length - 1];

    if (workTime.length === 1) {
      return `${daysOfWeekShort[firstDay.dayOfTheWeek]} ${formatTime(
        firstDay.timeStart,
      )} - ${formatTime(firstDay.timeEnd)}`;
    }

    return `${daysOfWeekShort[firstDay.dayOfTheWeek]} - ${
      daysOfWeekShort[lastDay.dayOfTheWeek]
    } ${formatTime(firstDay.timeStart)} - ${formatTime(firstDay.timeEnd)}`;
  };

  const handleWorkTimeSaveAndUpdate = (workTime: WorkTime[]) => {
    handleWorkTimeSave(workTime);
    setFormattedWorkTime(formatWorkTime(workTime));
  };

  const handleOpenEmployees = () => {
    if (onOpenEmployeesModal && companyData?.id) {
      onOpenEmployeesModal(companyData.id);
    }
  };

  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const fetchPlans = async () => {
    if (hasFetchedPlans.current || loading) return;

    try {
      const controller = new AbortController();
      abortControllerRef.current = controller;

      setLoading(true);
      const response = await axiosInstance.get('/Plans/GetPlansList', {
        signal: controller.signal,
      });

      setPlans(response.data);
      hasFetchedPlans.current = true;
      setError(null);
    } catch (err) {
    } finally {
      setLoading(false);
      abortControllerRef.current = null;
    }
  };
  useEffect(() => {
    if (!hasFetchedPlans.current && !loading) {
      fetchPlans();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [loading]);

  useEffect(() => {
    if (formDataArray && formDataArray.length > 0) {
      setFormData(formDataArray);
    }
  }, [formDataArray]);

  return (
    <div className={style.formWrapper} onClick={handleWrapperClick}>
      <div className={style.modalWrap}>
        <FormHeader
          title={
            isEdit ? `Компанія: ${companyData?.userName}` : 'Нова компанія'
          }
          onClose={onClose}
        />
        <form
          className={style.companyForm}
          onSubmit={handleSubmit(handleFormSubmit)}
          noValidate
        >
          <div className={style.screenInfo}>
            <div className={style.avatar}>
              {companyData?.avatarUrl ? (
                <img
                  src={companyData.avatarUrl}
                  alt={`${companyData.userName}'s avatar`}
                />
              ) : (
                <DefaultAvatar />
              )}
            </div>
            <FormSection title={''}>
              <TagSelector
                selectedTags={selectedTags}
                onChange={handleTagChange}
              />
            </FormSection>
          </div>
          <FormSection title="Компанія">
            <div className={style.displayTop}>
              <FieldController
                name="userName"
                control={control}
                rules={{
                  required: "Це поле є обов'язковим",
                  maxLength: {
                    value: 80,
                    message: 'Максимальна кількість символів 80',
                  },
                }}
                type="text"
                label="Назва"
                error={errors.userName}
              />
              <div onClick={openWorkTimeModal}>
                <FieldController
                  name="workTime"
                  control={control}
                  type="text"
                  label="Години роботи"
                  value={formattedWorkTime}
                  error={errors.workTime as FieldError | undefined}
                  readOnly
                  rules={undefined}
                />
              </div>
              <FieldController
                name="edrpou"
                control={control}
                type="text"
                label="Код ЄДРПОУ"
                error={errors.edrpou}
                rules={{
                  maxLength: {
                    value: 8,
                    message: 'Код ЄДРПОУ має бути не більше 8 символів',
                  },
                }}
              />
            </div>
            <div className={style.displayMiddle}>
              <FieldController
                name="name"
                control={control}
                type="text"
                label="ПІБ керівника"
                error={errors.name}
                rules={{
                  maxLength: {
                    value: 64,
                    message: 'Максимальна кількість символів 64',
                  },
                }}
              />
              <FieldController
                name="directorPosition"
                control={control}
                type="text"
                label="Посада керівника"
                error={errors.directorPosition}
                rules={{
                  maxLength: {
                    value: 64,
                    message: 'Максимальна кількість символів 64',
                  },
                }}
              />
              <FieldController
                name="iban"
                control={control}
                type="text"
                label="Рахунок / IBAN"
                error={errors.iban}
                rules={{
                  maxLength: {
                    value: 29,
                    message: 'Рахунок / IBAN має бути не більше 29 символів',
                  },
                }}
              />
            </div>
            <div className={style.displayBottom}>
              <FieldController
                name="email"
                control={control}
                rules={{
                  required: "Це поле є обов'язковим",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Введіть дійсний email',
                  },
                  maxLength: {
                    value: 256,
                    message: 'Email має бути не більше 256 символів',
                  },
                }}
                type="email"
                label="Email"
                error={errors.email}
              />
              <FieldController
                name="phone"
                control={control}
                rules={{
                  required: "Це поле є обов'язковим",
                  pattern: {
                    value: /^\+\d{12}$/,
                    message:
                      'Номер телефону має бути у форматі +38 000 000 00 00',
                  },
                }}
                type="tel"
                label="Телефон"
                error={errors.phone}
              />
              <FieldController
                name="website"
                control={control}
                type="text"
                label="Вебсторінка"
                error={errors.website}
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'Вебсторінка має бути не більше 256 символів',
                  },
                }}
              />
            </div>
          </FormSection>
          <FormSection title="Підписка">
            <div className={style.displayTop}>
              <FieldController
                name="plan"
                control={control}
                type="select"
                label="Тариф"
                options={plans.map((plan) => ({
                  value: plan.value.toString(),
                  label: plan.name,
                }))}
                error={errors.plan}
                value={watch('plan')}
              />
              <FieldController
                name="contractNumber"
                control={control}
                type="text"
                label="Номер договору"
                error={errors.contractNumber}
              />
              <div onClick={openDateModal}>
                <Input
                  type="text"
                  label="Термін договору"
                  value={formattedDateRange}
                  readOnly
                  name="timeContractRange"
                  onChange={() => {}}
                  onBlur={() => {}}
                />
              </div>
            </div>
          </FormSection>
          <div className={isEdit ? style.buttonEdit : style.buttonContainer}>
            {isEdit && (
              <button
                className={style.employeesButton}
                type="button"
                onClick={handleOpenEmployees}
              >
                <EmployeesIcon />
                <span>Співробітники</span>
              </button>
            )}
            <button
              className={style.submitButton}
              type="submit"
              disabled={!isValid}
            >
              {isEdit ? 'Зберегти' : 'Зберегти та надіслати запрошення'}
            </button>
          </div>
        </form>
        <DateModal
          isOpen={isDateModalOpen}
          onClose={closeDateModal}
          onSelect={handleDateSelect}
        />
        <WorkTimeModal
          isOpen={isWorkTimeModalOpen}
          onClose={closeWorkTimeModal}
          workTime={companyData?.workTime || []}
          onSave={handleWorkTimeSaveAndUpdate}
        />
      </div>
    </div>
  );
};

export default CompanyForm;
