import React, { useEffect, useRef, useState } from 'react';
import DateInput from '../DateInput/DateInput';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import CrossIcon from '../icon/Сross';
import styles from './DateModal.module.css';
import { DateModalProps } from './healpers';

const months = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];

const years = Array.from({ length: 50 }, (_, i) => i + 2000);
const daysOfWeek = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

const DateModal: React.FC<DateModalProps> = ({ isOpen, onClose, onSelect }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const startDateInputRef = useRef<HTMLInputElement>(null);
  const endDateInputRef = useRef<HTMLInputElement>(null);
  const yearDropdownRef = useRef<HTMLDivElement>(null);

  const [activeContainer, setActiveContainer] = useState<
    'start' | 'end' | null
  >(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth(),
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear(),
  );
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] =
    useState<boolean>(false);
  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState<boolean>(false);

  const handleSelect = (date: Date | null) => {
    if (activeContainer === 'start') {
      setStartDate(date);
      if (date && endDate && date > endDate) {
        setEndDate(null);
      }
    } else if (activeContainer === 'end') {
      if (!startDate || (startDate && date && date >= startDate)) {
        setEndDate(date);
      }
    }
  };

  const handleMonthChange = (increment: number) => {
    let newMonth = selectedMonth + increment;
    let newYear = selectedYear;

    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    } else if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const handleMonthSelect = (month: number) => {
    setSelectedMonth(month);
    setIsMonthDropdownOpen(false);
  };

  const handleYearSelect = (year: number) => {
    setSelectedYear(year);
    setIsYearDropdownOpen(false);
  };

  const handleDateInputClick = (container: 'start' | 'end') => {
    setActiveContainer(container);
  };

  const handleSaveDates = () => {
    onSelect({ startDate, endDate });
    onClose();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }

    if (
      startDateInputRef.current &&
      !startDateInputRef.current.contains(event.target as Node)
    ) {
      startDateInputRef.current.blur();
    }

    if (
      endDateInputRef.current &&
      !endDateInputRef.current.contains(event.target as Node)
    ) {
      endDateInputRef.current.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setStartDate(null);
      setEndDate(null);
      setActiveContainer(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isYearDropdownOpen && yearDropdownRef.current) {
      const currentYearIndex = years.indexOf(selectedYear);
      if (currentYearIndex !== -1) {
        yearDropdownRef.current.scrollTop = currentYearIndex * 40;
      }
    }
  }, [isYearDropdownOpen, selectedYear]);

  if (!isOpen) return null;

  const isDateSelected = startDate !== null && endDate !== null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} ref={modalRef}>
        <div className={styles.title}>
          <h6>Термін договору</h6>
          <button className={styles.closeButton} onClick={onClose}>
            <CrossIcon />
          </button>
        </div>
        <div className={styles.dropdownWrap}>
          <DateInput
            ref={startDateInputRef}
            label="Дата початку"
            date={startDate}
            active={activeContainer === 'start'}
            onClick={() => handleDateInputClick('start')}
            onDateChange={(date) => handleSelect(date)}
            onInputChange={(value) => {
              const [day, month, year] = value.split('.').map(Number);
              if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                const newDate = new Date(year, month - 1, day);
                if (!isNaN(newDate.getTime())) {
                  handleSelect(newDate);
                }
              }
            }}
          />
          <DateInput
            ref={endDateInputRef}
            label="Дата кінця"
            date={endDate}
            active={activeContainer === 'end'}
            onClick={() => handleDateInputClick('end')}
            onDateChange={(date) => handleSelect(date)}
            onInputChange={(value) => {
              const [day, month, year] = value.split('.').map(Number);
              if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                const newDate = new Date(year, month - 1, day);
                if (!isNaN(newDate.getTime())) {
                  handleSelect(newDate);
                }
              }
            }}
          />
        </div>
        <div className={styles.datePickerContainer}>
          <div className={styles.monthPicker}>
            <button
              onClick={() => handleMonthChange(-1)}
              className={styles.buttonRegulator}
            >
              {'<'}
            </button>
            <div
              className={`${styles.customSelect} ${isMonthDropdownOpen ? styles.open : ''}`}
              onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
            >
              {months[selectedMonth]}
              <span
                className={`${styles.arrow} ${isMonthDropdownOpen ? styles.open : ''}`}
              />
              {isMonthDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  {months.map((month, index) => (
                    <div
                      key={index}
                      className={styles.dropdownItem}
                      onClick={() => handleMonthSelect(index)}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className={`${styles.customSelect} ${isYearDropdownOpen ? styles.open : ''}`}
              style={{ width: '112px' }}
              onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
            >
              {selectedYear}
              <span
                className={`${styles.arrow} ${isYearDropdownOpen ? styles.open : ''}`}
              />
              {isYearDropdownOpen && (
                <div className={styles.dropdownMenu} ref={yearDropdownRef}>
                  {years.map((year, index) => (
                    <div
                      key={index}
                      className={styles.dropdownItem}
                      onClick={() => handleYearSelect(year)}
                    >
                      {year}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              onClick={() => handleMonthChange(1)}
              className={styles.buttonRegulator}
            >
              {'>'}
            </button>
          </div>
          <div className={styles.daysWeek}>
            {daysOfWeek.map((day, index) => (
              <div key={index} className={styles.day}>
                {day}
              </div>
            ))}
          </div>
          <DateRangePicker
            onSelect={(dates) => {
              setStartDate(dates.startDate);
              setEndDate(dates.endDate);
            }}
            startDate={startDate}
            endDate={endDate}
            activeInput={activeContainer}
            month={selectedMonth}
            year={selectedYear}
          />
        </div>
        <div className={styles.wrapButton}>
          <button onClick={onClose}>Скасувати</button>
          <button
            className={styles.saveButton}
            onClick={handleSaveDates}
            disabled={!isDateSelected}
          >
            Обрати
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateModal;
