import React, { useEffect } from 'react';
import style from './ConfirmationModal.module.css';
import LeadingIcon from './icon/Leading';
import DeleteIcon from './icon/delete';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
  onDelete: (scheduleId: number) => Promise<void>; // Add onDelete prop
  position: { top: number; left: number };
  scheduleId: number;
  userId: string;
  setUpdateCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onEdit,
  onDelete,
  position,
  scheduleId,
  userId,
  setUpdateCounter,
}) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modalContent = document.getElementById(
        'confirmation-modal-content',
      );
      if (modalContent && !modalContent.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const modalStyle = {
    top: position.top + 115,
    left: position.left + 50,
    transform: 'translate(-50%, -50%)',
  };

  const handleDeleteClick = async () => {
    try {
      await onDelete(scheduleId);
      setUpdateCounter((prevCounter) => prevCounter + 1);
      onClose();
    } catch (error) {
      console.error('Failed to delete schedule:', error);
    }
  };

  return (
    <div className={style.modalOverlay}>
      <div
        id="confirmation-modal-content"
        className={style.modalContent}
        style={modalStyle}
      >
        <button onClick={onEdit} className={style.editButton}>
          <LeadingIcon />
          Змінити
        </button>
        <button onClick={handleDeleteClick} className={style.deleteButton}>
          <DeleteIcon />
          Видалити
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
