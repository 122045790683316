import React from 'react';
import styles from './button.module.css';

interface ButtonProps {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  type = 'button',
  disabled = false,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${styles.submit_button} ${disabled ? styles.invalid : styles.valid}`}
    >
      {text}
    </button>
  );
};

export default Button;
