import { FetchTranslatorsResponse } from '../../types';
import axiosInstance from '../../utils/axiosInstance';
import {
  appendTranslatorsSuccess,
  loadTranslatorsFailure,
  loadTranslatorsRequest,
  loadTranslatorsSuccess,
  searchTranslatorsFailure,
  searchTranslatorsRequest,
  searchTranslatorsSuccess,
} from '../actions/translatorsActions';
import { AppDispatch } from '../store';

export const fetchTranslators =
  (
    pageIndex: number,
    pageSize: number,
    orderDirection: string = 'ASC',
    searchString: string = '',
  ) =>
  async (dispatch: AppDispatch): Promise<FetchTranslatorsResponse> => {
    dispatch(loadTranslatorsRequest({ pageIndex, pageSize, orderDirection }));
    try {
      const response = await axiosInstance.post('/Admin/GetTranslatorsList', {
        pageIndex,
        pageSize,
        searchString,
        orderDirection,
      });
      const translators = response.data;
      if (pageIndex === 0) {
        dispatch(loadTranslatorsSuccess(translators.items));
      } else {
        dispatch(appendTranslatorsSuccess(translators.items));
      }
      return translators;
    } catch (error: any) {
      dispatch(loadTranslatorsFailure(error.message));
      throw error;
    }
  };

export const searchTranslators =
  (query: string) => async (dispatch: AppDispatch) => {
    dispatch(searchTranslatorsRequest());
    try {
      const response = await axiosInstance.post('/Admin/GetTranslatorsList', {
        pageIndex: 0,
        pageSize: 10,
        searchString: query,
        orderDirection: 'ASC',
      });
      const searchResults = response.data.items;
      dispatch(searchTranslatorsSuccess(searchResults));
    } catch (error: any) {
      dispatch(searchTranslatorsFailure(error.message));
    }
  };
