import React from 'react';
import style from './FormSection.module.css';

interface FormSectionProps {
  title: string;
  children: React.ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({ title, children }) => (
  <div className={style.wrapInfo}>
    <h6>{title}</h6>
    {children}
  </div>
);

export default FormSection;
