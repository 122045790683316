import axiosInstance from '../../../utils/axiosInstance';

export const adjustMenuPosition = (
  menuRef: React.RefObject<HTMLDivElement>,
  menuPosition: { top: number; left: number },
) => {
  if (!menuRef.current) return { top: 0, left: 0 };

  const { top, left } = menuPosition;
  const menuRect = menuRef.current.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  let adjustedTop = top - 8;
  let adjustedLeft = left + 100;

  if (adjustedLeft + menuRect.width > viewportWidth) {
    adjustedLeft = viewportWidth - menuRect.width - 10;
  }

  if (adjustedTop + menuRect.height > viewportHeight) {
    adjustedTop = top - menuRect.height - 15;

    if (adjustedTop < 0) {
      adjustedTop = 10;
    }
  }

  if (adjustedLeft < 0) adjustedLeft = 10;
  if (adjustedTop < 0) adjustedTop = 10;

  return { top: adjustedTop, left: adjustedLeft };
};

export const handleDeleteClick = async (
  translatorId: string,
  onDelete: (translatorId: string) => void,
) => {
  try {
    const response = await axiosInstance.delete(`/Admin/RemoveTranslator`, {
      params: { translatorId },
    });

    if (response.status === 200) {
      console.log('Перекладача успішно видалено');
      onDelete(translatorId);
    } else {
      console.error('Не вдалося видалити перекладача');
    }
  } catch (error) {
    console.error('Помилка:', error);
  }
};
