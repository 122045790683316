import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import {
  addAdmin,
  FormValues,
  NewAdminModalProps,
  uploadAvatar,
} from './adminService';
import AvatarLoader from './AvatarLoader/AvatarLoader';
import InputField from './InputField/InputField';
import ModalHeader from './ModalHeader/ModalHeader';
import style from './newAdminModal.module.css';
import SubmitButton from './SubmitButton/SubmitButton';
import SuccessModal from './SuccessModal/SuccessModal';

const NewAdminModal: React.FC<NewAdminModalProps> = ({
  setIsNewAdminModalOpen,
  fetchAdmins,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'onChange' });
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleOnClose = () => {
    setIsNewAdminModalOpen(false);
  };

  const handleOutsideClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      handleOnClose();
    }
  };

  const handleAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await uploadAvatar(file);
        const { url } = response.data;
        setAvatarUrl(url);
        setValue('avatarUrl', url);
      } catch (error) {
        console.error('Error uploading avatar:', error);
      }
    }
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await addAdmin(data);

      if (response.status === 200) {
        fetchAdmins();
        setIsSuccessModalOpen(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  };

  const handleError = (error: any) => {
    if (error.response && error.response.status === 400) {
      const errorMessage =
        error.response.data?.Message || error.response.data?.message;
      if (
        errorMessage &&
        errorMessage.toLowerCase().includes('user already exists')
      ) {
        dispatch(
          showSnackbar({
            message: 'Такий адміністратор вже зареєстрований',
            type: 'error',
          }),
        );
      } else {
        console.log('Other 400 error:', errorMessage);
        dispatch(
          showSnackbar({
            message: 'Помилка запиту: Некоректні дані',
            type: 'error',
          }),
        );
      }
    } else {
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при збереженні адміністратора',
          type: 'error',
        }),
      );
    }
  };

  return (
    <div>
      {isSuccessModalOpen && (
        <SuccessModal
          message="Новий адміністратор успішно доданий"
          onClose={() => {
            setIsSuccessModalOpen(false);
            handleOnClose();
          }}
        />
      )}
      <div className={style.modalOverlay} onClick={handleOutsideClick}>
        <div className={style.modalContent}>
          <ModalHeader
            title="Створення нового адміна"
            onClose={handleOnClose}
          />
          <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
            <AvatarLoader
              avatarUrl={avatarUrl}
              handleAvatarUpload={handleAvatarUpload}
            />
            <h6 className={style.infoTitle}>Персональна інформація</h6>
            <div className={style.wrapInputs}>
              <InputField
                label="Ім'я користувача"
                name="userName"
                register={register}
                required={true}
                validationRules={{
                  maxLength: { value: 80, message: 'Максимум 80 символів' },
                }}
                error={errors.userName}
              />
              <InputField
                label="Email"
                name="email"
                type="email"
                register={register}
                required={true}
                validationRules={{
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Введіть коректний email',
                  },
                }}
                error={errors.email}
              />
            </div>
            <div className={style.submitWrap}>
              <SubmitButton isValid={isValid} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewAdminModal;
