import { createReducer } from '@reduxjs/toolkit';
import {
  Client,
  loadClientsFailure,
  loadClientsRequest,
  loadClientsSuccess,
  searchClientsFailure,
  searchClientsRequest,
  searchClientsSuccess,
} from '../actions/clientsActions';

interface ClientsState {
  clients: Client[];
  searchResults: Client[];
  loading: boolean;
  error: string | null;
}

export const initialState: ClientsState = {
  clients: [],
  searchResults: [],
  loading: false,
  error: null,
};

const clientsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadClientsRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(loadClientsSuccess, (state, action) => {
      state.clients = action.payload;
      state.loading = false;
    })
    .addCase(loadClientsFailure, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(searchClientsRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(searchClientsSuccess, (state, action) => {
      state.searchResults = action.payload;
      state.loading = false;
    })
    .addCase(searchClientsFailure, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
});

export default clientsReducer;
