import React, { ChangeEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CustomSelect from './CustomSelect/CustomSelect';
import ImageUpload from './ImageUpload/ImageUpload';
import Input from './Input/Input';
import SuccessModal from './SuccessModal/SuccessModal';
import style from './TranslatorForm.module.css';
import { addTranslator, uploadImage } from './helpers';
import CrossIcon from './icons/Сross';

interface TranslatorFormProps {
  onClose: () => void;
}

interface FormData {
  role: string;
  tag: string;
  name: string;
  email: string;
  phone: string;
}

const TranslatorForm: React.FC<TranslatorFormProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({ mode: 'onChange' });
  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setError(null);
    let avatarUrl = '';

    try {
      if (image) {
        avatarUrl = await uploadImage(image);
      }

      const success = await addTranslator(data, avatarUrl, dispatch);
      if (success) {
        setSubmittedEmail(data.email);
        setShowSuccessModal(true);
        reset();
      }
    } catch (error: any) {
      setError('Виникла помилка при додаванні перекладача');
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  return (
    <div className={style.formOverlay}>
      {showSuccessModal ? (
        <SuccessModal email={submittedEmail} onClose={handleClose} />
      ) : (
        <div className={style.formContainer}>
          <div className={style.header}>
            <h2>Новий перекладач</h2>
            <button
              type="button"
              onClick={onClose}
              className={style.closeButton}
            >
              <CrossIcon />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={style.wrap_form}>
            <div className={style.section}>
              <ImageUpload
                image={image ? URL.createObjectURL(image) : null}
                handleImageChange={handleImageChange}
              />
              <CustomSelect
                name="role"
                label="Роль"
                options={[{ value: 'Перекладач', label: 'Перекладач' }]}
                register={register}
                defaultValue=" "
                validation={{ required: "Це поле є обов'язковим" }}
                errors={errors}
              />
              <div className={style.inputContainer}>
                <Input
                  type="text"
                  register={register}
                  name="tag"
                  label="Тег"
                  validation={{ required: "Це поле є обов'язковим" }}
                  errors={errors}
                />
              </div>
            </div>
            <div>
              <div className={style.title}>
                <h4>Персональна інформація</h4>
              </div>
              <div className={style.personal_information}>
                <Input
                  type="text"
                  register={register}
                  name="name"
                  label="ПІБ"
                  validation={{
                    required: "Це поле є обов'язковим",
                    maxLength: {
                      value: 80,
                      message: 'Максимальна кількість символів 80',
                    },
                    pattern: {
                      value: /^[А-Яа-яЁёЇїІіЄєҐґ\s]+$/,
                      message: 'Має приймати лише літери Кирилиці',
                    },
                  }}
                  errors={errors}
                />
                <Input
                  type="email"
                  register={register}
                  name="email"
                  label="EMAIL"
                  validation={{
                    required: "Це поле є обов'язковим",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Введіть дійсний email',
                    },
                  }}
                  errors={errors}
                />
                <Input
                  type="tel"
                  register={register}
                  name="phone"
                  label="ТЕЛЕФОН"
                  validation={{
                    required: "Це поле є обов'язковим",
                    pattern: {
                      value: /^\+\d{12}$/,
                      message:
                        'Номер телефону має бути у форматі +38 000 000 00 00',
                    },
                  }}
                  errors={errors}
                />
              </div>
            </div>
            <div className={style.wrap_buttons}>
              <button
                type="submit"
                className={!isValid ? style.buttons_disabled : style.buttons}
                disabled={!isValid}
              >
                Зберегти та надіслати запрошення
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TranslatorForm;
