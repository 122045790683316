import React, { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import style from './CustomSelect.module.css';

interface CustomSelectProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  register: UseFormRegister<any>;
  defaultValue: string;
  validation?: any;
  errors: any;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  label,
  options,
  register,
  defaultValue,
  validation = {},
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className={`${style.customSelectContainer} ${isOpen ? style.open : ''}`}
    >
      <label>{label}</label>
      <div className={style.selectedOption} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption}
      </div>
      {isOpen && (
        <div className={style.optionsContainer}>
          {options.map((option) => (
            <div
              key={option.value}
              className={style.option}
              onClick={() => handleOptionClick(option.label)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      <input
        type="hidden"
        {...register(name, validation)}
        value={selectedOption}
      />
      {errors[name] && (
        <span className={style.error}>{errors[name]?.message}</span>
      )}
    </div>
  );
};

export default CustomSelect;
