import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteSchedule, updateSchedule } from '../actions/scheduleActions';
import { ScheduleState, WorkSchedule } from '../type/cheduleTypes';

export const initialState: ScheduleState = {
  schedule: {},
  status: 'idle',
  error: null,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedule: (
      state,
      action: PayloadAction<{ [key: string]: WorkSchedule[] }>,
    ) => {
      state.schedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSchedule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        const { dayKey, newWork } = action.payload;
        state.schedule[dayKey] = [...(state.schedule[dayKey] || []), newWork];
        state.status = 'succeeded';
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update schedule';
      })
      .addCase(deleteSchedule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        const { dayKey, scheduleId } = action.payload;
        if (state.schedule[dayKey]) {
          state.schedule[dayKey] = state.schedule[dayKey].filter(
            (work) => work.id !== scheduleId,
          );
        }
        state.status = 'succeeded';
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete schedule';
      });
  },
});

export const { setSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
