import React from 'react';

interface SortArrowProps {
  fill?: string;
  className: string;
}

const SortArrow: React.FC<SortArrowProps> = ({
  fill = '#152837',
  className,
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8 13.6094V6.43438L5.425 9.00938L4 7.60938L9 2.60938L14 7.60938L12.575 9.00938L10 6.43438V13.6094H8ZM15 22.6094L10 17.6094L11.425 16.2094L14 18.7844V11.6094H16V18.7844L18.575 16.2094L20 17.6094L15 22.6094Z"
      fill="#152837"
    />
  </svg>
);

export default SortArrow;
