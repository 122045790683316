import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { iconsConfig } from '../../pages/CustomersPage/helpers';
import PushLogo from '../../pages/CustomersPage/icons/Notification';
import { RootState } from '../../store/store';
import Sidebar from '../Sidebar/Sidebar';
import style from './MainLayout.module.css';

const MainLayout: React.FC = () => {
  const { jwtToken } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeComponent, setActiveComponent] =
    useState<keyof typeof iconsConfig>('customer');

  useEffect(() => {
    if (!jwtToken) {
      navigate('/');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    if (path && path in iconsConfig) {
      setActiveComponent(path as keyof typeof iconsConfig);
    }
  }, [location.pathname]);

  return (
    <div className={style.customers}>
      <Sidebar
        setActiveComponent={setActiveComponent}
        activeComponent={activeComponent}
      />
      <div className={style.management_content}>
        <div className={style.management_header}>
          <h3 className={style.header_title}>
            {iconsConfig[activeComponent].label}
          </h3>
          <button className={style.header_button}>
            <PushLogo fill="#596987" />
          </button>
        </div>
        <div className={style.management_active_component}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
