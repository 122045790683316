import style from './plan.module.css';
import { PlanType } from './planTypes';

export const getBackgroundClass = (planType: PlanType): string => {
  switch (planType) {
    case PlanType.Network:
      return style.network;
    case PlanType.Subscription:
      return style.subscription;
    case PlanType.FOP:
      return style.fop;
    case PlanType.LegalEntity:
      return style.legalEntity;
    case PlanType.EventTranslation:
      return style.eventTranslation;
    default:
      return '';
  }
};
