import axiosInstance from '../../../utils/axiosInstance';

export interface NewAdminModalProps {
  setIsNewAdminModalOpen: (isOpen: boolean) => void;
  fetchAdmins: () => void;
}

export interface FormValues {
  userName: string;
  email: string;
  avatarUrl: string;
}

export const addAdmin = async (data: FormValues) => {
  return await axiosInstance.put('/Admin/AddAdmin', data);
};

export const deleteAdmin = async (adminId: string) => {
  return await axiosInstance.delete('/Admin/RemoveAdmin', {
    params: { adminId },
  });
};

export const uploadAvatar = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return await axiosInstance.post('/File/UploadAvatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
