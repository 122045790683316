import axiosInstance from '../../utils/axiosInstance';
import {
  loadClientsFailure,
  loadClientsRequest,
  loadClientsSuccess,
  searchClientsFailure,
  searchClientsRequest,
  searchClientsSuccess,
} from '../actions/clientsActions';
import { AppDispatch } from '../store';

export const fetchClients =
  (
    pageIndex: number,
    pageSize: number,
    orderDirection: string = 'ASC',
    searchString: string = '',
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadClientsRequest({ pageIndex, pageSize, orderDirection }));
    try {
      const response = await axiosInstance.post('/Admin/GetClientsList', {
        pageIndex,
        pageSize,
        searchString,
        orderDirection,
      });
      const clients = response.data.items;
      dispatch(loadClientsSuccess(clients));
      return { clients };
    } catch (error: any) {
      dispatch(loadClientsFailure(error.message));
      return { clients: [] };
    }
  };
