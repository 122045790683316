import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { Translator } from '../../../types';
import style from './Calendar.module.css';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import DatesGrid from './DatesGrid/DatesGrid';
import DaysOfWeek from './DaysOfWeek/DaysOfWeek';
import Modal from './TimeModal/TimeModal';
import { fetchUpdatedSchedule, saveSchedule } from './helpers';

interface CalendarProps {
  userId: string;
  onAdd: (day: keyof Translator['weeklyWorkSchedule'], date: string) => void;
  updateCounter: number;
  setUpdateCounter: React.Dispatch<React.SetStateAction<number>>;
}

interface ModalPosition {
  top: number;
  left: number;
}

const Calendar: React.FC<CalendarProps> = ({
  onAdd,
  userId,
  updateCounter,
  setUpdateCounter,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [modalPosition, setModalPosition] = useState<ModalPosition>({
    top: 0,
    left: 0,
  });
  const buttonRef = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch<AppDispatch>();
  const schedule = useSelector((state: RootState) => state.schedule.schedule);

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1),
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1),
    );
  };

  const openModal = (
    date: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSelectedDate(date);
    setModalOpen(true);
    const rect = event.currentTarget.getBoundingClientRect();
    const offsetTop = 130;
    const offsetLeft = 50;
    setModalPosition({
      top: rect.top + window.scrollY + offsetTop,
      left: rect.left + window.scrollX + offsetLeft,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedDate('');
  };

  const handleSave = async (startAt: string, finishAt: string) => {
    await saveSchedule(
      userId,
      selectedDate,
      startAt,
      finishAt,
      fetchUpdatedSchedule,
      dispatch,
    );
    closeModal();
  };

  return (
    <div className={style.calendar}>
      <CalendarHeader
        currentMonth={currentMonth}
        onPrevMonth={handlePrevMonth}
        onNextMonth={handleNextMonth}
      />
      <DaysOfWeek />
      <DatesGrid
        currentMonth={currentMonth}
        onDateClick={openModal}
        schedule={schedule}
        userId={userId}
        setUpdateCounter={setUpdateCounter}
      />
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        onSave={handleSave}
        position={modalPosition}
      />
    </div>
  );
};

export default Calendar;
