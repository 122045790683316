import { FC, KeyboardEvent } from 'react';
import style from './AddTagInput.module.css';

interface AddTagInputProps {
  newTag: string;
  setNewTag: (tag: string) => void;
  handleAddTag: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const AddTagInput: FC<AddTagInputProps> = ({
  newTag,
  setNewTag,
  handleAddTag,
}) => (
  <input
    type="text"
    placeholder="Введіть назву тега"
    value={newTag}
    onChange={(e) => setNewTag(e.target.value)}
    onKeyDown={handleAddTag}
    className={style.newTagInput}
  />
);

export default AddTagInput;
