import React, { useRef, useState } from 'react';
import styles from './DateRangePicker.module.css';

interface DateRangePickerProps {
  month: number;
  year: number;
  startDate: Date | null;
  endDate: Date | null;
  activeInput: 'start' | 'end' | null;
  onSelect: (dates: { startDate: Date | null; endDate: Date | null }) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  month,
  year,
  startDate,
  endDate,
  activeInput,
  onSelect,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartDate, setDragStartDate] = useState<Date | null>(null);
  const dragTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInPreviousMonth = new Date(year, month, 0).getDate();

  const weeks: (Date | null)[][] = [];
  let currentDay = 1 - ((firstDayOfMonth + 6) % 7);

  while (currentDay <= daysInMonth) {
    const week: (Date | null)[] = Array(7).fill(null);
    for (let i = 0; i < 7; i++) {
      if (currentDay > 0 && currentDay <= daysInMonth) {
        week[i] = new Date(year, month, currentDay);
      } else if (currentDay <= 0) {
        week[i] = new Date(year, month - 1, daysInPreviousMonth + currentDay);
      } else {
        week[i] = new Date(year, month + 1, currentDay - daysInMonth);
      }
      currentDay++;
    }
    weeks.push(week);
  }

  const isSameDay = (date1: Date, date2: Date): boolean => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleMouseDown = (date: Date | null) => {
    if (!date || date.getMonth() !== month) return;

    dragTimeoutRef.current = setTimeout(() => {
      setIsDragging(true);
      setDragStartDate(date);
      if (activeInput === 'start') {
        onSelect({ startDate: date, endDate: null });
      } else if (activeInput === 'end' && startDate && date > startDate) {
        onSelect({ startDate, endDate: date });
      }
    }, 1000);
  };

  const handleMouseUp = (date: Date | null) => {
    if (!date || date.getMonth() !== month) return;

    if (dragTimeoutRef.current) {
      clearTimeout(dragTimeoutRef.current);
    }

    if (isDragging) {
      setIsDragging(false);
      if (dragStartDate && date) {
        if (activeInput === 'start') {
          onSelect({
            startDate: dragStartDate,
            endDate: date < dragStartDate ? null : date,
          });
        } else if (activeInput === 'end') {
          if (startDate && date > startDate) {
            onSelect({ startDate, endDate: date });
          } else {
            onSelect({ startDate: date, endDate: dragStartDate });
          }
        }
      }
    } else {
      handleDateClick(date);
    }
  };

  const handleMouseMove = (date: Date | null) => {
    if (!isDragging || !date || date.getMonth() !== month) return;

    if (dragStartDate) {
      if (activeInput === 'start') {
        onSelect({
          startDate: dragStartDate,
          endDate: date < dragStartDate ? null : date,
        });
      } else if (activeInput === 'end' && startDate && date > startDate) {
        onSelect({ startDate, endDate: date });
      }
    }
  };

  const handleDateClick = (date: Date | null) => {
    if (!date || date.getMonth() !== month) return;

    if (activeInput === 'start') {
      onSelect({
        startDate: date,
        endDate: endDate && date > endDate ? null : endDate,
      });
    } else if (activeInput === 'end') {
      if (startDate && date < startDate) {
        onSelect({ startDate: date, endDate: null });
      } else {
        onSelect({ startDate, endDate: date });
      }
    }
  };

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.calendarGrid}>
        {weeks.map((week, weekIndex) => (
          <React.Fragment key={weekIndex}>
            {week.map((day, dayIndex) => {
              const isLeadingEmpty = day && day.getMonth() < month;
              const isTrailingEmpty = day && day.getMonth() > month;

              const isSelected =
                (startDate && day && isSameDay(startDate, day)) ||
                (endDate && day && isSameDay(endDate, day));
              const isInRange =
                startDate && endDate && day && day > startDate && day < endDate;

              const cellClassNames = [
                styles.calendarCell,
                isLeadingEmpty ? styles.leadingEmpty : '',
                isTrailingEmpty ? styles.trailingEmpty : '',
                isSelected ? styles.selected : '',
                isSelected && startDate && day && isSameDay(startDate, day)
                  ? styles.start
                  : '',
                isSelected && endDate && day && isSameDay(endDate, day)
                  ? styles.end
                  : '',
                isInRange ? styles.inRange : '',
                !isLeadingEmpty && !isTrailingEmpty && !isSelected && !isInRange
                  ? styles.empty
                  : '',
              ].join(' ');

              return (
                <div
                  key={dayIndex}
                  className={cellClassNames}
                  onMouseDown={() => handleMouseDown(day)}
                  onMouseUp={() => handleMouseUp(day)}
                  onMouseMove={() => handleMouseMove(day)}
                >
                  {day ? day.getDate() : ''}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default DateRangePicker;
