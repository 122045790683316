import { AdminListResponse } from '../../types';
import axiosInstance from '../../utils/axiosInstance';

export const fetchAdminList = async (
  pageIndex: number,
  searchString: string,
  orderDirection: 'asc' | 'desc',
): Promise<AdminListResponse> => {
  const response = await axiosInstance.post<AdminListResponse>(
    '/Admin/GetAdminList',
    {
      pageIndex,
      pageSize: 20,
      searchString,
      orderDirection,
    },
  );

  return response.data;
};

export const deleteAdmin = async (adminId: string): Promise<void> => {
  await axiosInstance.delete(`/Admin/RemoveAdmin`, {
    params: { adminId },
  });
};
