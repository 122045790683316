import { useEffect, useRef, useState } from 'react';
import { Admin } from '../../../types';
import { fetchAdminList } from '../adminService';

export const useAdmins = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const prevRequestRef = useRef<{
    pageIndex: number;
    searchString: string;
    orderDirection: 'asc' | 'desc';
  } | null>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchAdmins = async (resetPageIndex = false) => {
    if (isLoading || (!hasMore && !resetPageIndex)) return;

    const currentRequest = {
      pageIndex: resetPageIndex ? 0 : pageIndex,
      searchString,
      orderDirection,
    };

    if (
      !resetPageIndex &&
      prevRequestRef.current &&
      prevRequestRef.current.pageIndex === currentRequest.pageIndex &&
      prevRequestRef.current.searchString === currentRequest.searchString &&
      prevRequestRef.current.orderDirection === currentRequest.orderDirection
    ) {
      return;
    }

    prevRequestRef.current = currentRequest;
    setIsLoading(true);

    try {
      const { items, hasNextPage } = await fetchAdminList(
        currentRequest.pageIndex,
        currentRequest.searchString,
        currentRequest.orderDirection,
      );

      if (resetPageIndex) {
        setAdmins(items);
        setPageIndex(1);
      } else {
        setAdmins((prevAdmins) => [...prevAdmins, ...items]);
        setPageIndex((prevPageIndex) => prevPageIndex + 1);
      }

      setHasMore(hasNextPage);
    } catch (error) {
      console.error('Error fetching admin list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      fetchAdmins(true);
    }, 200);
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [searchString, orderDirection]);

  const handleSearch = (query: string) => {
    setSearchString(query);
    setHasMore(true);
  };

  const handleSort = () => {
    setOrderDirection((prevOrderDirection) =>
      prevOrderDirection === 'asc' ? 'desc' : 'asc',
    );
    setAdmins([]);
    setPageIndex(0);
    setHasMore(true);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 500
    ) {
      fetchAdmins();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return {
    admins,
    isLoading,
    searchString,
    orderDirection,
    hasMore,
    fetchAdmins,
    handleSearch,
    handleSort,
    setAdmins,
    setIsLoading,
  };
};
