import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import TimePicker from '../TimePicker/TimePicker';
import style from './ChangeModal.module.css';

interface ChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (startAt: string, finishAt: string) => Promise<void>;
  position: { top: number; left: number };
  initialStartAt?: string;
  initialFinishAt?: string;
  userId: string;
  workId?: number;
  selectedDate: Date;
}

const ChangeModal: React.FC<ChangeModalProps> = ({
  isOpen,
  onClose,
  position,
  onSave,
  initialStartAt = '00:00',
  initialFinishAt = '00:00',
  userId,
  workId,
  selectedDate,
}) => {
  const [startAt, setStartAt] = useState(initialStartAt);
  const [finishAt, setFinishAt] = useState(initialFinishAt);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  const handleSave = async () => {
    try {
      const datePart = selectedDate.toISOString().split('T')[0];
      console.log('Saving schedule with:', {
        startAt,
        finishAt,
        userId,
        datePart,
        workId,
      });
      await onSave(
        new Date(`${datePart}T${startAt}`).toISOString(),
        new Date(`${datePart}T${finishAt}`).toISOString(),
      );
      onClose();
      resetTime();
    } catch (error) {
      console.error('Помилка:', error);
    }
  };

  const handleClose = () => {
    onClose();
    resetTime();
  };

  const resetTime = () => {
    setStartAt(initialStartAt);
    setFinishAt(initialFinishAt);
    setIsSaveDisabled(true);
  };

  useEffect(() => {
    if (startAt !== '00:00' && finishAt !== '00:00') {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [startAt, finishAt]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.getElementById('modal');
      if (modal && !modal.contains(event.target as Node)) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setStartAt(initialStartAt);
    setFinishAt(initialFinishAt);
  }, [initialStartAt, initialFinishAt]);

  if (!isOpen) return null;

  const modalStyle = {
    top: position.top + 120,
    left: position.left + 40,
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div className={style.modalOverlay}>
      <div id="modal" className={style.modalContent} style={modalStyle}>
        <div className={style.timeChange}>
          <TimePicker label="Початок" value={startAt} onChange={setStartAt} />
          <TimePicker label="Кінець" value={finishAt} onChange={setFinishAt} />
        </div>
        <div className={style.buttons}>
          <button
            onClick={handleSave}
            className={isSaveDisabled ? style.saveDisabled : style.saveButton}
            disabled={isSaveDisabled}
          >
            Зберегти
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeModal;
