const VisibilityOff = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.075 8.47498L10.9875 7.38748C11.1 6.79998 10.9313 6.24998 10.4813 5.73748C10.0313 5.22498 9.45 5.02498 8.7375 5.13748L7.65 4.04998C7.8625 3.94998 8.07812 3.87498 8.29688 3.82498C8.51563 3.77498 8.75 3.74998 9 3.74998C9.9375 3.74998 10.7344 4.0781 11.3906 4.73435C12.0469 5.3906 12.375 6.18748 12.375 7.12498C12.375 7.37498 12.35 7.60935 12.3 7.8281C12.25 8.04685 12.175 8.26248 12.075 8.47498ZM14.475 10.8375L13.3875 9.78748C13.8625 9.42498 14.2844 9.0281 14.6531 8.59685C15.0219 8.1656 15.3375 7.67498 15.6 7.12498C14.975 5.86248 14.0781 4.85935 12.9094 4.1156C11.7406 3.37185 10.4375 2.99998 9 2.99998C8.6375 2.99998 8.28125 3.02498 7.93125 3.07498C7.58125 3.12498 7.2375 3.19998 6.9 3.29998L5.7375 2.13748C6.25 1.92498 6.775 1.7656 7.3125 1.65935C7.85 1.5531 8.4125 1.49998 9 1.49998C10.8875 1.49998 12.5688 2.02185 14.0438 3.0656C15.5188 4.10935 16.5875 5.46248 17.25 7.12498C16.9625 7.86248 16.5844 8.54685 16.1156 9.1781C15.6469 9.80935 15.1 10.3625 14.475 10.8375ZM14.85 15.45L11.7 12.3375C11.2625 12.475 10.8219 12.5781 10.3781 12.6469C9.93438 12.7156 9.475 12.75 9 12.75C7.1125 12.75 5.43125 12.2281 3.95625 11.1844C2.48125 10.1406 1.4125 8.78748 0.75 7.12498C1.0125 6.46248 1.34375 5.84685 1.74375 5.2781C2.14375 4.70935 2.6 4.19998 3.1125 3.74998L1.05 1.64998L2.1 0.599976L15.9 14.4L14.85 15.45ZM4.1625 4.79998C3.8 5.12498 3.46875 5.48123 3.16875 5.86873C2.86875 6.25623 2.6125 6.67498 2.4 7.12498C3.025 8.38748 3.92187 9.3906 5.09062 10.1344C6.25937 10.8781 7.5625 11.25 9 11.25C9.25 11.25 9.49375 11.2344 9.73125 11.2031C9.96875 11.1719 10.2125 11.1375 10.4625 11.1L9.7875 10.3875C9.65 10.425 9.51875 10.4531 9.39375 10.4719C9.26875 10.4906 9.1375 10.5 9 10.5C8.0625 10.5 7.26562 10.1719 6.60938 9.5156C5.95312 8.85935 5.625 8.06248 5.625 7.12498C5.625 6.98748 5.63437 6.85623 5.65312 6.73123C5.67187 6.60623 5.7 6.47498 5.7375 6.33748L4.1625 4.79998Z"
      fill="#596987"
    />
  </svg>
);

export default VisibilityOff;
