import React, { useState, useMemo } from 'react';
import Button from './Button/Button';
import style from './pushPanel.module.css';
import SearchInput from './SearchInput/SearchInput';
import { debounce } from 'lodash';
import PlusIcon from './icon/plusIcon';

interface PushPanelProps {
  onSearch: (query: string) => void;
  onActiveClick: () => void;
  onCompletedClick: () => void;
  onClear: () => void;
  activeType: 'active' | 'completed'; // Додаємо activeType до інтерфейсу
}

const PushPanel: React.FC<PushPanelProps> = ({
  onSearch,
  onActiveClick,
  onCompletedClick,
  onClear,
  activeType, // Отримуємо activeType як пропс
}) => {
  const [query, setQuery] = useState<string>('');

  const debouncedSearch = useMemo(
    () =>
      debounce((searchQuery: string) => {
        onSearch(searchQuery);
      }, 500),
    [onSearch],
  );

  const handleSearchChange = (searchQuery: string) => {
    setQuery(searchQuery);
    debouncedSearch(searchQuery);
  };

  const handleClearSearch = () => {
    setQuery('');
    debouncedSearch.cancel();
    onClear();
  };

  return (
    <div className={style.panel_wrap}>
      <div className={style.defaultStaffs}>
        <div className={style.wrapState}>
          <Button
            content="Активні"
            isActive={activeType === 'active'}
            onClick={onActiveClick}
          />
          <Button
            content="Вхідні заявки"
            isActive={activeType === 'completed'}
            onClick={onCompletedClick}
          />
        </div>
        <SearchInput
          query={query}
          setQuery={setQuery}
          onSearchChange={handleSearchChange}
          onClear={handleClearSearch}
        />
      </div>
      {/*{activeType === 'active' && (*/}
      {/*  <div className={style.addActiveButton}>*/}
      {/*    <Button*/}
      {/*      content={*/}
      {/*        <div className={style.buttonContent}>*/}
      {/*          <PlusIcon />*/}
      {/*          <span>Додати</span>*/}
      {/*        </div>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default PushPanel;
