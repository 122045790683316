import { useEffect, useState } from 'react';
import { useForm, FieldErrors } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../store/reducers/snackbarSlice';
import { Company, Tag } from '../../../../types';
import axiosInstance from '../../../../utils/axiosInstance';

interface WorkTime {
  timeStart: string;
  timeEnd: string;
  dayOfTheWeek: number;
}

export const useCompanyForm = (
  source: 'companyData' | 'formDataArray',
  companyData?: Company,
  formDataArray?: Company[],
  isEdit = false,
  newPlan?: string,
) => {
  const dispatch = useDispatch();

  const initialCompanyState: Company = {
    id: 0,
    userName: '',
    name: '',
    avatarUrl: '',
    plan: '',
    planId: 0,
    phone: '',
    email: '',
    workTime: [],
    edrpou: '',
    directorUserName: '',
    directorPosition: '',
    iban: '',
    website: '',
    contractNumber: '',
    contractStartAt: '',
    contractFinishAt: '',
    tags: [],
    newPlan: '',
    newPlanId: 0,
  };

  const [selectedFormIndex, setSelectedFormIndex] = useState<number>(0);
  const [formData, setFormData] = useState<Company[]>(formDataArray || []);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm<Company>({
    defaultValues: initialCompanyState,
    mode: 'onChange',
  });

  const parseWorkTime = (workTime: WorkTime[]): WorkTime[] => {
    return workTime.map((wt) => ({
      ...wt,
      timeStart: wt.timeStart,
      timeEnd: wt.timeEnd,
      dayOfTheWeek: wt.dayOfTheWeek,
    }));
  };

  const [isDateModalOpen, setDateModalOpen] = useState(false);
  const [isWorkTimeModalOpen, setWorkTimeModalOpen] = useState(false);
  const [workTime, setWorkTime] = useState<WorkTime[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (source === 'companyData' && companyData) {
      setWorkTime(parseWorkTime(companyData.workTime || []));
      setSelectedTags(companyData.tags || []);

      const planIdToSet = companyData.newPlanId || companyData.planId || '';
      setValue('plan', planIdToSet.toString());

      reset({ ...companyData, plan: planIdToSet.toString() });
    } else if (source === 'formDataArray' && formDataArray?.length) {
      const currentData = formDataArray[selectedFormIndex];

      setWorkTime(parseWorkTime(currentData.workTime || []));
      setSelectedTags(currentData.tags || []);

      const planIdToSet = currentData.newPlanId || currentData.planId || '';
      setValue('plan', planIdToSet.toString());

      reset({
        ...currentData,
        plan: planIdToSet.toString(),
      });
    } else {
      reset(initialCompanyState);
    }
  }, [companyData, formDataArray, source, reset, selectedFormIndex, setValue]);

  useEffect(() => {
    setValue('workTime', workTime);
    setValue('tags', selectedTags);
  }, [workTime, selectedTags, setValue]);

  const openDateModal = () => setDateModalOpen(true);
  const closeDateModal = () => setDateModalOpen(false);

  const openWorkTimeModal = () => setWorkTimeModalOpen(true);
  const closeWorkTimeModal = () => setWorkTimeModalOpen(false);

  const handleDateSelect = (dates: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    if (dates.startDate) {
      setValue('contractStartAt', new Date(dates.startDate).toISOString());
    }
    if (dates.endDate) {
      setValue('contractFinishAt', new Date(dates.endDate).toISOString());
    }
  };

  const handleWorkTimeSave = (workTime: WorkTime[]) => {
    setWorkTime(workTime);
  };

  const handleTagChange = (tags: Tag[]) => {
    setSelectedTags(tags);
  };

  const formatTimeWithSeconds = (time: string): string => {
    const [hours, minutes = '00', seconds = '00'] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };

  const transformDataToContract = (data: Company) => ({
    userData: {
      userName: data.userName,
      email: data.email,
      phone: data.phone,
      avatarUrl: data.avatarUrl || '',
      id: data.id.toString(),
    },
    workTime: data.workTime.map((time: WorkTime) => ({
      timeStart: formatTimeWithSeconds(time.timeStart),
      timeEnd: formatTimeWithSeconds(time.timeEnd),
      dayOfTheWeek: time.dayOfTheWeek,
    })),
    name: data.name,
    edrpou: data.edrpou,
    directorUserName: data.directorUserName,
    directorPosition: data.directorPosition,
    iban: data.iban,
    website: data.website,
    tagIds: data.tags.map((tag) => tag.id),
  });

  const updateClientPlan = async (
    clientId: string,
    planId: number,
    contractNumber: string,
    startAt: string | null,
    finishAt: string | null,
  ) => {
    try {
      await axiosInstance.patch('/Admin/UpdateClientPlan', {
        clientId,
        planId,
        contractNumber,
        contractStartAt: startAt,
        contractFinishAt: finishAt,
      });

      console.log('План успішно оновлений');
    } catch (error) {
      console.error('Помилка при оновленні плану:', error);
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при оновленні плану',
          type: 'error',
        }),
      );
      throw error;
    }
  };

  const onSubmit = async (data: Company) => {
    const transformedData = transformDataToContract(data);
    try {
      let responseData;

      if (isEdit) {
        responseData = await axiosInstance.patch(
          '/Admin/UpdateCompany',
          transformedData,
        );
      } else {
        responseData = await axiosInstance.put(
          '/Admin/AddCompany',
          transformedData,
        );
      }

      const startAt = watch('contractStartAt');
      const finishAt = watch('contractFinishAt');
      const planId = watch('plan');
      const contractNumber = watch('contractNumber');

      if (startAt && finishAt && planId && contractNumber) {
        await updateClientPlan(
          responseData.data.id,
          Number(planId),
          contractNumber,
          startAt,
          finishAt,
        );
      }

      return responseData.data;
    } catch (error) {
      console.error('Помилка при збереженні компанії:', error);
      dispatch(
        showSnackbar({
          message: 'Сталася помилка при збереженні компанії',
          type: 'error',
        }),
      );
      throw error;
    }
  };

  return {
    control,
    handleSubmit,
    errors: errors as FieldErrors<Company>,
    isValid,
    reset,
    openDateModal,
    closeDateModal,
    isDateModalOpen,
    handleDateSelect,
    openWorkTimeModal,
    closeWorkTimeModal,
    isWorkTimeModalOpen,
    handleWorkTimeSave,
    onSubmit,
    watch,
    selectedTags,
    handleTagChange,
    setValue,
    workTime,
    setSelectedFormIndex,
    formData,
  };
};
