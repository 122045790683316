import React from 'react';

const AzIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 5L12 2L15 5H9ZM12 22L9 19H15L12 22Z" />
      <path d="M9.72349 16.469C9.60216 16.1137 9.47649 15.7627 9.34649 15.416C9.21649 15.0693 9.09083 14.714 8.96949 14.35H5.17349C5.05216 14.714 4.92649 15.0737 4.79649 15.429C4.67516 15.7757 4.55383 16.1223 4.43249 16.469H2.72949C3.06749 15.507 3.38816 14.6187 3.69149 13.804C3.99483 12.9893 4.28949 12.218 4.57549 11.49C4.87016 10.762 5.16049 10.0687 5.44649 9.40999C5.73249 8.75132 6.02716 8.10132 6.33049 7.45999H7.87749C8.18083 8.10132 8.47549 8.75132 8.76149 9.40999C9.04749 10.0687 9.33349 10.762 9.61949 11.49C9.91416 12.218 10.2132 12.9893 10.5165 13.804C10.8285 14.6187 11.1535 15.507 11.4915 16.469H9.72349ZM7.07149 9.20199C6.87216 9.66132 6.64249 10.2203 6.38249 10.879C6.13116 11.5377 5.86683 12.2527 5.58949 13.024H8.55349C8.27616 12.2527 8.00749 11.5333 7.74749 10.866C7.48749 10.1987 7.26216 9.64399 7.07149 9.20199Z" />
      <path d="M17.7465 7.35999C18.1018 7.35999 18.5048 7.37732 18.9555 7.41199C19.4062 7.43799 19.8308 7.49432 20.2295 7.58099V16.46H18.5915V13.08H17.6425C17.5298 13.08 17.4128 13.08 17.2915 13.08C17.1702 13.0713 17.0575 13.0583 16.9535 13.041C16.7802 13.249 16.6068 13.4873 16.4335 13.756C16.2602 14.016 16.0868 14.2977 15.9135 14.601C15.7488 14.8957 15.5798 15.2033 15.4065 15.524C15.2418 15.836 15.0815 16.148 14.9255 16.46H13.1835C13.3395 16.122 13.5085 15.7797 13.6905 15.433C13.8725 15.0863 14.0545 14.7527 14.2365 14.432C14.4272 14.1027 14.6135 13.7993 14.7955 13.522C14.9862 13.236 15.1595 12.9933 15.3155 12.794C14.7262 12.5773 14.2928 12.2567 14.0155 11.832C13.7382 11.4073 13.5995 10.883 13.5995 10.259C13.5995 9.77365 13.6948 9.34899 13.8855 8.98499C14.0848 8.62099 14.3622 8.32199 14.7175 8.08799C15.0815 7.84532 15.5192 7.66332 16.0305 7.54199C16.5418 7.42065 17.1138 7.35999 17.7465 7.35999ZM17.6295 8.77699C17.3002 8.77699 16.9925 8.80299 16.7065 8.85499C16.4292 8.89832 16.1865 8.97632 15.9785 9.08899C15.7705 9.20165 15.6058 9.35332 15.4845 9.54399C15.3718 9.73465 15.3155 9.96865 15.3155 10.246C15.3155 10.5407 15.3718 10.7833 15.4845 10.974C15.6058 11.1647 15.7748 11.3163 15.9915 11.429C16.2168 11.5417 16.4855 11.6197 16.7975 11.663C17.1182 11.7063 17.4778 11.728 17.8765 11.728H18.5915V8.81599C18.2882 8.78999 17.9675 8.77699 17.6295 8.77699Z" />
    </svg>
  );
};

export default AzIcon;
