import React from 'react';

interface ProfileIconProps {
  fill?: string;
}

const ProfileIcon: React.FC<ProfileIconProps> = ({ fill = '#0A8081' }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.73906 12.25V12.2609H1.75H12.25H12.2609V12.25V11.55C12.2609 11.3877 12.2203 11.2398 12.139 11.1068C12.0579 10.974 11.9508 10.8705 11.8178 10.7967L11.8174 10.7965C11.0292 10.4024 10.2337 10.1068 9.43073 9.90969C8.62781 9.71261 7.81755 9.61406 7 9.61406C6.18245 9.61406 5.37219 9.71261 4.56927 9.90969C3.76634 10.1068 2.97079 10.4024 2.18261 10.7965L2.1826 10.7965L2.18219 10.7967C2.04925 10.8705 1.94215 10.974 1.86098 11.1068C1.77968 11.2398 1.73906 11.3877 1.73906 11.55V12.25ZM0.0109375 13.9891V11.55C0.0109375 11.0562 0.138 10.6024 0.392133 10.1885C0.646315 9.77458 0.983892 9.4588 1.40497 9.24099C2.30841 8.78928 3.22636 8.45052 4.15882 8.22469C5.09131 7.99886 6.03837 7.88594 7 7.88594C7.96163 7.88594 8.90869 7.99886 9.84118 8.22469C10.7736 8.45052 11.6916 8.78928 12.595 9.24099C13.0161 9.4588 13.3537 9.77458 13.6079 10.1885C13.862 10.6024 13.9891 11.0562 13.9891 11.55V13.9891H0.0109375ZM7 6.98906C6.04042 6.98906 5.21924 6.64752 4.53586 5.96414C3.85248 5.28076 3.51094 4.45958 3.51094 3.5C3.51094 2.54042 3.85248 1.71924 4.53586 1.03586C5.21924 0.352477 6.04042 0.0109375 7 0.0109375C7.95958 0.0109375 8.78076 0.352477 9.46414 1.03586C10.1475 1.71924 10.4891 2.54042 10.4891 3.5C10.4891 4.45958 10.1475 5.28076 9.46414 5.96414C8.78076 6.64752 7.95958 6.98906 7 6.98906ZM7 5.26094C7.48417 5.26094 7.89893 5.08841 8.24367 4.74367C8.58841 4.39893 8.76094 3.98417 8.76094 3.5C8.76094 3.01583 8.58841 2.60107 8.24367 2.25633C7.89893 1.91159 7.48417 1.73906 7 1.73906C6.51583 1.73906 6.10107 1.91159 5.75633 2.25633C5.41159 2.60107 5.23906 3.01583 5.23906 3.5C5.23906 3.98417 5.41159 4.39893 5.75633 4.74367C6.10107 5.08841 6.51583 5.26094 7 5.26094Z"
      fill={fill}
    />
  </svg>
);

export default ProfileIcon;
