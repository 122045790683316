import { isAxiosError } from 'axios';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { FieldValues } from 'react-hook-form';
import { showSnackbar } from '../../../store/reducers/snackbarSlice';
import { AppDispatch } from '../../../store/store';
import { fetchTranslators } from '../../../store/thunks/translatorsThunk';
import { Translator } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';

interface UploadResponse {
  url: string;
}

export const uploadImage = async (image: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', image);

  const uploadResponse = await axiosInstance.post<UploadResponse>(
    '/File/UploadAvatarForUser',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return uploadResponse.data.url;
};

export const addTranslator = async (
  data: any,
  avatarUrl: string,
  dispatch: AppDispatch,
  translatorId?: string,
): Promise<boolean> => {
  const requestData = {
    userData: {
      userName: data.name,
      email: data.email,
      phone: data.phone,
      avatarUrl,
      id: translatorId,
    },
  };

  try {
    await axiosInstance.patch('/Admin/UpdateTranslator', requestData);
    dispatch(
      showSnackbar({ message: 'Перекладач успішно доданий', type: 'success' }),
    );
    dispatch(fetchTranslators(0, 20, 'ASC'));
    return true;
  } catch (error: any) {
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const errorMessage = error.response?.data.Message || 'Помилка сервера';
      if (status === 400 && errorMessage === 'User already exists') {
        dispatch(
          showSnackbar({
            message: 'Перекладач з такою email адресою існує',
            type: 'error',
          }),
        );
      } else {
        dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
      }
    } else {
      const unexpectedError = 'Виникла неочікувана помилка';
      dispatch(showSnackbar({ message: unexpectedError, type: 'error' }));
    }
    return false;
  }
};

export const handleFormSubmit = async (
  data: FieldValues,
  image: File | null,
  translator: Translator | undefined,
  dispatch: AppDispatch,
  setError: Dispatch<SetStateAction<string | null>>,
  setSubmittedEmail: Dispatch<SetStateAction<string>>,
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>,
): Promise<boolean> => {
  setError(null);
  let avatarUrl = translator?.avatarUrl || '';

  try {
    if (image) {
      avatarUrl = await uploadImage(image);
    }

    const success = await addTranslator(
      data,
      avatarUrl,
      dispatch,
      translator?.id,
    );
    if (success) {
      setSubmittedEmail(data.email);
      setShowSuccessModal(true);
      return true;
    }
  } catch (error: any) {
    setError('Виникла помилка при додаванні перекладача');
  }
  return false;
};

export const handleImageUpload = (
  e: ChangeEvent<HTMLInputElement>,
  setImage: Dispatch<SetStateAction<File | null>>,
) => {
  const file = e.target.files?.[0];
  if (file) {
    setImage(file);
  }
};

export const fetchTranslatorData = async (
  translatorId: string,
): Promise<Translator | null> => {
  try {
    const response = await axiosInstance.get(
      `/api/translators/${translatorId}`,
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch translator data', error);
    return null;
  }
};
