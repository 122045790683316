import React from 'react';
import CrossIcon from '../icon/Сross';
import style from './FormHeader.module.css';

interface FormHeaderProps {
  title: string;
  onClose: () => void;
}

const FormHeader: React.FC<FormHeaderProps> = ({ title, onClose }) => (
  <div className={style.modalHead}>
    <h5>{title}</h5>
    <button onClick={onClose}>
      <CrossIcon />
    </button>
  </div>
);

export default FormHeader;
