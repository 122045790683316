import React from 'react';
import CrossIcon from '../../icon/Сross';
import style from './Header.module.css';

const Header: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <div className={style.header}>
    <h2>Робочий час</h2>
    <button onClick={onClose}>
      <CrossIcon />
    </button>
  </div>
);

export default Header;
