import React from 'react';
import style from './Logo.module.css';
import LogoImg from './images/Logo.svg'; // Путь зависит от структуры вашего проекта

const Logo: React.FC = () => {
  return (
    <div className={style.logo_container}>
      <img src={LogoImg} alt="LogoBack" className={style.logo} />
    </div>
  );
};

export default Logo;
