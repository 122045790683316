import CompanyLogo from './icons/CompanyLogo';
import PushLogo from './icons/Notification';
import AdminLogo from './icons/adminLogo';
import ReportsLogo from './icons/assignment';
import CustomerLogo from './icons/group';
import HistoryLogo from './icons/history';
import TranslatorsLogo from './icons/person_book';
import CancelLogo from './icons/phonelink_erase';
import SettingsLogo from './icons/settings';
import TariffLogo from './icons/tariffLogo';

export const iconsConfig = {
  company: { label: 'Компанії', icon: CompanyLogo },
  customer: { label: 'Клієнти', icon: CustomerLogo },
  translators: { label: 'Перекладачі', icon: TranslatorsLogo },
  administrator: { label: 'Адміністратори', icon: AdminLogo },
  history: { label: 'Історія перекладів', icon: HistoryLogo },
  cancel_translation: { label: 'Відміни перекладу', icon: CancelLogo },
  reports: { label: 'Звіти', icon: ReportsLogo },
  plan: { label: 'Тарифи', icon: TariffLogo },
  push_message: { label: 'Push-повідомлення', icon: PushLogo },
  settings: { label: 'Налаштування', icon: SettingsLogo },
} as const;

export type IconConfigKey = keyof typeof iconsConfig;
