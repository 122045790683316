import React, { useEffect, useRef } from 'react';
import styles from './PlanActionsModal.module.css';
import ActiveIcon from './icon/activeIcon';
import ChangeIcon from './icon/changeIcon';
import DeactivateIcon from './icon/deactivateIcon';
import DeleteIcon from './icon/deletIcon';

interface PlanActionsModalProps {
  isActive: boolean;
  onEdit: () => void;
  onToggleStatus: () => void;
  onClose: () => void;
  position: { top: number; left: number };
  canMove: boolean;
  selectedPlan: any;
}

const PlanActionsModal: React.FC<PlanActionsModalProps> = ({
  isActive,
  onEdit,
  onToggleStatus,
  onClose,
  position,
  canMove,
  selectedPlan,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !(event.target as HTMLElement).closest(`.${styles.modalContainer}`)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={modalRef}
      className={styles.modalContainer}
      style={{ top: position.top, left: position.left }}
    >
      {isActive && (
        <button className={styles.topButton} onClick={onEdit}>
          <ActiveIcon />
          Активувати тариф
        </button>
      )}

      <button
        className={isActive ? styles.bottomButton : styles.bottomButtonInactive}
        onClick={onToggleStatus}
      >
        {isActive ? <DeactivateIcon /> : <DeleteIcon />}
        {isActive ? 'Деактивувати тариф' : 'Видалити'}
      </button>
    </div>
  );
};

export default PlanActionsModal;
