import React from 'react';
import ConfirmIcon from './icons/confirmIcon.jpg';
import style from './successModal.module.css';

interface SuccessModalProps {
  message: string;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message, onClose }) => {
  return (
    <div className={style.modalOverlay}>
      <div className={style.modalContent}>
        <img src={ConfirmIcon} alt={'ConfirmIcon'} className={style.img} />
        <h6>{message}</h6>
        <button onClick={onClose} className={style.closeButton}>
          Зрозуміло
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
