import React, { ChangeEvent, FocusEvent, forwardRef, useState } from 'react';
import style from './Input.module.css';
import Eye from './icons/visibility.svg';
import EyeOff from './icons/visibility_off.svg';

interface InputProps {
  id: string;
  labelText: string;
  type: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      labelText,
      type,
      value,
      onChange,
      onBlur,
      onFocus,
      required,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const togglePasswordVisibility = (
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      e.preventDefault();
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    };

    const getPlaceholder = () => {
      if (type === 'email') {
        return isFocused
          ? 'example@example.com'
          : 'Введіть адресу електронної пошти';
      }
      if (type === 'password') {
        return 'Введіть пароль';
      }
      return '';
    };

    return (
      <div className={style.auth_input}>
        <label htmlFor={id}>{labelText}</label>
        <input
          id={id}
          type={showPassword ? 'text' : type}
          value={value}
          onChange={onChange}
          placeholder={getPlaceholder()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maxLength={type === 'password' ? 45 : undefined}
          required={required}
          ref={ref}
          {...rest}
        />
        {type === 'password' && (
          <div className={style.visibility_container}>
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={style.visibility_button}
              tabIndex={-1}
            >
              {showPassword ? (
                <img
                  src={EyeOff}
                  alt="EyeOff"
                  className={style.visibility_off_icon}
                />
              ) : (
                <img src={Eye} alt="eye" />
              )}
            </button>
          </div>
        )}
      </div>
    );
  },
);

export default Input;
