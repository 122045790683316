import React from 'react';
import style from './NavigationButton.module.css';

interface NavigationButtonProps {
  label: string;
  icon: React.ComponentType<{ fill: string }>;
  active: boolean;
  onClick: () => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  label,
  icon: Icon,
  active,
  onClick,
}) => (
  <button
    className={active ? style.activeButton : style.navButton}
    onClick={onClick}
  >
    <Icon fill={active ? '#0A8081' : '#596987'} />
    {label}
  </button>
);

export default NavigationButton;
