import React from 'react';
import style from './submitButton.module.css';

interface SubmitButtonProps {
  isValid: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isValid }) => (
  <button type="submit" disabled={!isValid} className={style.submitButton}>
    Надіслати запрошення
  </button>
);

export default SubmitButton;
