import React, { useEffect, useRef } from 'react';
import style from './ConfirmationModal.module.css';
import ClientAvatar from './icons/ClientAvatar';
import CompanyAvatar from './icons/CompanyAvatar';

interface ConfirmModalProps {
  userName: string | undefined;
  avatarUrl: string | undefined;
  onConfirm: () => void;
  onCancel: () => void;
  type: 'Company' | 'Client' | 'Translators' | 'Administration';
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  userName,
  avatarUrl,
  onConfirm,
  onCancel,
  type,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel]);

  const DefaultIcon = type === 'Company' ? CompanyAvatar : ClientAvatar;

  const getMessage = () => {
    switch (type) {
      case 'Administration':
        return 'Ви впевнені, що хочете видалити адміністратора?';
      case 'Company':
        return 'Ви впевнені, що хочете видалити компанію?';
      case 'Translators':
        return 'Ви впевнені, що хочете видалити перекладача?';
      default:
        return 'Ви впевнені, що хочете видалити клієнта?';
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'Administration':
        return 'Видалення адміністратора';
      case 'Company':
        return 'Видалення компанії';
      case 'Translators':
        return 'Видалення перекладача';
      default:
        return 'Видалення клієнта';
    }
  };

  const truncateUserName = (name: string | undefined) => {
    if (!name) return '';
    return name.length > 35 ? `${name.slice(0, 35)}...` : name;
  };

  return (
    <div className={style.modalOverlay}>
      <div ref={modalRef} className={style.modal}>
        <div className={style.modalContent}>
          <div>
            <h5 className={style.title}>{getTitle()}</h5>
          </div>
          <h5 className={style.message}>{getMessage()}</h5>
          <div className={avatarUrl ? style.detail : style.wrapInfo}>
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={`${userName || 'User'}'s avatar`}
                className={style.avatar}
              />
            ) : (
              <div className={style.nonAvatar}>
                <DefaultIcon />
              </div>
            )}
            <p>{truncateUserName(userName)}</p>
          </div>
          <div className={style.modalActions}>
            <button className={style.confirmButton} onClick={onConfirm}>
              Так
            </button>
            <button className={style.cancelButton} onClick={onCancel}>
              Ні
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
