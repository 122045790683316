import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { refreshJwtToken } from '../store/actions/authActions';
import { logout } from '../store/reducers/authSlice';
import store from '../store/store';

interface DecodedToken {
  exp: number;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    let jwtToken = store.getState().auth.jwtToken;

    if (jwtToken) {
      const decodedToken: DecodedToken = jwtDecode<DecodedToken>(jwtToken);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        try {
          const response = await store.dispatch(refreshJwtToken()).unwrap();
          jwtToken = response.jwtToken;
        } catch (err) {
          store.dispatch(logout());
          return Promise.reject(err);
        }
      }

      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      config.headers.set('Authorization', `Bearer ${jwtToken}`);
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
