import axiosInstance from '../../utils/axiosInstance';
import {
  loadCancelTranslationRequest,
  loadCancelTranslationSuccess,
  loadCancelTranslationFailure,
} from '../actions/cancelTranslationActions';
import { AppDispatch } from '../store';

export const fetchCancelTranslation =
  (
    pageIndex: number,
    pageSize: number,
    searchString: string = '',
    startAt: string = '',
    finishedAt: string = '',
    orderDirection: string = 'ASC',
    orderField: string = 'createdAt',
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadCancelTranslationRequest());
    try {
      const response = await axiosInstance.post(
        '/Admin/GetCallTranslationCancellationList',
        {
          pageIndex,
          pageSize,
          searchString,
          startAt: startAt ? startAt : undefined,
          finishedAt: finishedAt ? finishedAt : undefined,
          orderDirection,
          orderField,
        },
      );
      const history = response.data;
      dispatch(loadCancelTranslationSuccess(history));
      return history;
    } catch (error: any) {
      dispatch(loadCancelTranslationFailure(error.message));
      return { items: [], hasMore: false };
    }
  };
