import React from 'react';
import style from './SuccessModal.module.css';
import Confirmation from './icons/Confirmation.png';

interface SuccessModalProps {
  email: string;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ email, onClose }) => {
  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={style.modalOverlay} onClick={handleOverlayClick}>
      <div className={style.modalContent}>
        <img
          src={Confirmation}
          alt="confirmation"
          className={style.confir_logo}
        />
        <h5>Успіх!</h5>
        <p>Запрошення було успішно надіслано на {email}</p>
        <button onClick={onClose} className={style.modalButton}>
          Закрити
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
