import React, { useEffect, useRef } from 'react';
import style from './SuccessModal.module.css';
import SuccessIcon from './icons/iconSuccess.jpg';

interface SuccessModalProps {
  email: string | null;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ email, onClose }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={style.successModal}>
      <div className={style.modalContent} ref={modalRef}>
        <img src={SuccessIcon} alt={'img'} className={style.successImg} />
        <div className={style.wrap}>
          <h6 className={style.title}>Запрошення успішно надіслано!</h6>
          <p className={style.infoEmail}>На email: {email}</p>
        </div>
        <button onClick={onClose} className={style.successButton}>
          Зрозуміло
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
