interface CompanyLogoProps {
  fill: string;
}

const CompanyLogo = ({ fill }: CompanyLogoProps) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20.5V4.25L5 0.5L10 4.25V6.5H20V20.5H0ZM2 18.5H4V16.5H2V18.5ZM2 14.5H4V12.5H2V14.5ZM2 10.5H4V8.5H2V10.5ZM2 6.5H4V4.5H2V6.5ZM6 6.5H8V4.5H6V6.5ZM6 18.5H18V8.5H6V18.5ZM12 12.5V10.5H16V12.5H12ZM12 16.5V14.5H16V16.5H12ZM8 12.5V10.5H10V12.5H8ZM8 16.5V14.5H10V16.5H8Z"
      fill={fill}
    />
  </svg>
);

export default CompanyLogo;
