import { createAction } from '@reduxjs/toolkit';

export interface Client {
  userName: string;
  email: string;
  avatarUrl: string;
  phone: string;
  id: string;
  plan: string;
}

export const loadClientsRequest = createAction<{
  pageIndex: number;
  pageSize: number;
  orderDirection: string;
}>('LOAD_CLIENTS_REQUEST');
export const loadClientsSuccess = createAction<Client[]>(
  'LOAD_CLIENTS_SUCCESS',
);
export const loadClientsFailure = createAction<string>('LOAD_CLIENTS_FAILURE');
export const searchClientsRequest = createAction('SEARCH_CLIENTS_REQUEST');
export const searchClientsSuccess = createAction<Client[]>(
  'SEARCH_CLIENTS_SUCCESS',
);
export const searchClientsFailure = createAction<string>(
  'SEARCH_CLIENTS_FAILURE',
);
