import { Tag } from '../../../../types';
import axiosInstance from '../../../../utils/axiosInstance';

export const fetchTags = async (): Promise<Tag[]> => {
  try {
    const response = await axiosInstance.get('/Tags/GetAllTags');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch tags:', error);
    throw error;
  }
};

export const deleteTag = async (tagId: number): Promise<void> => {
  try {
    await axiosInstance.delete(`/Tags/RemoveTag`, { params: { tagId } });
  } catch (error) {
    console.error('Error deleting tag:', error);
    throw error;
  }
};

export const addTag = async (name: string): Promise<Tag> => {
  try {
    const response = await axiosInstance.put<Tag>('/Tags/AddTag', { name });
    return response.data;
  } catch (error) {
    console.error('Error adding tag:', error);
    throw error;
  }
};
