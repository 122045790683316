import React from 'react';
import { Admin } from '../../../types';
import AdminLogo from '../icons/adminLogo';
import DeleteIcon from '../icons/deleteIcon';
import style from './adminItem.module.css';

interface AdminItemProps {
  admin: Admin;
  index: number;
  openModal: (admin: Admin) => void;
}

const AdminItem: React.FC<AdminItemProps> = ({ admin, index, openModal }) => (
  <div className={style.adminItem}>
    <div className={style.numberText}>{index + 1}</div>
    <div className={style.firsInfo}>
      {admin.avatarUrl ? (
        <div>
          <img
            src={admin.avatarUrl}
            alt={admin.userName}
            className={style.withAvatar}
          />
        </div>
      ) : (
        <div className={style.wrapNoAvatar}>
          <AdminLogo />
        </div>
      )}
      <h6 className={style.adminName}>{admin.userName}</h6>
    </div>
    <div className={style.adminEmail}>{admin.email}</div>
    <button className={style.deleteButton} onClick={() => openModal(admin)}>
      <DeleteIcon />
    </button>
  </div>
);

export default AdminItem;
