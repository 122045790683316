import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import style from './Select.module.css';

interface SelectProps {
  label: string;
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
  error?: string;
  required?: boolean;
}

const Select = forwardRef<HTMLDivElement, SelectProps>(
  ({ label, options, value, onChange, error, required }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openUpwards, setOpenUpwards] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => wrapperRef.current as HTMLDivElement);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const closeDropdown = () => setIsOpen(false);

    const handleSelect = (value: string) => {
      onChange(value);
      closeDropdown();
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          closeDropdown();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (isOpen) {
        const dropdownRect = dropdownRef.current?.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (dropdownRect) {
          const spaceBelow = viewportHeight - dropdownRect.bottom;
          const spaceAbove = dropdownRect.top;
          setOpenUpwards(
            spaceBelow < dropdownRect.height && spaceAbove > spaceBelow,
          );
        }
      }
    }, [isOpen]);

    return (
      <div
        className={error ? style.wrapInputError : style.wrapInput}
        ref={wrapperRef}
      >
        <div
          className={
            isOpen ? style.selectContainerActive : style.selectContainer
          }
          onClick={toggleDropdown}
        >
          <label className={style.label}>{label}</label>
          <div className={style.selectedValue}>
            {value && options.find((option) => option.value === value)?.label}
          </div>
          <div
            className={`${style.arrow} ${isOpen ? style.arrowOpen : ''}`}
          ></div>
        </div>
        {isOpen && (
          <div
            ref={dropdownRef}
            className={style.dropdownMenu}
            style={{
              top: openUpwards ? 'auto' : '100%',
              bottom: openUpwards ? '100%' : 'auto',
            }}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className={style.dropdownItem}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
        {error && <span className={style.error}>{error}</span>}
      </div>
    );
  },
);

Select.displayName = 'Select';

export default Select;
