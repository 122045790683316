import { createAction } from '@reduxjs/toolkit';
import { Company } from '../../types';

export const loadCompaniesRequest = createAction<{
  pageIndex: number;
  pageSize: number;
  orderDirection: string;
}>('LOAD_COMPANIES_REQUEST');

export const loadCompaniesSuccess = createAction<{
  items: Company[];
  hasMore: boolean;
}>('LOAD_COMPANIES_SUCCESS');

export const appendCompaniesSuccess = createAction<{
  items: Company[];
  hasMore: boolean;
}>('APPEND_COMPANIES_SUCCESS');

export const loadCompaniesFailure = createAction<string>(
  'LOAD_COMPANIES_FAILURE',
);

export const searchCompaniesRequest = createAction('SEARCH_COMPANIES_REQUEST');

export const searchCompaniesSuccess = createAction<Company[]>(
  'SEARCH_COMPANIES_SUCCESS',
);

export const searchCompaniesFailure = createAction<string>(
  'SEARCH_COMPANIES_FAILURE',
);
