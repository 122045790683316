import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { login, performLogout, refreshJwtToken } from '../actions/authActions';
import { AuthState, Role } from '../type/authType';

export const initialState: AuthState = {
  jwtToken: localStorage.getItem('jwtToken'),
  refreshToken: Cookies.get('refreshToken') || null,
  isAuthenticated: !!localStorage.getItem('jwtToken'),
  loading: false,
  error: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{
        jwtToken: string;
        refreshToken: string;
        roles: Role[];
        email: string;
      }>,
    ) => {
      state.jwtToken = action.payload.jwtToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
      state.user = { email: action.payload.email, roles: action.payload.roles };
      localStorage.setItem('jwtToken', action.payload.jwtToken);
      Cookies.set('refreshToken', action.payload.refreshToken);
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.jwtToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.user = null;
      Cookies.remove('refreshToken');
      localStorage.removeItem('jwtToken');
    },
    loadUserSuccess: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.jwtToken = action.payload.jwtToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
      state.user = { email: action.payload.email, roles: action.payload.roles };
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(refreshJwtToken.fulfilled, (state, action) => {
      state.jwtToken = action.payload.jwtToken;
    });
    builder.addCase(performLogout.fulfilled, (state) => {
      state.jwtToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.user = null;
    });
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  loadUserSuccess,
} = authSlice.actions;

export default authSlice.reducer;
