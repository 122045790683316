import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { fetchHistory } from '../../store/thunks/historyThunk';
import style from './History.module.css';
import { TranslationHistoryItem } from './helpers';
import { HistoryList } from './components/HistoryList/HistoryList';

const History = () => {
  const dispatch: AppDispatch = useDispatch();
  const { history, loading, hasNextPage } = useSelector(
    (state: RootState) => state.history,
  );

  const [data, setData] = useState<TranslationHistoryItem[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dateValues, setDateValues] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: '', endDate: '' });
  const [sortField, setSortField] = useState<string>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    if (searchQuery && searchQuery.length < 2) {
      return;
    }

    dispatch(
      fetchHistory(
        pageIndex,
        15,
        searchQuery,
        dateValues.startDate
          ? new Date(dateValues.startDate).toISOString()
          : undefined,
        dateValues.endDate
          ? new Date(dateValues.endDate).toISOString()
          : undefined,
        sortDirection,
        sortField,
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sortField, sortDirection, pageIndex, dateValues]);

  useEffect(() => {
    if (searchQuery && searchQuery.length < 2) {
      return;
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      dispatch(
        fetchHistory(
          pageIndex,
          15,
          searchQuery,
          dateValues.startDate
            ? new Date(dateValues.startDate).toISOString()
            : undefined,
          dateValues.endDate
            ? new Date(dateValues.endDate).toISOString()
            : undefined,
          sortDirection,
          sortField,
        ),
      );
    }, 2000);

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchQuery]);

  useEffect(() => {
    if (pageIndex === 1) {
      setData(history);
    } else {
      setData((prev) => {
        const newData = history.filter(
          (newItem) =>
            !prev.some(
              (prevItem) =>
                prevItem.client.id === newItem.client.id &&
                prevItem.createdAt === newItem.createdAt,
            ),
        );
        return [...prev, ...newData];
      });
    }
  }, [history, pageIndex]);

  const fetchMoreData = () => {
    setPageIndex((prev) => prev + 1);
  };

  const handleChangeSort = (field: string) => {
    setPageIndex(1);
    setData([]);
    if (field === sortField) {
      setSortDirection((prevDirection: string) =>
        prevDirection === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleChangeSearch = (query: string) => {
    if (query.length < 2) {
      setSearchQuery(query);
    } else {
      setPageIndex(1);
      setData([]);
      setSearchQuery(query);
    }
  };

  const handleChangeDate = (dates: { startDate: string; endDate: string }) => {
    setPageIndex(1);
    setData([]);
    setDateValues(dates);
  };

  return (
    <div className={style.history_container}>
      <ControlPanel
        searchQuery={searchQuery}
        setSearchQuery={handleChangeSearch}
        dateValues={dateValues}
        setDateValues={handleChangeDate}
      />
      <HistoryList
        handleChangeSort={handleChangeSort}
        fetchMoreData={fetchMoreData}
        data={data}
        hasNextPage={hasNextPage}
        sortDirection={sortDirection}
        sortField={sortField}
        loading={loading}
      />
    </div>
  );
};

export default History;
