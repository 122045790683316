import ControlPanel from '../ControlPanel/ControlPanel';
import style from './Customer.module.css';
import CustomerList from './CustomerList/CustomerList';
import useCustomerLogic from './hooks/useCustomerLogic';

const Customer = () => {
  const {
    sortingLogo,
    isRotated,
    allClients,
    observerTarget,
    searchResults,
    isSearching,
    handleSortArrowClick,
    toggleSortingDirection,
    handleSearchChange,
    setIsSearching,
  } = useCustomerLogic();

  const displayedClients = isSearching ? searchResults : allClients;

  return (
    <div className={style.customer}>
      <ControlPanel
        setIsSearching={setIsSearching}
        onSearch={handleSearchChange}
      />
      <CustomerList
        clients={displayedClients}
        sortingLogo={sortingLogo}
        isRotated={isRotated}
        toggleSortingDirection={toggleSortingDirection}
        handleSortArrowClick={handleSortArrowClick}
        observerTarget={observerTarget}
      />
    </div>
  );
};

export default Customer;
