import React, { useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Link } from 'react-router-dom';
import InputField from '../InputField/InputField';
import style from './EmailFields.module.css';

interface EmailFieldsProps {
  control: Control<any>;
  showOtpField: boolean;
  handleResendOtp: (email: string) => void;
  getValues: () => { newEmail: string };
}

const EmailFields: React.FC<EmailFieldsProps> = ({
  control,
  showOtpField,
  handleResendOtp,
  getValues,
}) => {
  const [otp, setOtp] = useState<string[]>(Array(5).fill(''));
  const otpRefs = Array.from({ length: 5 }, () =>
    React.createRef<HTMLInputElement>(),
  );

  const handleOtpChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    onChange: (value: string) => void,
  ) => {
    const { value } = event.target;

    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otpRefs.length - 1) {
        otpRefs[index + 1].current?.focus();
      }

      onChange(newOtp.join('')); // Передаємо значення як одну строку
    }
  };

  const email = getValues().newEmail;

  return (
    <>
      {showOtpField ? (
        <div className={style.otpSection}>
          <div className={style.titelText}>
            <h6>Введіть код підтвердження</h6>
            <p>
              Для підтвердження облікового запису введіть 5-значний код, який ми
              надіслали на пошту <strong>{email}</strong>.
            </p>
          </div>
          <div className={style.wrapOtpInput}>
            {[0, 1, 2, 3, 4].map((index) => (
              <Controller
                key={index}
                name="otp"
                control={control}
                render={({ field }) => (
                  <InputField
                    value={otp[index] || ''}
                    onChange={(event) =>
                      handleOtpChange(event, index, field.onChange)
                    }
                    onBlur={field.onBlur}
                    name={`otp-${index}`}
                    maxLength={1}
                    ref={otpRefs[index]}
                    className={style.otpInput}
                    containerClassName={style.otpContainer}
                  />
                )}
              />
            ))}
          </div>
          <div className={style.text}>
            <p>Лист не надійшов?</p>
            <Link
              to={'#'}
              onClick={() => handleResendOtp(email)}
              className={style.resendLink}
            >
              Надіслати повторно
            </Link>
          </div>
        </div>
      ) : (
        <div className={style.wrapNewEmail}>
          <div className={style.titelText}>
            <h6>Введіть код підтвердження</h6>
            <p>
              Для підтвердження облікового запису введіть 5-значний код, який ми
              надіслали на пошту <strong>{email}</strong>.
            </p>
          </div>
          <Controller
            name="newEmail"
            control={control}
            render={({ field }) => (
              <InputField
                label="Новий Email"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                placeholder="Введіть новий email"
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default EmailFields;
