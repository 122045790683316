import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WorkTime } from '../../../../types';
import TimePickerModal from '../TimePickerModal/TimePickerModal';
import ActionMenu from './ActionMenu/ActionMenu';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import WorkTimeList from './WorkTimeList/WorkTimeList';
import style from './WorkTimeModal.module.css';
import { formatTime, sanitizeTime, WorkTimeModalProps } from './helpers';

const WorkTimeModal: React.FC<WorkTimeModalProps> = ({
  isOpen,
  onClose,
  workTime,
  onSave,
}) => {
  const initialWorkTime = Array.from({ length: 7 }, (_, i) => ({
    timeStart: '',
    timeEnd: '',
    dayOfTheWeek: i + 1,
  }));

  const actionMenuRef = useRef<HTMLDivElement | null>(null);
  const [localWorkTime, setLocalWorkTime] =
    useState<WorkTime[]>(initialWorkTime);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [modalPosition, setModalPosition] = useState<DOMRect | null>(null);
  const [actionMenuDay, setActionMenuDay] = useState<number | null>(null);
  const [actionMenuPosition, setActionMenuPosition] = useState<DOMRect | null>(
    null,
  );
  const [actionMenuAnchor, setActionMenuAnchor] = useState<
    HTMLDivElement | HTMLButtonElement | null
  >(null);

  useEffect(() => {
    if (isOpen) {
      const updatedWorkTime = initialWorkTime.map((item) => {
        const existingWorkTime = workTime.find(
          (wt) => wt.dayOfTheWeek === item.dayOfTheWeek,
        );
        return existingWorkTime
          ? {
              ...existingWorkTime,
              timeStart: sanitizeTime(existingWorkTime.timeStart),
              timeEnd: sanitizeTime(existingWorkTime.timeEnd),
            }
          : item;
      });
      setLocalWorkTime(updatedWorkTime);
    }
  }, [isOpen, workTime]);

  const closeActionMenu = useCallback(() => {
    setActionMenuDay(null);
    setActionMenuPosition(null);
    setActionMenuAnchor(null);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target as Node)
      ) {
        closeActionMenu();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeActionMenu]);

  const handleTimeChange = useCallback(
    (index: number, field: 'timeStart' | 'timeEnd', value: string) => {
      setLocalWorkTime((prevWorkTime) => {
        const newWorkTime = [...prevWorkTime];
        newWorkTime[index][field] = value;
        return newWorkTime;
      });
    },
    [],
  );

  const handleSave = useCallback(() => {
    const filteredWorkTime = localWorkTime.filter(
      (wt) => wt.timeStart && wt.timeEnd,
    );

    onSave(filteredWorkTime);
    onClose();
  }, [localWorkTime, onSave, onClose]);

  const openActionMenu = useCallback(
    (index: number, buttonRef: HTMLDivElement | HTMLButtonElement) => {
      setActionMenuDay(index);
      setActionMenuPosition(buttonRef.getBoundingClientRect());
      setActionMenuAnchor(buttonRef);
    },
    [],
  );

  const handleEdit = useCallback(() => {
    if (actionMenuDay !== null && actionMenuAnchor) {
      openTimePicker(actionMenuDay, actionMenuAnchor);
      closeActionMenu();
    }
  }, [actionMenuDay, actionMenuAnchor, closeActionMenu]);

  const handleDelete = useCallback(() => {
    if (actionMenuDay !== null) {
      handleTimeChange(actionMenuDay, 'timeStart', '');
      handleTimeChange(actionMenuDay, 'timeEnd', '');
      closeActionMenu();
    }
  }, [actionMenuDay, handleTimeChange, closeActionMenu]);

  const openTimePicker = useCallback(
    (index: number, buttonRef: HTMLDivElement | HTMLButtonElement) => {
      setSelectedDay(index);
      setModalPosition(buttonRef.getBoundingClientRect());
    },
    [],
  );

  const handleTimeSave = useCallback(
    (timeStart: string, timeEnd: string) => {
      if (selectedDay !== null) {
        handleTimeChange(selectedDay, 'timeStart', timeStart);
        handleTimeChange(selectedDay, 'timeEnd', timeEnd);
        setSelectedDay(null);
        setModalPosition(null);
      }
    },
    [selectedDay, handleTimeChange],
  );

  if (!isOpen) return null;

  return (
    <div className={style.modalOverlay}>
      <div className={style.modalContent}>
        <Header onClose={onClose} />
        <WorkTimeList
          localWorkTime={localWorkTime}
          openActionMenu={openActionMenu}
          openTimePicker={openTimePicker}
        />
        <Footer handleSave={handleSave} />
        {actionMenuPosition && actionMenuDay !== null && (
          <ActionMenu
            actionMenuRef={actionMenuRef}
            actionMenuPosition={actionMenuPosition}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            onClose={closeActionMenu}
          />
        )}
        {modalPosition && selectedDay !== null && (
          <TimePickerModal
            onSave={handleTimeSave}
            onClose={() => setSelectedDay(null)}
            initialTimeStart={formatTime(localWorkTime[selectedDay].timeStart)}
            initialTimeEnd={formatTime(localWorkTime[selectedDay].timeEnd)}
            buttonPosition={modalPosition}
          />
        )}
      </div>
    </div>
  );
};

export default WorkTimeModal;
