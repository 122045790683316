import React from 'react';

const DeleteIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 3L10 0L13 3H7ZM10 20L7 17H13L10 20Z" />
      <path d="M16.7694 14.469C16.6481 14.1136 16.5224 13.7626 16.3924 13.416C16.2624 13.0693 16.1367 12.714 16.0154 12.35H12.2194C12.0981 12.714 11.9724 13.0736 11.8424 13.429C11.7211 13.7756 11.5997 14.1223 11.4784 14.469H9.77539C10.1134 13.507 10.4341 12.6186 10.7374 11.804C11.0407 10.9893 11.3354 10.218 11.6214 9.48996C11.9161 8.76196 12.2064 8.06863 12.4924 7.40996C12.7784 6.75129 13.0731 6.10129 13.3764 5.45996H14.9234C15.2267 6.10129 15.5214 6.75129 15.8074 7.40996C16.0934 8.06863 16.3794 8.76196 16.6654 9.48996C16.9601 10.218 17.2591 10.9893 17.5624 11.804C17.8744 12.6186 18.1994 13.507 18.5374 14.469H16.7694ZM14.1174 7.20196C13.9181 7.66129 13.6884 8.22029 13.4284 8.87896C13.1771 9.53763 12.9127 10.2526 12.6354 11.024H15.5994C15.3221 10.2526 15.0534 9.53329 14.7934 8.86596C14.5334 8.19863 14.3081 7.64396 14.1174 7.20196Z" />
      <path d="M5.29249 5.35999C5.64783 5.35999 6.05083 5.37732 6.50149 5.41199C6.95216 5.43799 7.37683 5.49432 7.77549 5.58099V14.46H6.13749V11.08H5.18849C5.07583 11.08 4.95883 11.08 4.83749 11.08C4.71616 11.0713 4.60349 11.0583 4.49949 11.041C4.32616 11.249 4.15283 11.4873 3.97949 11.756C3.80616 12.016 3.63283 12.2977 3.45949 12.601C3.29483 12.8957 3.12583 13.2033 2.95249 13.524C2.78783 13.836 2.62749 14.148 2.47149 14.46H0.729492C0.885492 14.122 1.05449 13.7797 1.23649 13.433C1.41849 13.0863 1.60049 12.7527 1.78249 12.432C1.97316 12.1027 2.15949 11.7993 2.34149 11.522C2.53216 11.236 2.70549 10.9933 2.86149 10.794C2.27216 10.5773 1.83883 10.2567 1.56149 9.83199C1.28416 9.40732 1.14549 8.88299 1.14549 8.25899C1.14549 7.77365 1.24083 7.34899 1.43149 6.98499C1.63083 6.62099 1.90816 6.32199 2.26349 6.08799C2.62749 5.84532 3.06516 5.66332 3.57649 5.54199C4.08783 5.42065 4.65983 5.35999 5.29249 5.35999ZM5.17549 6.77699C4.84616 6.77699 4.53849 6.80299 4.25249 6.85499C3.97516 6.89832 3.73249 6.97632 3.52449 7.08899C3.31649 7.20165 3.15183 7.35332 3.03049 7.54399C2.91783 7.73465 2.86149 7.96865 2.86149 8.24599C2.86149 8.54065 2.91783 8.78332 3.03049 8.97399C3.15183 9.16465 3.32083 9.31632 3.53749 9.42899C3.76283 9.54165 4.03149 9.61965 4.34349 9.66299C4.66416 9.70632 5.02383 9.72799 5.42249 9.72799H6.13749V6.81599C5.83416 6.78999 5.51349 6.77699 5.17549 6.77699Z" />
    </svg>
  );
};

export default DeleteIcon;
