import React from 'react';
import { Translator } from '../../../types';
import styles from './WorkSchedule.module.css';

interface WorkScheduleProps {
  schedule: Translator['weeklyWorkSchedule'];
}

const daysOfWeek = [
  { short: 'Пн', key: 'mon' },
  { short: 'Вт', key: 'tue' },
  { short: 'Ср', key: 'wed' },
  { short: 'Чт', key: 'thu' },
  { short: 'Пт', key: 'fri' },
  { short: 'Сб', key: 'sat' },
  { short: 'Нд', key: 'sun' },
];

const WorkSchedule: React.FC<WorkScheduleProps> = ({ schedule }) => {
  return (
    <div className={styles.schedule}>
      {daysOfWeek.map((day) => {
        const daySchedule = schedule[day.key as keyof typeof schedule];
        const isActive = daySchedule.length > 0;

        return (
          <div
            key={day.key}
            className={`${styles.day} ${isActive ? styles.active : styles.inactive}`}
          >
            <div>{day.short}</div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkSchedule;
