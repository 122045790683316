import React from 'react';
import style from './modalHeader.module.css';

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => (
  <div className={style.modalHeader}>
    <h6>{title}</h6>
    <button onClick={onClose} className={style.closeButton}>
      ×
    </button>
  </div>
);

export default ModalHeader;
