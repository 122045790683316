interface AdminLogoProps {
  fill: string;
}

const AdminLogo = ({ fill }: AdminLogoProps) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11.5C7.01667 11.5 6.1875 11.1625 5.5125 10.4875C4.8375 9.8125 4.5 8.98333 4.5 8C4.5 7.01667 4.8375 6.1875 5.5125 5.5125C6.1875 4.8375 7.01667 4.5 8 4.5C8.98333 4.5 9.8125 4.8375 10.4875 5.5125C11.1625 6.1875 11.5 7.01667 11.5 8C11.5 8.98333 11.1625 9.8125 10.4875 10.4875C9.8125 11.1625 8.98333 11.5 8 11.5ZM8 9.5C8.43333 9.5 8.79167 9.35833 9.075 9.075C9.35833 8.79167 9.5 8.43333 9.5 8C9.5 7.56667 9.35833 7.20833 9.075 6.925C8.79167 6.64167 8.43333 6.5 8 6.5C7.56667 6.5 7.20833 6.64167 6.925 6.925C6.64167 7.20833 6.5 7.56667 6.5 8C6.5 8.43333 6.64167 8.79167 6.925 9.075C7.20833 9.35833 7.56667 9.5 8 9.5ZM8 20.5C5.68333 19.9167 3.77083 18.5875 2.2625 16.5125C0.754167 14.4375 0 12.1333 0 9.6V3.5L8 0.5L16 3.5V9.6C16 12.1333 15.2458 14.4375 13.7375 16.5125C12.2292 18.5875 10.3167 19.9167 8 20.5ZM8 2.625L2 4.875V9.6C2 10.5 2.125 11.375 2.375 12.225C2.625 13.075 2.96667 13.875 3.4 14.625C4.1 14.275 4.83333 14 5.6 13.8C6.36667 13.6 7.16667 13.5 8 13.5C8.83333 13.5 9.63333 13.6 10.4 13.8C11.1667 14 11.9 14.275 12.6 14.625C13.0333 13.875 13.375 13.075 13.625 12.225C13.875 11.375 14 10.5 14 9.6V4.875L8 2.625ZM8 15.5C7.4 15.5 6.81667 15.5667 6.25 15.7C5.68333 15.8333 5.14167 16.0167 4.625 16.25C5.10833 16.75 5.63333 17.1833 6.2 17.55C6.76667 17.9167 7.36667 18.2 8 18.4C8.63333 18.2 9.23333 17.9167 9.8 17.55C10.3667 17.1833 10.8917 16.75 11.375 16.25C10.8583 16.0167 10.3167 15.8333 9.75 15.7C9.18333 15.5667 8.6 15.5 8 15.5Z"
      fill={fill}
    />
  </svg>
);

export default AdminLogo;
