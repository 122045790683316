import { FC } from 'react';
import LeadingIcon from '../icons/leadingIcon';
import style from './SearchInput.module.css';

interface SearchInputProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  isFocused: boolean;
  setIsFocused: (focused: boolean) => void;
  handleSearchKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Новий пропс
}

const SearchInput: FC<SearchInputProps> = ({
  searchTerm,
  setSearchTerm,
  isFocused,
  setIsFocused,
  handleSearchKeyDown,
}) => (
  <div className={style.searchWrap} onMouseDown={(e) => e.stopPropagation()}>
    <input
      type="text"
      placeholder="Пошук"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className={style.searchInput}
      onFocus={(e) => {
        e.stopPropagation();
        setIsFocused(true);
      }}
      onBlur={(e) => {
        e.stopPropagation();
        setIsFocused(false);
      }}
      onKeyDown={handleSearchKeyDown}
    />
    <LeadingIcon
      className={`${style.leadingIcon} ${isFocused ? 'focused' : ''}`}
    />
  </div>
);

export default SearchInput;
