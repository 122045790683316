const DeactivateIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17.425L13.875 19.525L12.475 18.125L14.575 16L12.475 13.875L13.875 12.475L16 14.575L18.125 12.475L19.525 13.875L17.425 16L19.525 18.125L18.125 19.525L16 17.425ZM3 20C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V14H3V0H18V10.375C17.6833 10.2583 17.3583 10.1708 17.025 10.1125C16.6917 10.0542 16.35 10.025 16 10.025V2H5V14H10.35C10.2333 14.3167 10.1458 14.6417 10.0875 14.975C10.0292 15.3083 10 15.65 10 16H2V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18H10.35C10.4833 18.3833 10.65 18.7417 10.85 19.075C11.05 19.4083 11.2833 19.7167 11.55 20H3ZM6 7V5H15V7H6ZM6 10V8H15V10H6Z"
      fill="#FF3B30"
    />
  </svg>
);

export default DeactivateIcon;
