interface TariffLogoProps {
  fill: string;
}

const TariffLogo = ({ fill }: TariffLogoProps) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 17.5H9V16.5H10C10.2833 16.5 10.5208 16.4042 10.7125 16.2125C10.9042 16.0208 11 15.7833 11 15.5V12.5C11 12.2167 10.9042 11.9792 10.7125 11.7875C10.5208 11.5958 10.2833 11.5 10 11.5H7V10.5H11V8.5H9V7.5H7V8.5H6C5.71667 8.5 5.47917 8.59583 5.2875 8.7875C5.09583 8.97917 5 9.21667 5 9.5V12.5C5 12.7833 5.09583 13.0208 5.2875 13.2125C5.47917 13.4042 5.71667 13.5 6 13.5H9V14.5H5V16.5H7V17.5ZM2 20.5C1.45 20.5 0.979167 20.3042 0.5875 19.9125C0.195833 19.5208 0 19.05 0 18.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H10L16 6.5V18.5C16 19.05 15.8042 19.5208 15.4125 19.9125C15.0208 20.3042 14.55 20.5 14 20.5H2ZM9 6.5V2.5H2V18.5H14V6.5H9Z"
      fill={fill}
    />
  </svg>
);

export default TariffLogo;
