import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  fetchTranslators,
  searchTranslators,
} from '../../store/thunks/translatorsThunk';
import { Translator } from '../../types';
import ControlPanel from '../ControlPanel/ControlPanel';
import ClientList from './ClientList/ClientList';
import style from './Translators.module.css';
import { MenuProvider } from './contexts/MenuContext';
import SortingAZ from './icons/SortingAZ';
import SortingZA from './icons/SortingZA';

export interface MenuPosition {
  top: number;
  left: number;
}

const Translators: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { translators, searchResults, loading, orderDirection } = useSelector(
    (state: RootState) => state.translators,
  );

  const [sortingLogo, setSortingLogo] = useState<boolean>(false);
  const [visibleTranslators, setVisibleTranslators] = useState<Translator[]>(
    [],
  );
  const [sortedTranslators, setSortedTranslators] = useState<Translator[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const initialLoad = useRef<boolean>(true);
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const pageIndexRef = useRef(0);
  const orderDirectionRef = useRef(orderDirection);
  const fetchOnce = useRef(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (initialLoad.current && !fetchOnce.current) {
      fetchOnce.current = true;
      dispatch(
        fetchTranslators(pageIndexRef.current, 18, orderDirectionRef.current),
      ).then(() => {
        setInitialLoadCompleted(true);
        initialLoad.current = false;
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (translators.length) {
      setSortedTranslators(translators);
      setVisibleTranslators(translators.slice(0, 18));
    } else {
      setSortedTranslators([]);
      setVisibleTranslators([]);
    }
  }, [translators, loading]);

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!initialLoadCompleted || isFetching || loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loading && !isFetching) {
            if (
              listContainerRef.current &&
              listContainerRef.current.scrollHeight >
                listContainerRef.current.clientHeight
            ) {
              setIsFetching(true);
              pageIndexRef.current += 1;
              dispatch(
                fetchTranslators(
                  pageIndexRef.current,
                  18,
                  orderDirectionRef.current,
                ),
              ).then(() => {
                setIsFetching(false);
              });
            }
          }
        },
        {
          root: listContainerRef.current,
          rootMargin: '20px',
          threshold: 1.0,
        },
      );

      if (node) observer.current.observe(node);
    },
    [initialLoadCompleted, loading, dispatch, isFetching],
  );

  const toggleSortingDirection = useCallback(() => {
    setSortingLogo((prev) => !prev);
    pageIndexRef.current = 0;
    orderDirectionRef.current = sortingLogo ? 'ASC' : 'DESC';
    dispatch(fetchTranslators(0, 18, sortingLogo ? 'ASC' : 'DESC'));
  }, [dispatch, sortingLogo]);

  const handleSearch = (query: string) => {
    setIsSearching(true);
    dispatch(searchTranslators(query));
  };

  const translatorsToDisplay =
    searchResults.length > 0 ? searchResults : sortedTranslators;

  const handleTranslatorDelete = (translatorId: string) => {
    setSortedTranslators((prevTranslators) =>
      prevTranslators.filter((translator) => translator.id !== translatorId),
    );
    setVisibleTranslators((prevVisibleTranslators) =>
      prevVisibleTranslators.filter(
        (translator) => translator.id !== translatorId,
      ),
    );
  };

  const getContainerClassName = () => {
    if (isSearching && searchResults.length === 0) {
      return `${style.translators_content} ${style.translators_content_many}`;
    }
    return translatorsToDisplay.length <= 9
      ? `${style.translators_content} ${style.translators_content_many}`
      : style.translators_content;
  };

  const handleRefresh = () => {
    pageIndexRef.current = 0;
    dispatch(fetchTranslators(0, 18, orderDirectionRef.current));
  };

  return (
    <MenuProvider>
      <div className={style.translators_container}>
        <ControlPanel setIsSearching={setIsSearching} onSearch={handleSearch} />
        <div className={getContainerClassName()}>
          <div className={style.head_list}>
            <h5>#</h5>
            <div className={style.initials}>
              <h5>ПІБ</h5>
              <button onClick={toggleSortingDirection}>
                {sortingLogo ? <SortingZA /> : <SortingAZ />}
              </button>
            </div>
            <h5>ТЕЛЕФОН</h5>
            <h5>ГРАФІК РОБОТИ</h5>
          </div>
          <div className={style.board} ref={listContainerRef}>
            {!loading && translators.length === 0 && !initialLoad.current ? (
              <div className={style.no_results}>
                <h6 className={style.content}>
                  Список перекладачів порожній. Додайте нових перекладачів, щоб
                  розпочати роботу.
                </h6>
              </div>
            ) : isSearching && searchResults.length === 0 ? (
              <div className={style.no_results}>
                <h6 className={style.content}>
                  Перекладача не знайдено. Спробуйте інше ім'я або додайте
                  нового перекладача, щоб розпочати роботу.
                </h6>
              </div>
            ) : (
              <>
                <ClientList
                  translators={translatorsToDisplay}
                  onDelete={handleTranslatorDelete}
                  onRefresh={handleRefresh}
                />
                {searchResults.length === 0 && <div ref={lastElementRef}></div>}
              </>
            )}
          </div>
        </div>
      </div>
    </MenuProvider>
  );
};

export default Translators;
